import React, { useEffect, useState } from 'react'
import Back from '../../../app/components/common/BackButton';
import { AiOutlineCalendar } from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'react-bootstrap';

type DateSlotType = {
    date_slot: unknown;
}

interface ILocation {
    state: DateSlotType;
}

const DateSlot: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading] = useState<boolean>(false);
    const [dates, setDates] = useState();
    const [campaignId, setCampaignId] = useState();

    useEffect(() => {
        if (location) {
            // @ts-ignore
            setDates(location?.state?.date_slot);
            // @ts-ignore
            setCampaignId(location?.state?.campaign_id);
            // @ts-ignore            
            console.log(location?.state?.date_slot)
        }
    }, [location]);

    return loading ? (<>...loading</>) : (
        <>
            <div className="d-flex mb-5 gap-3 align-items-center">
                <Back /> <h2 className="mb-0">Campaigns Date Slot</h2>
            </div>
            {
                //@ts-ignore
                dates && Array.isArray(dates) && dates.length > 0 ? dates.map((date) => (
                    <div className='row g-5 g-xl-10 mb-5 mb-xl-10 justify-content-center'>
                        <div className='col-sm-3'>
                            <button
                                onClick={() => {
                                    navigate("/campaign/time-slot", { state: { time_slots: date?.slot, date: date?.date, campaign_id: campaignId } })
                                }}
                                className="w-100 d-flex flex-row gap-6 justify-content-center py-6 align-items-center btn bg-black"
                                style={{ backgroundColor: "#000" }}
                            >
                                <AiOutlineCalendar color="#98A2B3" size={24} /> <span className="text-white fs-6">{moment(date?.date).format('DD MMM YYYY')}</span>
                            </button>
                        </div>
                    </div>
                )) : <></>
            }
        </>
    );
};

export default DateSlot;