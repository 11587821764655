import {Link} from 'react-router-dom'
import useDetectMode from '../../../hooks/useDetectMode'
import styles from './common.module.scss'
import {FaArrowLeft} from 'react-icons/fa'

const SuccessStatus = () => {
  const mode = useDetectMode()

  return (
    <>
      <div className='d-flex justify-content-start align-items-center w-100 mb-10'>
        <Link
          to='/login'
          onClick={() => {}}
          type='button'
          className='btn btn-sm mb-3 d-flex btn-primary align-items-center'
        >
          <FaArrowLeft />{' '}
          <span
            style={{
              marginLeft: '8px',
            }}
          >
            Back
          </span>
        </Link>
      </div>

      <section
        className={mode === 'light' || mode === 'system' ? styles.wrapper : styles.wrapperDark}
      >
        <div className={styles.img_container}>
          <img src='/media/icons/duotune/status/success.svg' alt='success' />
        </div>

        <h3 className='text-center mb-5'>Approved</h3>

        <p className='text-center text-muted fs-5'>
          Welcome to Elite List! We're so glad to have you as part of the team and now you are an
          Elite Lister.
        </p>
      </section>
    </>
  )
}

export default SuccessStatus
