/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Input from '../../../app/components/Input/Input'
import { RegistrationRepresentativeSchema } from '../../../_metronic/helpers/validations/auth'
import { UserModelType } from '@/app/types/User';
import axios from '../../../_metronic/hooks/axios'

export interface FormikFormProp {
  setStatus: (status: string) => void;
  setSubmitting: (isSubmitting: boolean) => void;
}

export function RegistrationRepresentative() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserModelType>({} as UserModelType);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = searchParams.get('token') || '';
    console.log('TOKEN', token);
    getInfoByToken(token);
  }, []);

  const getInfoByToken = (token: string) => {
    setLoading(true);
    try {
      axios
        .post('get-info-by-token', { verify_token: token })
        .then(res => {
          console.log('USER INFO', res);
          if (res?.status === 200) {
            const result = res?.data;
            if (result?.success) {
              console.log('USER INFO', result?.data);
              setUser({ ...result?.data });
            }
          }
          setLoading(false)
        })
        .catch(err => {
          console.error(err);
          setLoading(false)
        })
    } catch (err) {
      console.error('Error fetching', err);
      setLoading(false)
    }
  }

  const registerHandler = (
    values: UserModelType, { setStatus, setSubmitting, }: FormikFormProp
  ) => {
    setLoading(true)
    let formData = {
      verify_token: values.verify_token,
      email: values.email,
      password: values.password,
    }

    try {
      axios.post('/set-representative-password', formData)
        .then((res) => {
          console.log(res);
          if (res.status === 200 && res.data.success === true) {
            toast.success(res.data?.message);
            setLoading(false);
            navigate('/login')
          } else if (res.status === 415) {
            setSubmitting(false);
            setLoading(false);
            if (res.data?.message?.email?.length > 0) {
              setStatus(res.data?.message?.email[0])
            }

            if (res.data?.message?.password?.length > 0) {
              setStatus(res.data?.message?.password[0]);
            }
          }
          setLoading(false);
          navigate('/login');
        }).catch((err) => {
          console.log(err);
          let message =
            typeof err.response !== 'undefined'
              ? (err.response.data.message as string)
              : (err.message as string);

          setLoading(false);
          toast.error(message);
          setSubmitting(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false)
      setLoading(false)
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={user}
      enableReinitialize
      validationSchema={RegistrationRepresentativeSchema}
      onSubmit={registerHandler}
    >
      {({ errors, values, isValidating, isSubmitting, status, handleBlur, touched }) => (
        <Form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          id='kt_login_signup_form'
        >
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Hi There! </h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              Welcome to Elite List, create your account
            </div>
          </div>

          {status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{status}</div>
            </div>
          )}

          <div className='row'>
            <Input
              id='f_name'
              name='f_name'
              disabled
              type='text'
              errorText={errors.f_name}
              error={touched.f_name}
              onBlur={handleBlur}
              inputClass=''
              placeholder='Enter First Name'
              containerClass='mb-6  col-md-6'
            />
            <Input
              id='l_name'
              name='l_name'
              disabled
              type='text'
              errorText={errors.l_name}
              error={touched.l_name}
              onBlur={handleBlur}
              inputClass=''
              placeholder='Enter Last Name'
              containerClass='mb-6  col-md-6'
            />
            <Input
              id='email'
              name='email'
              type='email'
              disabled
              errorText={errors.email}
              error={touched.email}
              onBlur={handleBlur}
              inputClass=''
              placeholder='Email'
              containerClass='mb-4'
            />

            <Input
              id='password'
              name='password'
              type='password'
              errorText={errors.password}
              error={touched.password}
              onBlur={handleBlur}
              inputClass=''
              placeholder='Password'
              containerClass='mb-4'
            />
            <Input
              id='c_password'
              name='c_password'
              type='password'
              errorText={errors.c_password}
              error={touched.c_password}
              onBlur={handleBlur}
              inputClass=''
              placeholder='Confirm Password'
              containerClass='mb-4'
            />
          </div>
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={isSubmitting}
            >
              {!loading && <span className='indicator-label'>Sign Up</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg w-100 mb-5'
            >
              Already have an account? <Link to='/login'>Sign In</Link>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
