import * as React from 'react'
import {parseCookies} from 'nookies'
import {Alert, Button, Form, Modal, Spinner} from 'react-bootstrap'
import {APIURL} from '../../../config/config'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './VerifyDialog.css'

import ReactPinInput from 'react-pin-input'

interface VerifyDialogProps {
  show: boolean
  onHide: () => void
  bookingId: string | number
  fetchBooking: () => void
}

const VerifyDialog: React.FC<VerifyDialogProps> = (props) => {
  const initialState = {
    booking_id: props.bookingId,
    verify_code: '',
  }

  const [verifyState, setVerifyState] = React.useState<any>(initialState)

  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = React.useState<boolean>(false)

  const verifyBooking = async () => {
    if (!verifyState.verify_code) {
      toast.error('Please enter your pin number')
      return
    }

    setLoading(true)
    try {
      var myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${token}`)
      myHeaders.append('Content-Type', 'application/json')

      const response = await fetch(`${APIURL}/merchant/verify-pin`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(verifyState),
      })

      const data = await response.json()

      if (data?.success) {
        toast.success(data?.message)
        props.onHide()
        props.fetchBooking()
      } else {
        toast.error(
          data?.message || 'Something went wrong. Please try again later or contact support'
        )
      }
    } catch (error) {
      console.log('🚀 ~ file: VerifyDialog.tsx:50 ~ verifyBooking ~ error:', error)
      setLoading(false)
    }

    setLoading(false)
  }

  return (
    <Modal {...props} size='sm' aria-labelledby='add-tutorial-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='add-tutorial-modal'>Add Tutorial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-center'>Please enter your pin number</h4>
        <div className={`d-flex align-items-center justify-content-center mb-6 `}>
          <ReactPinInput
            length={4}
            focus={true}
            onChange={(value) => {
              setVerifyState({verify_code: value, booking_id: props.bookingId})
            }}
            style={{
              padding: '5px 0 10px 0',
            }}
          />
        </div>

        <div className='d-flex justify-content-center'>
          <Button variant='success' size='lg' disabled={loading} onClick={verifyBooking}>
            {loading ? <Spinner animation='border' size='sm' /> : 'Verify'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default VerifyDialog
