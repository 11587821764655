import * as React from 'react'
import isEqual from 'react-fast-compare'

interface ITitleProps {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
}

const TitleInput = ({campaignState, setCampaignState}: ITitleProps) => {
  return (
    <div className='col-sm-12 col-md-6'>
      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
        <span className='required'>Campaign Title</span>
      </label>
      <input
        type='text'
        name='title'
        value={campaignState.title}
        onChange={(e) =>
          setCampaignState((pre) => ({
            ...pre,
            title: e.target.value,
          }))
        }
        className='form-control'
        placeholder='Enter Name'
      />
    </div>
  )
}

export default React.memo(TitleInput, isEqual)
