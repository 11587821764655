/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { parseCookies } from 'nookies'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { baseUrl, APIURL } from '../../../config/config'
import { FaLocationDot } from 'react-icons/fa6'
import ChatBox from '../../../../src/app/components/Chats/ChatBox'
import AdminChatBox from '../../../../src/app/components/Chats/AdminChatBox'

const InfluencerList: FC = () => {

    const params = useParams()
    const { id } = params
    const cookies = parseCookies()
    const token = cookies?.token;
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [influencerOpen, setInfluencerOpen] = useState(false);
    const [adminOpen, setAdminOpen] = useState(false);

    const [myAccount, setMyAccount] = useState<any>([])
    const [influencerList, setInfluencerList] = useState<any>([]);
    const [newInfluencerList, setNewInfluencerList] = useState<any>([]);
    const [influencer, setInfluencer] = useState<any>([]);
    const [campaign, setCampaign] = useState<any>([])
    const [searchInfluencer, setSearchInfluencer] = useState("");

    const getInfluencerList = async () => {
        setLoading(true);
        var myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${token}`)
        myHeaders.append('Content-Type', 'application/json')

        var requestOptions: any = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        try {
            const myInfo = await fetch(`${APIURL}/me`, requestOptions)
            const meResult = await myInfo.json()

            console.log('myInfo user', meResult)

            if (meResult.success === true) {
                setMyAccount(meResult?.data)
            }

            const influencerList = await fetch(`${APIURL}/merchant/campaign-wise-influencer?campaign_id=${id}`, requestOptions)
            const infResult = await influencerList.json()

            // console.log('influencerList', infResult);

            if (infResult.success == true) {
                setInfluencerList(infResult?.data);
                setNewInfluencerList(infResult?.data);
            }

            const getCampaign = await fetch(`${APIURL}/merchant/campaign?id=${id}`,
                requestOptions
            )
            const camResult = await getCampaign.json()


            console.log('getCampaign', camResult);

            if (camResult.success == true) {
                setCampaign(camResult?.data);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getInfluencerList();
    }, [])

    const chatHandler = (info: string, data: any) => {

        if (info == "influencer") {
            setInfluencerOpen(true);
            setInfluencer(data);
            setAdminOpen(false);
        }

        if (info == "admin") {
            setInfluencerOpen(false);
            setAdminOpen(true);
        }
    }

    const searchHandler = (e: { target: { name: any; value: any } }) => {
        setSearchInfluencer(e.target.value);

        if (e.target.value.length > 0) {
            let new_list = influencerList.filter((item: any) => {
                const search = e.target.value;
                const full_name = item.full_name;
                const target = full_name.match(search);
                if (target) {
                    return item;
                }
            })
            setNewInfluencerList(new_list);
        } else {
            setNewInfluencerList(influencerList);
        }
    }


    return (
        <>
            <PageTitle>Influencer List</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col-xl-12'>
                    <div className="d-flex flex-column flex-lg-row">
                        <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
                            <div className="card card-flush">
                                <div className="card-header pt-7">
                                    <form className="w-100 position-relative" autoComplete="off">
                                        {/*begin::Icon*/}
                                        {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                                        <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x="17.0365"
                                                    y="15.1223"
                                                    width="8.15546"
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(45 17.0365 15.1223)"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/} {/*end::Icon*/}
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className="form-control form-control-solid px-15"
                                            name="search"
                                            value={searchInfluencer}
                                            onChange={(e) => searchHandler(e)}
                                            defaultValue=""
                                            placeholder="Search by username or email..."
                                        />
                                        {/*end::Input*/}
                                    </form>
                                </div>
                                <div className="card-body pt-5">
                                    <div className="scroll-y me-n5 pe-5 h-200px h-lg-auto">
                                        {
                                            loading ? null : <>
                                                {
                                                    newInfluencerList.map((influencer: any, index: number) => (
                                                        <div
                                                            className="d-flex flex-stack py-4 border-bottom rounded p-2 cursor-pointer"
                                                            onClick={() => { chatHandler('influencer', influencer) }}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol  symbol-45px symbol-circle ">
                                                                    <img
                                                                        src={influencer.photo_url}
                                                                        width={50}
                                                                        height={50}
                                                                        className='symbol-label  bg-light-danger text-danger fs-6 fw-bolder '
                                                                        alt=""
                                                                    />
                                                                    {/* <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div> */}
                                                                </div>
                                                                <div className="ms-5">
                                                                    <span className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2" >
                                                                        {influencer.full_name}
                                                                    </span>
                                                                    <div className="fw-semibold text-muted"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-column align-items-end ms-2">
                                                                <span className="text-muted fs-7 mb-1">20 hrs</span>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }


                                        <div
                                            className="d-flex flex-stack py-4 border-bottom rounded p-2 cursor-pointer"
                                            onClick={() => { chatHandler('admin', []) }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-45px symbol-circle" style={{ backgroundColor: '#212529 !important' }}>
                                                    <img
                                                        src={`${baseUrl}/favicon.png`}
                                                        width={50}
                                                        height={50}
                                                        className='symbol-label text-danger p-2 fs-6 fw-bolder '
                                                        style={{ backgroundColor: '#212529 !important' }}
                                                        alt=""
                                                    />
                                                    {/* <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div> */}
                                                </div>
                                                <div className="ms-5">
                                                    <span className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2" >
                                                        Admin
                                                    </span>
                                                    <div className="fw-semibold text-muted"> </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column align-items-end ms-2">
                                                <span className="text-muted fs-7 mb-1">20 hrs</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                            {
                                influencerOpen ? <ChatBox influencer={influencer} campaign={campaign} /> : null
                            }
                            {
                                adminOpen ? <AdminChatBox myAccount={myAccount} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default InfluencerList;
