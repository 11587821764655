import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from '../../../_metronic/hooks/axiosPrivate';
import Back from '../../../app/components/common/BackButton';
import { Button } from 'react-bootstrap';
import InfluencerSkeleton from './Influencer/InfluencerSkeleton';
import { PaginationInfoType, PendingInvitesType, UpcomingDataType, NoShowStateType, CheckInStateType } from './type';
import Pagination from 'replace-js-pagination';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FaCalendar } from 'react-icons/fa';
import { BiTimeFive } from "react-icons/bi";
import moment from 'moment';

const UpcomingCampaign = () => {

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [noShowData, setNoShowData] = useState<NoShowStateType>({
    loading: false,
    data: {},
    error: null,
  });
  const [checkInData, setCheckInData] = useState<CheckInStateType>({
    loading: false,
    data: {},
    error: null,
  });

  const [campaigns, setCampaigns] = useState<UpcomingDataType[]>([] as UpcomingDataType[]);
  const [checkInfo, setCheckInfo] = useState<UpcomingDataType>({
    booking_date: "",
    booking_id: 0,
    campaign_id: 0,
    campaign_name: "",
    campaign_photo: "",
    is_verified_influencer: "0",
    is_verified_merchant: "0",
    influencer: [],
    influencer_photo: "",
    status: 0,
    time_slot: "",
    verify_code: 0,
  });

  const [paginationInfo, setPaginagtionInfo] = useState<PaginationInfoType>({
    total: 0,
    currentPage: 1,
    perPage: 0,
    lastPage: 0,
    activePage: 1,
    nextPage: false,
    prevPage: false,
  });

  useEffect(() => {
    setLoading(true);
    axios.get("/merchant/booking-list", { params: { search_type: 'upcoming', page: paginationInfo?.currentPage } }).then((res) => {
      console.log('UPCOMING', res);
      if (res?.status === 200 && res?.data?.success) {
        const result = res?.data;

        setCampaigns(result?.data);

        setPaginagtionInfo((prevState) => ({
          ...prevState,
          activePage: result?.paginate?.current_page,
          currentPage: result?.paginate?.current_page,
          total: result?.paginate?.total,
          perPage: result?.paginate?.per_page,
          lastPage: result?.paginate?.last_page,
        }));

        if (result?.paginate?.next_url === null) {
          setPaginagtionInfo((prevState) => ({
            ...prevState,
            nextPage: false,
          }));
        } else {
          setPaginagtionInfo((prevState) => ({
            ...prevState,
            nextPage: true,
          }));
        }

        if (result?.paginate?.prev_url === null) {
          setPaginagtionInfo((prevState) => ({
            ...prevState,
            prevPage: false,
          }));
        } else {
          setPaginagtionInfo((prevState) => ({
            ...prevState,
            prevPage: true,
          }));
        }
      }
    }).catch((err) => {

    }).finally(() => {
      setLoading(false);
    });
  }, [noShowData, checkInData]);

  const handlePageChange = () => {
    if (paginationInfo?.nextPage) {
      setPaginagtionInfo((prevState) => ({
        ...prevState,
        currentPage: prevState?.currentPage + 1,
      }));
    }

    if (paginationInfo?.prevPage) {
      setPaginagtionInfo((prevState) => ({
        ...prevState,
        currentPage: prevState?.currentPage - 1,
      }));
    }
  };


  const BookingNoShowHandler = (bookingId: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to no show this campaign?",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      confirmButtonColor: '#148FF9',
      cancelButtonColor: '#32343F',
    }).then((result) => {
      if (result?.isConfirmed) {
        axios.post("/merchant/no-show-booking", { booking_id: bookingId })
          .then((res) => {
            // console.log('No Show', res);
            if (res?.status === 200 && res?.data?.success) {
              const result = res?.data;
              // console.log('No Show', result);
              setNoShowData((prevState) => ({
                ...prevState,
                data: result?.data,
                error: "There is something wrong",
              }));

              toast.success("No show was successful!");
            }
          })
          .catch((err) => {
            // console.log('No Show', err.response);
            setNoShowData((prevState) => ({
              ...prevState,
              error: "There is something wrong",
            }));
          });
      }
    })
  }

  const handleClose = () => {
    setCheckInfo({
      ...checkInfo,
      booking_date: "",
      booking_id: 0,
      campaign_id: 0,
      campaign_name: "",
      campaign_photo: "",
      influencer: [],
      influencer_photo: "",
      status: 0,
      time_slot: "",
      verify_code: 0,
    });
    setShow(false)
  };

  const handleShow = (info: UpcomingDataType) => {
    setCheckInfo({
      ...checkInfo,
      booking_date: info?.booking_date,
      booking_id: Number(info?.booking_id),
      campaign_id: Number(info?.campaign_id),
      campaign_name: info?.campaign_name,
      campaign_photo: info?.campaign_photo,
      influencer: info?.influencer,
      influencer_photo: info?.influencer_photo,
      status: Number(info?.status),
      time_slot: info?.time_slot,
      verify_code: Number(info?.verify_code),
    });
    setShow(true)
  };

  const timeSlot = (time: string) => {
    const new_time = time;
    const [hours_1st, minutes_1st, hours_2nd, minutes_2nd] = new_time.split(':');
    const timeSlo_01 = hours_1st + ':' + minutes_1st;
    const timeSlo_02 = hours_2nd + ':' + minutes_2nd;
    return [timeSlo_01, timeSlo_02];
  }


  const BookingCheckInHandler = (bookingId: number, verifyCode: number) => {
    axios.post("/merchant/verify-pin", {
      booking_id: bookingId,
      verify_code: verifyCode,
    })
      .then((res) => {
        // console.log('Check-in', res);
        if (res?.status === 200 && res?.data?.success) {
          const result = res?.data;
          // console.log('Check-in', result);
          setCheckInData((prevState) => ({
            ...prevState,
            data: result?.data,
            error: "There is something wrong",
          }));

          toast.success("Check-in successfully verified!");
          handleClose();
        }
      })
      .catch((err) => {
        // console.log('No Show', err.response);
        setCheckInData((prevState) => ({
          ...prevState,
          error: "There is something wrong",
        }));
      });
  }

  // console.log('checkInfo', checkInfo);


  return loading ? (<>...loading</>) : (
    <>
      <div className="d-flex mb-5 gap-3 align-items-center">
        <Back /> <h2 className="mb-0">Upcoming Campaigns</h2>
      </div>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card'>
            <div className='card-body pt-0'>
              <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    id='kt_subscriptions_table'
                  >
                    <thead>
                      <tr className='text-start text-dark fw-bolder fs-7 text-capitalize gs-0'>
                        <th className='min-w-125px sorting'>Image</th>
                        <th className='min-w-125px sorting'>Influencer Name</th>
                        <th className='min-w-125px sorting'>Campaign Name</th>
                        <th className='min-w-125px sorting'>Schedule</th>
                        <th className='min-w-125px  sorting_disabled text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                      {loading ? (
                        <>
                          {new Array(3).fill(0).map((_, index) => (
                            <InfluencerSkeleton key={index} />
                          ))}
                        </>
                      ) : campaigns?.length === 0 ? (
                        <tr>
                          <td colSpan={6} className='text-center'>
                            No Data Found
                          </td>
                        </tr>
                      ) : (
                        <>
                          {campaigns?.map((campaign: any, index: any) => (
                            <tr className='odd align-middle' key={`${campaign?.booking_id}.${index}`}>
                              <td>
                                <picture>
                                  <img
                                    src={campaign?.influencer?.photo_url}
                                    alt={campaign?.influencer?.full_name}
                                    style={{
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      borderRadius: '10px',
                                    }}
                                  />
                                </picture>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-center flex-column'>
                                    {campaign?.influencer?.full_name}
                                  </div>
                                </div>
                              </td>
                              <td>{campaign?.campaign?.name}</td>
                              <td>
                                {campaign?.booking_date} -
                                {moment(timeSlot(`${campaign?.time_slot}`)[0], "LT").format("LT")} - {moment(timeSlot(`${campaign?.time_slot}`)[1], "LT").format("LT")}
                              </td>
                              <td>
                                <div className='w-100 d-flex justify-content-center gap-1'>
                                  <Button
                                    variant='light'
                                    className='btn btn-secondary btn-active-light-primary btn-sm'
                                    onClick={() => { handleShow(campaign) }}
                                    title="Approve"
                                    disabled={campaign?.campaign?.is_verified_influencer !== "0"}
                                  >
                                    Check-In
                                  </Button>
                                  <Button
                                    variant='light'
                                    className='btn btn-secondary btn-active-light-primary btn-sm'
                                    onClick={() => { BookingNoShowHandler(campaign?.booking_id) }}
                                    title="No-Show"
                                  >
                                    No-Show
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {/* {['3', '4'].includes(currentUser?.user_type as string) && ()} */}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='col-sm-12'></div>
              </div>
            </div>

            <div className='card-footer p-4 mb-2 bg-secondary'>
              <div className='d-flex flex-stack flex-wrap'>
                <div className='fs-6 fw-bold text-gray-700'>
                  Showing {paginationInfo?.currentPage} to {paginationInfo?.perPage} of {paginationInfo?.total} entries
                </div>

                <div className='pagination'>
                  <Pagination
                    hideFirstLastPages={false}
                    hideNavigation={true}
                    linkClassFirst='next'
                    linkClassPrev='previous'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={paginationInfo?.activePage}
                    itemsCountPerPage={paginationInfo?.perPage}
                    totalItemsCount={paginationInfo?.total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <span className='w-100 text-center d-flex justify-content-center gap-1'>
                      <Modal.Title>Check-in</Modal.Title>
                    </span>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='w-100 mt-5 mb-5'>
                      <picture className='w-100 text-center d-flex justify-content-center gap-1 mb-4'>
                        <img
                          src={checkInfo?.influencer?.photo_url}
                          alt={checkInfo?.influencer?.full_name}
                          style={{
                            width: '120px',
                            height: '120px',
                            objectFit: 'cover',
                            borderRadius: '100%',
                          }}
                        />
                      </picture>

                      <div className='w-100 text-center d-flex justify-content-center gap-1 mb-4 fs-3'>
                        {checkInfo?.influencer?.full_name}
                      </div>
                      <div className='w-100 text-center d-flex justify-content-center gap-1 mb-4 fs-4'>
                        <FaCalendar className='fs-4 ml-4 mt-1' /> {checkInfo?.booking_date}
                      </div>
                      <div className='w-100 text-center d-flex justify-content-center gap-1 mb-5 fs-4'>
                        <BiTimeFive className='fs-4 ml-4 mt-1' />
                        {moment(timeSlot(`${checkInfo?.time_slot}`)[0], "LT").format("LT")} - {moment(timeSlot(`${checkInfo?.time_slot}`)[1], "LT").format("LT")}
                      </div>
                      <div className='w-100 text-center d-flex justify-content-center gap-1 mb-5 fs-2'>
                        {checkInfo?.verify_code}
                      </div>
                    </div>
                    <br />
                    <div className='w-100 text-center d-flex justify-content-center gap-1 mt-5'>
                      <Button variant="primary" onClick={() => BookingCheckInHandler(checkInfo?.booking_id, checkInfo?.verify_code)}>
                        Complete the Check-In
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingCampaign;