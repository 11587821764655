import {useState} from 'react'
import clsx from 'clsx'
import {Field} from 'formik'
import classes from "./input.module.scss"

import EyeSlash from '../../../_metronic/assets/icons/EyeSlash'
import Eye from '../../../_metronic/assets/icons/Eye'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string
  containerClass?: string
  labelClass?: string
  inputClass?: string
  label?: string
  type?: string
  error?: boolean
  errorText?: string | any
  errorColor?: string
  required?: boolean
}

const Input: React.FC<InputProps> = (props) => {
  const {
    id = '',
    containerClass = '',
    labelClass = '',
    inputClass = '',
    placeholder = '',
    label = '',
    type = 'text',
    error = false,
    errorText = '',
    errorColor = 'text-red-600',
    required = false,
    ...rest
  } = props

  const [isPassword, setIsPassword] = useState(false)
  const inputClasses = clsx(
    `form-control bg-transparent ${errorText && error ? 'is-invalid' : ''}  ${inputClass}`
  )
  return (
    <div className={`${classes.container_input} ${containerClass}`}>
      {label ? (
        <label htmlFor={id} className={`form-label fw-bolder fs-6 ${labelClass}`}>
          {label} {required && <span className='text-red'>*</span>}
        </label>
      ) : (
        ''
      )}
      {type === 'password' ? (
        <div className={classes.password_input}>
          <Field
            type={isPassword ? 'text' : type}
            className={inputClasses}
            id={id}
            placeholder={placeholder}
            autoComplete={false}
            {...rest}
          />
          {isPassword ? (
            <button
              type='button'
              onClick={(e) => setIsPassword(false)}
              className={`btn ${classes.eye_btn}`}
            >
              <Eye className={classes.svg_stroke} />
            </button>
          ) : (
            <button
              type='button'
              onClick={(e) => setIsPassword(true)}
              className={`btn ${classes.eye_btn}`}
            >
              <EyeSlash className={classes.svg_stroke} />
            </button>
          )}
        </div>
      ) : (
        <Field type={type} className={inputClasses} id={id} placeholder={placeholder} {...rest} />
      )}

      {errorText && error ? <p className={`${classes.error}  ${errorColor}`}>{errorText}</p> : ''}
    </div>
  )
}

export default Input
