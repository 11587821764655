/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { isNotEmpty, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
// import {
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
//   CardsWidget7,
//   CardsWidget17,
//   CardsWidget20,
//   ListsWidget26,
//   EngageWidget10,
//   ChartsWidget2,
//   StatisticsWidget3,
// } from '../../../_metronic/partials/widgets'

import { ActiveCampaign } from '../../components/dashboard/ActiveCampaign'
import { TotalBooking } from '../../components/dashboard/TotalBooking'
import { InfluencerJoin } from '../../components/dashboard/InfluencerJoin'
import { CampaignChart } from '../../components/dashboard/CampaignChart'

import { APIURL } from '../../../config/config'
import { parseCookies } from 'nookies'
import axios from '../../../_metronic/hooks/axiosPrivate';

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = useState(true)
  const [dashData, setDashData] = useState([])

  const fetchDash = async () => {
    setLoading(true);
    axios.get('merchant/dashboard')
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.success) {
            const result = res?.data?.data;
            setDashData(result);
          }
        }
      })
      .catch((err) => {
        console.error("E", err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchDash();
  }, []);

  console.log('RESULT', dashData);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xxl-6'>
          <div className='row mb-5'>
            <div className='col-md-6' style={{ height: '185px' }}>
              {loading ? (
                <></>
              ) : (
                <>
                  {isNotEmpty(dashData) ? <ActiveCampaign
                    className='h-100'
                    description='Total Campaign'
                    color='#F1416C'
                    img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                    dashData={dashData}
                  /> : null}
                </>
              )}
            </div>

            <div className='col-md-6' style={{ height: '185px' }}>
              {loading ? (
                <></>
              ) : (
                <>
                  {isNotEmpty(dashData) ? <TotalBooking className='' dashData={dashData} /> : null}
                </>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              {loading ? (
                <></>
              ) : (
                <>
                  {isNotEmpty(dashData) ? <InfluencerJoin
                    className=''
                    color='success'
                    title='Influencer'
                    description='Weekly Influencer Booking'
                    change='+100'
                    dashData={dashData}
                  /> : null}
                </>
              )}
            </div>
          </div>
        </div>

        <div className='col-xxl-6'>
          {loading ? (
            <></>
          ) : (
            <>{isNotEmpty(dashData) ? <CampaignChart className='h-md-100' dashData={dashData} /> : null}</>
          )}
        </div>
      </div>
    </>
  )
}

export { DashboardWrapper }
