import * as React from 'react'
import Select from 'react-select'
import isEqual from 'react-fast-compare'

interface ITenure {
  tenure: string
  setTenure: React.Dispatch<React.SetStateAction<string>>
}

const customStyles = {
  container: (base: any, state: any) => ({
    ...base,
    'react-select-3-listbox': {
      background: '#323248 !important'
    }
  }),
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: '#1e1e2d',
    border: '1px solid #323248',
    minHeight: '44px',
    '&:hover': {
      border: '1px solid #323248',
    }
  }),
  menuList: (base: any, state: any) => ({
    ...base,
    padding: 0,
    backgroundColor: 'white',
    color: '#92929f',
  }),
  singleValue: (base: any, state: any) => ({
    ...base,
    color: '#92929f',
    fontSize: '1.1rem',
    fontWeight: 500,
  }),
  input: (base: any, state: any) => ({
    ...base,
    color: '#fff',
  })
}

const Tenure = ({tenure, setTenure}: ITenure) => {
  const tenures: TenureType[] = new Array(12).fill(0).map(
    (_, i) =>
      ({
        value: `${i + 1}`,
        label: `${i + 1} Month${i + 1 > 1 ? 's' : ''}`,
      } as TenureType)
  )

  const handleTenureChange = (tenure: TenureType) => {
    setTenure(tenure.value)
  }

  return (
    <section className='col-sm-12 col-md-4'>
      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
        <span>Campaign Tenure</span>
      </label>
      <Select
        styles={customStyles}
        options={tenures}
        name='location'
        value={tenures?.find((item: TenureType) => item?.value === tenure)}
        placeholder='Select Location'
        onChange={(e) => handleTenureChange(e as TenureType)}
        isDisabled={tenures?.length === 0}
      />
    </section>
  )
}

export default React.memo(Tenure, isEqual)
