import React, { useEffect, useState } from 'react';
import axios from '../../../../_metronic/hooks/axiosPrivate';
import { APIURL } from '../../../../config/config'

import { parseCookies } from 'nookies'
import { useParams, useLocation } from 'react-router-dom';
import queryString from "query-string";

import { Button, Card, Image, Spinner } from 'react-bootstrap';
import Back from '../../../../app/components/common/BackButton';
import moment from 'moment';
import { toast } from 'react-toastify'
import useDebounce from '../Invitation/useDebounce';
import useSWR from 'swr';

type SocialMediaDataType = {
  profile_url: string;
  followers_screenshot: string;
}

type InfluencerDataType = {
  influencer_id: number;
  address: string;
  about: string;
  full_name: string;
  photo_url: string;
  email: string;
  phone: string;
  gender: string;
  dob: string;
  location: string;
  zip_code: string;
  social_newtworks: SocialMediaDataType[];
  categories: string;
  invitation_status: string;
}

const Trow: React.FC<{ title: string; value: string }> = ({ title, value }) => (
  <tr>
    <td className="px-3 fs-4 text-end">{title}</td>
    <td className="fs-4">{value}</td>
  </tr>
)


const InfluencerDetail = () => {
  const params = useParams();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const cookies = parseCookies();
  const token = cookies?.token;

  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [influencer, setInfluencer] = useState<InfluencerDataType>({} as InfluencerDataType);
  const [skillId, setSkillId] = React.useState('');


  const [total, setTotal] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [perPage, setPerpage] = React.useState<number>(0);
  const [lastPage, setLastPage] = React.useState<number>(0);
  const [activePage, setActivePage] = React.useState<number>(1);
  const [nextPage, setNextPage] = React.useState<boolean>(false);
  const [prevPage, setPrevPage] = React.useState<boolean>(false);

  const [report, setReport] = React.useState<any[]>([]);

  const debouncedNameTerm: string = useDebounce(name, 500);
  const debouncedEmailTerm: string = useDebounce(email, 500);


  const fetcher = async (url: string) => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    const requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    console.log("ITEM", url);
    const response = await fetch(url, requestOptions)
    console.log("ITEM", response);

    const result = await response.json()
    if (result.success === true) {
      let newData: any[] = []

      result?.data?.forEach((item: any) => {
        console.log('ITEM', item);
        newData.push({
          profile_image: item?.photo_url,
          name: item?.full_name,
          email: item?.email,
          phone: item?.phone,
          followers: item?.social_stats
            ?.map((social: any) => {
              return `${social.name}: ${social.followers}`
            })
            .join(', '),
          skills: item?.skills
            ?.map((skill: any) => {
              return skill.skill_name
            })
            .join(', '),
        })
      });

      setReport(newData)

      setActivePage(result?.paginate?.current_page)
      setTotal(result?.paginate?.total)
      setCurrentPage(result?.paginate?.current_page)
      setPerpage(result?.paginate?.per_page)
      setLastPage(result?.paginate?.last_page)

      if (result?.paginate?.next_url === null) {
        setNextPage(false)
      } else {
        setNextPage(true)
      }

      if (result?.paginate?.prev_url === null) {
        setPrevPage(false)
      } else {
        setPrevPage(true)
      }

      return result?.data
    }
  }

  const {
    data: influencerList,
    error,
    isLoading,
    mutate,
  } = useSWR(
    `${APIURL}/merchant/influencer-list?campaign_id=${query?.cid}&page=${currentPage}&name=${debouncedNameTerm}&email=${debouncedEmailTerm}&skill_id=${skillId}`,
    fetcher
  );

  useEffect(() => {
    if (params?.id) {
      setLoading(true);
      axios.get("/merchant/influencer", { params: { id: params?.id } }).then((res) => {
        console.log('influencer :--', res)
        if (res?.status === 200 && res?.data?.success) {
          const result = res?.data?.data;
          setInfluencer(result);
        }
      }).catch((err) => {
        console.log(err.response);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [params?.id]);

  console.log('query?.cid', query?.cid)

  return loading ? (<>...loading</>) : (
    <>
      <div className="d-flex mb-5 gap-3 align-items-center">
        <Back /> <h2>Influencer Details</h2>
      </div>
      <Card className="p-6">
        <div className="d-flex  flex-column">
          <Image src={influencer?.photo_url} roundedCircle width={200} height={200} />
          <div className='w-100'>
            <div className='w-100 mt-4 mb-2 border-bottom'>
              <h4 className="fs-2 test-start">About</h4>
              <p>{influencer?.about}</p>
            </div>
            <div className='w-100 mt-4 mb-2 border-bottom'>
              <h4 className="fs-2 test-start">Categories</h4>
              <p>
                {
                  Array.isArray(influencer?.categories) && influencer?.categories?.length > 0 ?
                    influencer?.categories.map((cat) => (
                      <span className="btn bg-primary btn-sm ms-2" key={cat?.id}>{cat?.name}
                      </span>
                    )) : null
                }
              </p>
            </div>
            <div className='w-100 mt-4 mb-2 border-bottom'>
              <h4 className="fs-2 test-start">Personal Information</h4>
              <div className='w-100 row g-3 mb-4'>
                <div className="col-sm-12 col-md-6">
                  <label htmlFor="Name" className="form-label">
                    Name
                  </label>
                  <span id="Name" className="form-control">
                    {influencer?.full_name}
                  </span>
                </div>
                <div className="col-sm-12 col-md-6">
                  <label htmlFor="Email" className="form-label">
                    Email
                  </label>
                  <span id="Email" className="form-control">
                    {influencer?.email}
                  </span>
                </div>
                <div className="col-sm-12 col-md-6">
                  <label htmlFor="Name" className="form-label">
                    Date of birth
                  </label>
                  <span id="Name" className="form-control">
                    {influencer?.dob}
                  </span>
                </div>
                <div className="col-sm-12 col-md-6">
                  <label htmlFor="Email" className="form-label">
                    Gender
                  </label>
                  <span id="Email" className="form-control">
                    {influencer?.gender}
                  </span>
                </div>
                <div className="col-sm-12 col-md-6">
                  <label htmlFor="Name" className="form-label">
                    Location
                  </label>
                  <span id="Name" className="form-control">
                    {influencer?.location}
                  </span>
                </div>
                <div className="col-sm-12 col-md-6">
                  <label htmlFor="Email" className="form-label">
                    Zip code
                  </label>
                  <span id="Email" className="form-control">
                    {influencer?.zip_code}
                  </span>
                </div>
              </div>
            </div>

            <div className='w-100 mt-4 mb-2 border-bottom'>
              <h4 className="fs-2 test-start"> Social Networks</h4>
              <div className='w-100'>
                {
                  Array.isArray(influencer?.social_newtworks) &&
                  influencer?.social_newtworks?.length > 0 &&
                  influencer?.social_newtworks.map((social) => <a key={Math.random() + new Date().getTime()} href={social?.profile_url} className="mx-1 mt-2 mb-2 d-flex" target="_blank" rel="noreferrer">
                    <img src={social?.followers_screenshot} width={40} height={40} alt="" />
                    <span className="ms-2 fs-3">{social?.profile_url}</span>
                  </a>)
                }
              </div>
            </div>

            <div className='w-100 mt-4 mb-2'>
              {
                query?.cid == undefined ? null :
                  <InviteBtn
                    campaign_id={query?.cid}
                    influencer_id={influencer.influencer_id}
                    invitation_status={influencer?.invitation_status}
                    mutate={mutate}
                  />
              }
            </div>

          </div>
        </div>
      </Card>
    </>
  );
}

export default InfluencerDetail;

const InviteBtn = ({
  campaign_id,
  influencer_id,
  invitation_status,
  mutate,
}: {
  campaign_id: any
  influencer_id: any
  invitation_status?: number | string
  mutate?: any
}) => {
  console.log('🚀 ~ file: index.tsx:345 ~ invitation_status:', invitation_status)
  const [loading, setLoading] = React.useState<boolean>(false)

  const cookies = parseCookies()
  const token = cookies?.token

  const addInvite = async (id: number) => {
    setLoading(true)
    const res = await fetch(`${APIURL}/merchant/request-for-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        influencer_id: id,
        campaign_id: campaign_id,
      }),
    })
    if (res?.status === 200) {
      toast.success('Invitation sent successfully')
      mutate()
      setLoading(false)
    } else {
      toast.error('You have already requested to the influencer')
      mutate()
      setLoading(false)
    }
  }

  return (
    <Button
      className='btn btn-light  btn-sm'
      size='sm'
      onClick={() => addInvite(influencer_id)}
      disabled={invitation_status === 1 || loading}
    >
      {loading ? (
        <Spinner animation='border' size='sm' />
      ) : invitation_status === 1 ? (
        'Invited'
      ) : (
        'Invite'
      )}
    </Button>
  )
}
