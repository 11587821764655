import * as React from 'react'
import isEqual from 'react-fast-compare'

interface IVoucher {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
}

const Voucher = ({campaignState, setCampaignState}: IVoucher) => {
  // make a function to generate voucher code with 6 digit
  const generateVoucher = () => {
    const voucher = Math.random().toString(36).substring(2, 8).toUpperCase()

    setCampaignState((pre) => ({
      ...pre,
      voucher: voucher.toString(),
    }))
  }

  return (
    <div className='col-md-12 col-sm-12 mb-3'>
      <div className='col-sm-12 col-md-6'>
        <div className='position-relative'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Promo</span>
          </label>
          <input
            type='text'
            name='voucher_code'
            value={campaignState.voucher}
            onChange={(e) =>
              setCampaignState((pre) => ({
                ...pre,
                voucher: e.target.value,
              }))
            }
            className='form-control'
            placeholder='Enter Voucher Code'
          />

          {/* <button
            className='btn btn-primary  position-absolute top-0 end-0 mt-8'
            type='button'
            // onClick={generateVoucher}
          >
            <span className='d-flex align-items-center'>
              <span className='me-2 text-uppercase'>Generate</span>
            </span>
          </button> */}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Voucher, isEqual)
