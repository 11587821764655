/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from 'react-router-dom'
import { useState, useEffect, ChangeEvent, SetStateAction } from 'react'
import { Formik, useFormikContext, FieldArray, Form } from 'formik'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify'
import { FaPlus, FaArrowLeft } from 'react-icons/fa'
import {
  registrationSchema2,
  registrationSchema3,
} from '../../../../_metronic/helpers/validations/auth'
import { BusinessData } from '../../../../_metronic/assets/ts/components/_auth'
import Information from '../components/PreviewInformation/index'
import { useQuery } from 'react-query'
import CoverPhoto from '../components/PreviewInformation/CoverPhoto'
import PhoneInput from '../../../components/PhoneInput'
import AsyncSelect from '../../../components/Input/AsyncSelect'
import Agree from '../components/PreviewInformation/Agree'
import Input from '../../../components/Input/Input'
import Select from '../../../components/Input/Select'


import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import CountrySelect from 'react-bootstrap-country-select';

import 'react-toastify/dist/ReactToastify.css'
import axios from '../../../../_metronic/hooks/axiosPrivate'

const prices = [
  { id: 1, value: '$' },
  { id: 2, value: '$$' },
  { id: 3, value: '$$$' },
  { id: 4, value: '$$$$' },
]

const initialValues = {
  f_name: '',
  l_name: '',
  business_name: '',
  profile_photo: '',
  phone: '',
  business_type: null,
  business_category_id: null,
  business_sub_category_id: null,
  no_of_employee: null,
  annual_revenue: null,
  price: '',
  representatives: [
    {
      r_first_name: '',
      r_last_name: '',
      r_email: '',
      business_location: '',
      isRepresentative: false,
      latitude: "",
      longitude: "",
    },
  ],
  step: 1,
}
const UpdateInformation = () => {
  const [businessSubCategory, setSubCategory] = useState([])
  const [isChecked, setIsChecked] = useState(false)

  const [country, setCountry] = useState({
    id: "us",
    name: "United States",
    flag: "🇺🇸",
    alpha2: "us",
    alpha3: "usa",
    ioc: "usa"
  });

  const navigate = useNavigate()
  const { data: businessTypes } = useQuery(
    'business-types',
    () => axios.get('/admin/get-business-types').then((res) => res?.data?.data),
    {
      cacheTime: 5 * 60 * 1000, // Cache data for 5 minutes
      staleTime: 1 * 60 * 1000, // Data is considered stale after 1 minute
      refetchOnWindowFocus: true,
    }
  )
  const { data: businessCategory } = useQuery(
    'categories',
    () => axios.get('/admin/categories').then((res) => res?.data?.data),
    {
      cacheTime: 5 * 60 * 1000, // Cache data for 5 minutes
      staleTime: 1 * 60 * 1000, // Data is considered stale after 1 minute
      refetchOnWindowFocus: true,
    }
  );

  const getSubCategory = async (business_category_id: any) => {
    try {
      const response = await axios.get(
        `admin/get-sub-categories?category_id=${business_category_id}`
      )
      setSubCategory(response?.data?.data)
      if (!response?.data?.data?.length) {
        // setFieldValue('business_sub_category_id', null)
      }
    } catch (error) {
      console.error('Error fetching sub-categories:', error)
    }
  }

  const { data: employees } = useQuery(
    'employees',
    () => axios.get('/admin/get-no-employees').then((res) => res?.data?.data),
    {
      enabled: true,
    }
  );
  const { data: businessRrevenues } = useQuery(
    'business-revenues',
    () => axios.get('/admin/get-business-revenues').then((res) => res?.data?.data),
    {
      enabled: true,
    }
  )

  const [loading, setLoading] = useState(false)
  const [viewData, setViewData] = useState({
    business_type: null,
    business_category_id: null,
    business_sub_category_id: null,
    no_of_employee: null,
    annual_revenue: null,
    price: '',
  });

  const onViewData = (name: string, item: any) => {
    if (name === 'business_category_id') {
      getSubCategory(item.id)
    }

    setViewData((prev) => ({
      ...prev, // Spread the previous state
      [name]: item.value, // Update the specific property using computed property name
    }))
  }

  function removeFields(jsonData: BusinessData) {
    let modifiedData: BusinessData = { ...jsonData }
    //@ts-ignore
    modifiedData.representatives = modifiedData.representatives.map((rep) => {
      const { isRepresentative, ...rest } = rep
      return rest
    })
    //@ts-ignore
    delete modifiedData.step

    return modifiedData
  }

  const submitHandler = async (
    _values: typeof initialValues,
    {
      setStatus,
      setSubmitting,
    }: { setStatus: (status?: any) => void; setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      setLoading(true)
      //@ts-ignore

      let fromData = removeFields(_values)
      const result = await axios.put(`/profile-update`, fromData)

      if (result.status === 200 && result.data.success === true) {
        toast.success(result.data.message)
        setTimeout(() => {
          navigate('/pending')
        }, 1500)
      } else if (result.status === 415) {
        setSubmitting(false)
        setLoading(false)
        // console.log("message", result);

        const fieldsToCheck = ['email', 'f_name', 'l_name', 'password', 'phone']
        fieldsToCheck.forEach((field) => {
          if (result?.data?.message?.[field]?.length > 0) {
            setStatus(result.data.message[field][0])
          }
        })
      }
    } catch (err: any) {
      let message =
        typeof err.response !== 'undefined'
          ? (err.response.data.message as string)
          : (err.message as string)
      setLoading(false)
      toast.error(message)
      // Handle error appropriately, e.g., show a toast message or update UI state
    }
  }

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={initialValues.step === 1 ? registrationSchema2 : registrationSchema3}
        onSubmit={(values, { resetForm, setStatus, setSubmitting }) =>
          submitHandler(values, { setStatus, setSubmitting })
        }
      >
        {({
          errors,
          values,
          setFieldValue,
          isValidating,
          isSubmitting,
          status,
          handleBlur,
          touched,
        }) => (
          <Form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework scrollspy-example'
            id='kt_login_signup_form'
          >
            {/* begin::Heading */}
            <div className='text-left mb-11'>
              {/* begin::Title */}
              <button
                onClick={() => {
                  if (values.step === 1) {
                    navigate('/registration')
                  } else if (values.step === 2) {
                    setFieldValue('step', 1)
                  } else {
                    setFieldValue('step', 2)
                  }
                }}
                type='button'
                className='btn btn-sm mb-3 d-flex  btn-outline-primary align-items-center'
              >
                <FaArrowLeft />{' '}
                <span
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  Back
                </span>
              </button>

              <h1 className='text-dark fw-bolder mb-3'>Hi There! </h1>
              {/* end::Title */}

              <div className=' fw-semibold fs-6'>
                Welcome to Elite List, Update your Information
              </div>
            </div>
            {/* end::Heading */}
            {status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{status}</div>
              </div>
            )}
            <CoverPhoto
              profileErr={errors.profile_photo}
              setFieldValue={(e: any) => setFieldValue('profile_photo', e)}
              step={values.step}
            />
            {values.step === 1 ? (
              <>
                <div className='row'>
                  <Input
                    id='f_name'
                    name='f_name'
                    label='First Name'
                    type='text'
                    errorText={errors.f_name}
                    error={touched.f_name}
                    onBlur={handleBlur}
                    inputClass=''
                    placeholder='Enter First Name'
                    containerClass='mb-6 col-md-6'
                  />
                  <Input
                    id='l_name'
                    name='l_name'
                    label='Last Name'
                    type='text'
                    errorText={errors.l_name}
                    error={touched.l_name}
                    onBlur={handleBlur}
                    inputClass=''
                    placeholder='Enter Last Name'
                    containerClass='mb-6 col-md-6'
                  />
                  <Input
                    id='business_name'
                    name='business_name'
                    label='Name of Business'
                    type='text'
                    errorText={errors.business_name}
                    error={touched.business_name}
                    onBlur={handleBlur}
                    inputClass=''
                    placeholder='Name of Business'
                    containerClass='mb-6 col-md-12'
                  />
                  {/* Country Select */}
                  <div className='mb-6 col-md-12'>
                    <CountrySelect
                      value={country}
                      //@ts-ignore
                      onChange={setCountry}
                      onTextChange={function (text: string, changeEvent: ChangeEvent<Element>): void {
                        // throw new Error('Function not implemented.')
                      }} />
                  </div>
                  {/* Country Select */}

                  <PhoneInput
                    id='phone'
                    name='phone'
                    label='Phone Number'
                    type='text'
                    errorText={errors.phone}
                    error={touched.phone}
                    onBlur={handleBlur}
                    inputClass=''
                    placeholder='Phone Number'
                    containerClass='mb-6 col-md-12'
                    country={country}
                  />
                </div>

                <FieldArray name='representatives'>
                  {({ insert, remove, push }) => (
                    <>
                      {values.representatives.map((_, index: number) => (
                        <div key={index} className='row representative-block mb-4'>
                          <div className="col-sm-12">
                            {index === 0 ? (
                              ''
                            ) : (
                              <div className="row">
                                <div className='mb-6 col-md-6'>
                                  <h2 className='text-white'>Business Location {index + 1}</h2>
                                </div>
                                <div className='mb-6 col-md-6 text-end'>
                                  <AiOutlineCloseCircle onClick={() => remove(index)} className="text-danger fs-1 cursor-pointer" />
                                </div>
                              </div>
                            )}
                            <AsyncSelect
                              name={`representatives.${index}.business_location`}
                              id={`representatives.${index}.business_location`}
                              /* @ts-ignore */
                              errorText={errors.representatives?.[index]?.business_location}
                              errorDuplicate={errors.representatives}
                              error={touched.representatives?.[index]?.business_location}
                              label='Business Location'
                              inputClass='text-black'
                              labelClass='text-white'
                              placeholder='Enter Business Location'
                              containerClass='mb-6 col-md-12'
                              country={country}
                              index={index}
                              setFieldValue={setFieldValue}
                            />
                            {_.isRepresentative ? (
                              <div className="row">
                                <div className='col-md-12 my-5'>
                                  <h2 className='text-white'>Representative Details</h2>
                                </div>
                                <Input
                                  name={`representatives.${index}.r_first_name`}
                                  id={`representatives.${index}.r_first_name`}
                                  /* @ts-ignore */
                                  errorText={errors?.representatives?.[index]?.r_first_name}
                                  error={touched?.representatives?.[index]?.r_first_name}
                                  label='First Name'
                                  type='text'
                                  onBlur={handleBlur}
                                  inputClass=''
                                  placeholder='Enter First Name'
                                  containerClass='mb-6 col-md-6'
                                  labelClass='text-white'
                                />
                                <Input
                                  name={`representatives.${index}.r_last_name`}
                                  id={`representatives.${index}.r_last_name`}
                                  label='Last Name'
                                  type='text'
                                  /* @ts-ignore */
                                  errorText={errors?.representatives?.[index]?.r_last_name}
                                  error={touched?.representatives?.[index]?.r_last_name}
                                  onBlur={handleBlur}
                                  inputClass=''
                                  placeholder='Enter Last Name'
                                  containerClass='mb-6 col-md-6'
                                  labelClass='text-white'
                                />
                                <Input
                                  name={`representatives.${index}.r_email`}
                                  id={`representatives.${index}.r_email`}
                                  label='Email'
                                  type='eamil'
                                  /* @ts-ignore */
                                  errorText={errors?.representatives?.[index]?.r_email}
                                  error={touched?.representatives?.[index]?.r_email}
                                  onBlur={handleBlur}
                                  inputClass=''
                                  placeholder='Enter Your Email'
                                  containerClass='mb-6 col-md-12'
                                  labelClass='text-white'
                                />
                              </div>
                            ) : (
                              <div className='col-md-12 text-end'>
                                <button
                                  type='button'
                                  onClick={(e) =>
                                    setFieldValue(`representatives.${index}.isRepresentative`, true)
                                  }
                                  className='btn btn-sm text-primary pe-0 mb-1'
                                >
                                  <FaPlus /> Add Representative for above location
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className='row justify-content-start'>
                        <div className='col-md-6'>
                          <button
                            type='button'
                            className='btn btn-sm text-primary mb-1'
                            onClick={() =>
                              push({
                                r_first_name: '',
                                r_last_name: '',
                                r_email: '',
                                business_location: '',
                              })
                            }
                          >
                            <FaPlus /> Add more location
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </FieldArray>

                <div className='text-center'>
                  <button
                    type='button'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    onClick={() => {
                      if (values.step === 1 && registrationSchema2.isValidSync(values)) {
                        setFieldValue('step', 2)
                      }
                    }}
                    disabled={!registrationSchema2.isValidSync(values) || isValidating}
                  >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </>
            ) : values.step === 2 ? (
              <>
                <Select
                  options={businessTypes}
                  id='business_type'
                  name='business_type'
                  label='Business Type'
                  valueLabel='value'
                  onDataChange={onViewData}
                  value='id'
                  type='text'
                  errorText={errors.business_type}
                  error={touched.business_type}
                  onBlur={handleBlur}
                  inputClass='text-black'
                  placeholder='Select Type'
                  containerClass='mb-6 col-md-12'
                />
                <Select
                  value='id'
                  onDataChange={onViewData}
                  options={businessCategory}
                  id='business_category_id'
                  name='business_category_id'
                  label='Business Category'
                  valueLabel='name'
                  type='text'
                  errorText={errors.business_category_id}
                  error={touched.business_category_id}
                  onBlur={handleBlur}
                  inputClass='text-black'
                  placeholder='Select Type'
                  containerClass='mb-6  col-md-12'
                />
                <Select
                  value='id'
                  onDataChange={onViewData}
                  disabled={!businessSubCategory?.length && !values?.business_category_id}
                  options={businessSubCategory}
                  id='business_sub_category_id'
                  name='business_sub_category_id'
                  label='Sub Category'
                  valueLabel='name'
                  type='text'
                  errorText={errors.business_sub_category_id}
                  error={touched.business_sub_category_id}
                  onBlur={handleBlur}
                  inputClass='text-black'
                  placeholder='Select Sub Category'
                  containerClass='mb-6  col-md-12'
                />

                <Select
                  options={prices}
                  id='price'
                  name='price'
                  label='Price'
                  valueLabel='value'
                  onDataChange={onViewData}
                  value='id'
                  errorText={errors.price}
                  error={touched.price}
                  onBlur={handleBlur}
                  inputClass='text-black'
                  placeholder='$$$$'
                  containerClass='mb-6  col-md-12'
                />
                <Select
                  options={employees}
                  id='no_of_employee'
                  name='no_of_employee'
                  label='No. Of Employees'
                  valueLabel='value'
                  onDataChange={onViewData}
                  value='id'
                  type='text'
                  errorText={errors.no_of_employee}
                  error={touched.no_of_employee}
                  onBlur={handleBlur}
                  inputClass='text-black'
                  placeholder='Select'
                  containerClass='mb-6  col-md-12'
                />
                <Select
                  options={businessRrevenues}
                  id='annual_revenue'
                  name='annual_revenue'
                  label='Annual Business Revenue'
                  valueLabel='value'
                  onDataChange={onViewData}
                  value='id'
                  type='text'
                  errorText={errors.annual_revenue}
                  error={touched.annual_revenue}
                  onBlur={handleBlur}
                  inputClass='text-black'
                  placeholder='Revenue'
                  containerClass='mb-6  col-md-12'
                />
                <div className='text-center'>
                  <button
                    type='button'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    onClick={() => {
                      if (values.step === 2 && registrationSchema3.isValidSync(values)) {
                        setFieldValue('step', 3)
                      }
                    }}
                    disabled={!registrationSchema3.isValidSync(values) || isValidating}
                  >
                    {!loading && <span className='indicator-label'>Continue</span>}

                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                {/* @ts-ignore */}
                <Information viewData={viewData} values={values} />
                <Agree isChecked={isChecked} setIsChecked={setIsChecked} />
                <div className='text-center mt-5'>
                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={
                      isSubmitting ||
                      !isChecked ||
                      !registrationSchema3.isValidSync(values) ||
                      !registrationSchema2.isValidSync(values)
                    }
                  >
                    {!loading && <span className='indicator-label'>Apply</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </>
            )}
            {/* end::Form group */}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UpdateInformation;

