import React from 'react';
import { ReportStateType } from './type';
import { Link } from 'react-router-dom';

type CampaignStateTPropsType = {
  reports: ReportStateType;
}


const CampaignState: React.FC<CampaignStateTPropsType> = ({ reports }) => {

  console.log('reports', reports);

  return (
    <div className="row mb-8">
      <div className="col-md-2 text-center">
        <Link className="btn btn-primary w-100 p-4 text-center" to="pending-invites">
          <h4 className="fs-1">{reports?.pending ? reports?.pending : Number(0)}</h4>
        </Link>
        <h4 className="fs-4 mt-2">Pending Invites</h4>
      </div>
      <div className="col-md-2 text-center">
        <Link className="btn btn-warning w-100 p-4 text-center" to="upcoming">
          <h4 className="fs-1 text-black">{reports?.upcoming ? reports?.upcoming : Number(0)}</h4>
        </Link>
        <h4 className="fs-4 mt-2">Upcoming Campaigns</h4>
      </div>

      <div className="col-md-2 text-center">
        <Link className="btn btn-danger w-100 p-4 text-center" to="in-process">
          <h4 className="fs-1 text-black">{reports?.inProcess ? reports?.inProcess : Number(0)}</h4>
        </Link>
        <h4 className="fs-4 mt-2">In Process Campaigns</h4>
      </div>

      <div className="col-md-2 text-center">
        <Link className="btn btn-success w-100 p-4 text-center" to="completed">
          <h4 className="fs-1">{reports?.completed ? reports?.completed : Number(0)}</h4>
        </Link>
        <h4 className="fs-4 mt-2">Completed Campaigns</h4>
      </div>

      <div className="col-md-2 text-center">
        <Link className="btn btn-info w-100 p-4 text-center" to="no-show">
          <h4 className="fs-1">{reports?.noShow ? reports?.noShow : Number(0)}</h4>
        </Link>
        <h4 className="fs-4 mt-2">No Show Campaigns</h4>
      </div>

      <div className="col-md-2"></div>
    </div>
  )
}

export default CampaignState;