/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'

import Table from 'react-bootstrap/Table'
import Pagination from 'replace-js-pagination'
import {parseCookies} from 'nookies'
import {Button} from 'react-bootstrap'
import InfluencerActionDialog from './InfluencerActionDialog'
import {APIURL} from '../../../../config/config'
import UnBlockBtn from './UnBlockBtn'
import InfluencerSkeleton from './InfluencerSkeleton'
import ReviewDialog from './ReviewDialog'

const Influencer: React.FC = () => {
  const [open, setModalOpen] = React.useState<boolean>(false)
  const [show, setShow] = React.useState<boolean>(false)
  const cookies = parseCookies()
  const token = cookies?.token

  const [influencerList, setInfluencerList] = React.useState<any>([])
  const [inFluencerInfo, setInFluencerInfo] = React.useState<any>({})

  const [loading, setLoading] = React.useState<boolean>(true)
  const [blockLoading, setBlockLoading] = React.useState<boolean>(false)

  const [total, setTotal] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [perPage, setPerpage] = React.useState<number>(0)
  const [lastPage, setLastPage] = React.useState<number>(0)
  const [activePage, setActivePage] = React.useState<number>(1)

  const fetchInfluencer = async () => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/influencer-by-booking`, requestOptions)
    const result = await response.json()
    if (result.success === true) {
      setInfluencerList(result?.data?.slice(0, 10))
    }
    setLoading(false)
    setBlockLoading(false)
  }

  React.useEffect(() => {
    fetchInfluencer()
    setTotal(influencerList?.length)
    setCurrentPage(1)
    setPerpage(10)
    setLastPage(influencerList?.length / 10)
  }, [activePage, blockLoading])

  const handlePageChange = (page: number) => {
    setLoading(true)
    setInfluencerList(influencerList?.slice((page - 1) * 10, page * 10))
    setActivePage(page)
    setLoading(false)
  }

  const handleInfluencerAction = (influencer: any) => {
    setInFluencerInfo(influencer)
    setModalOpen(true)
  }

  const handleInfluencerReview = (influencer: any) => {
    setInFluencerInfo(influencer)
    setShow(true)
  }

  return (
    <>
      <InfluencerActionDialog
        show={open}
        onHide={() => setModalOpen(false)}
        inFluencerInfo={inFluencerInfo}
        blockLoading={blockLoading}
        setBlockLoading={setBlockLoading}
      />

      <ReviewDialog show={show} onHide={() => setShow(false)} bookingListInfo={inFluencerInfo} />

      <div
        className='row g-5 g-xl-10 mb-xl-10'
        style={{
          marginTop: '-60px',
        }}
      >
        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 bg-success py-5'>
              <h3 className='card-title fw-bolder text-white'>Influencer List</h3>
            </div>
            <div className='card-body p-2'>
              <div className='row mb-4'>
                <div className='col-sm-12'>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className='fs-4 text-center text-capitalize'>Profile Photo</th>
                        <th className='fs-4 text-center text-capitalize'>Name</th>
                        <th className='fs-4 text-center text-capitalize'>Email</th>
                        <th className='fs-4 text-center text-capitalize'>Phone</th>
                        <th className='fs-4 text-center text-capitalize'>Address</th>
                        <th className='fs-4 text-center text-capitalize'>Status</th>
                        <th className='fs-4 text-center text-capitalize'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <>
                          {new Array(influencerList?.length > 0 ? influencerList?.length : 10)
                            .fill(0)
                            .map((item, index) => (
                              <InfluencerSkeleton column={5} height={40} key={index} />
                            ))}
                        </>
                      )}

                      {influencerList?.length === 0 && (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            No Data Found
                          </td>
                        </tr>
                      )}

                      {!loading &&
                        influencerList &&
                        influencerList.map((item: any, index: number) => (
                          <tr key={`${item.influencer_id}.${index}`} className='align-middle'>
                            <td className='text-center'>
                              <picture className='symbol symbol-45px me-5'>
                                <img
                                  src={item?.influencer_photo_url}
                                  alt={item?.influencer_name}
                                  style={{width: '80px', height: '80px', objectFit: 'cover'}}
                                />
                              </picture>
                            </td>
                            <td className='text-center'>{item?.influencer_name}</td>
                            <td className='text-center'>{item?.influencer_email}</td>
                            <td className='text-center'>{item?.influencer_phone}</td>
                            <td className='text-center'>{item?.influencer_address}</td>
                            <td className='text-center text-capitalize'>{item?.block_status}</td>
                            <td
                              className='text-center d-flex gap-3 align-items-center justify-content-center'
                              style={{
                                minHeight: '100px',
                              }}
                            >
                              {item?.block_status === 'blocked' ? (
                                <UnBlockBtn fetchInfluencer={fetchInfluencer} item={item} />
                              ) : (
                                <Button
                                  className='btn btn-danger btn-sm'
                                  size='sm'
                                  style={{
                                    height: '45px',
                                  }}
                                  onClick={() => handleInfluencerAction(item)}
                                >
                                  Block
                                </Button>
                              )}

                              <Button
                                className='btn btn-light btn-sm'
                                style={{
                                  height: '45px',
                                }}
                                size='sm'
                                onClick={() => handleInfluencerReview(item)}
                              >
                                Reviews
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div className='col-sm-12'></div>
              </div>
            </div>
            <div className='card-footer p-4 mb-2 bg-secondary'>
              <div className='d-flex flex-stack flex-wrap'>
                <div className='fs-6 fw-bold text-gray-700'>
                  Showing {currentPage} to 10 of {total} entries
                </div>

                <div className='pagination'>
                  <Pagination
                    hideFirstLastPages={false}
                    hideNavigation={true}
                    linkClassFirst='next'
                    linkClassPrev='previous'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default Influencer
