/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import cookie from 'js-cookie'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [userDetails, setUserDetails] = useState<any>({})

  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        const user = JSON.parse(localStorage.getItem('user') || '{}')
        setUserDetails(user)
      }
    } catch (error) {
      console.log('🚀 ~ file: HeaderUserMenu.tsx:20 ~ useEffect ~ error', error)
    }
  }, [currentUser?.id])

  const setLogout = () => {
    logout()
    cookie.remove('token')
    cookie.remove('user')
    cookie.remove('userAuth')
    localStorage.removeItem('user')
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {userDetails?.profile_photo ? (<img src={userDetails?.profile_photo} alt={userDetails?.f_name} />) : (<img src="/assets/media/avatars/blank.png" alt={userDetails?.f_name} />)}
            {/* <img alt='Logo' src={userDetails?.profile_photo} /> */}
          </div>

          <div className='d-flex flex-column'>
            <p className='fw-bold text-muted m-0'>
              {userDetails?.f_name} {userDetails?.l_name}
            </p>
            <p className='fw-bold text-muted fs-7 m-0'>{userDetails?.email}</p>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={'/my-profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={setLogout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
