
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getDatabase, ref, set, get, onValue, child, push, update } from "firebase/database";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDyS3N941sERezJs_yVPDkFAfZ-tUHhwpQ",
    authDomain: "test-project-elite.firebaseapp.com",
    databaseURL: "https://test-project-elite-default-rtdb.firebaseio.com",
    projectId: "test-project-elite",
    storageBucket: "test-project-elite.appspot.com",
    messagingSenderId: "293074277744",
    appId: "1:293074277744:web:d98e195a0c994e09c12bb5",
    measurementId: "G-0ZXNQQ6B58"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const FirebaseDB = getFirestore(app)

export { FirebaseDB, getDatabase, ref, set, get, onValue, child, push, update };
