/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {parseCookies} from 'nookies'

import {baseUrl, APIURL} from '../../../../config/config'
import axios from '../../../../_metronic/hooks/axiosPrivate';

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | any
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const cookies = parseCookies()
let cuser = cookies?.user
let luser
if (cuser) {
  luser = JSON.parse(cuser)
} else {
  luser = undefined
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: luser,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

  const saveAuth = async (auth: AuthModel | undefined) => {
    const cookies = parseCookies()
    let cuser = cookies?.user
    // const token = cookies?.token
    // var myHeaders = new Headers()
    // myHeaders.append('Authorization', `Bearer ${token}`)
    // var requestOptions: any = {
    //   method: 'GET',
    //   headers: myHeaders,
    //   redirect: 'follow',
    // }

    axios.get('me')
    .then((res) => {
      console.log('RESULT', res);
      if (res.status === 200) {
        const resUser = res?.data?.data;
        console.log('RESULT', resUser);
        setCurrentUser(resUser)
        localStorage.setItem('user', JSON.stringify(resUser))
      }
    })
    .catch((err) => {
      console.log('RESULT', err);
      let luser
      if (cuser) {
        luser = JSON.parse(cuser)
      } else {
        luser = undefined
      }
      setCurrentUser(luser)
    });

    // const response = await fetch(`${APIURL}/me`, requestOptions)
    // const result = await response.json()
    // if (result.success == true) {
      // setCurrentUser(result?.data?.user)
      // localStorage.setItem('user', JSON.stringify(result?.data?.user))
    // } else {
    //   let luser
    //   if (cuser) {
    //     luser = JSON.parse(cuser)
    //   } else {
    //     luser = undefined
    //   }
    //   setCurrentUser(luser)
    // }

    setAuth(auth)
    // setCurrentUser(luser)
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  }

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
