/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import * as React from 'react'
import {APIURL} from '../../../config/config'
import {parseCookies} from 'nookies'
import Select from 'react-select'

const AddBrands = ({getBrands, brands: brandsForUpdate}: any) => {
  const cookies = parseCookies()
  const token = cookies?.token

  const [brands, setBrands] = React.useState<any>([])
  const [selectedBrands, setSelectedBrands] = React.useState<any>([])
  const [isBranChange, setIsBrandChange] = React.useState<boolean>(false)

  //   get brands
  React.useEffect(() => {
    const getSlots = async () => {
      try {
        const {data, status} = await axios.get(`${APIURL}/merchant/brands`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (status) {
          const allBrands = data?.data.map((item: any) => {
            return {value: item?.id, label: item?.brand_name}
          })

          if (brandsForUpdate?.length) {
            const updateSelectedBrands = allBrands?.filter((item: any) => {
              return brandsForUpdate?.find((brand: any) => brand?.brand_id === item?.value)
            })

            setSelectedBrands(updateSelectedBrands)
          }

          setBrands(allBrands)
        }
      } catch (error) {
        console.log(error)
      }
    }

    getSlots()
  }, [brandsForUpdate?.length, token])

  const handleBrandChange = (e: any) => {
    setSelectedBrands(e)
    setIsBrandChange((pre: any) => !pre)
  }

  React.useEffect(() => {
    const updateSelectedBrands = selectedBrands?.map((item: any) => {
      return {brand_id: item?.value}
    })

    getBrands(updateSelectedBrands)
  }, [isBranChange])

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
    }),

    menuList: (base: any, state: any) => ({
      ...base,
      padding: 0,
      backgroundColor: 'white',
      color: 'black',
    }),
  }

  return (
    <>
      <div className='col-sm-12 col-md-12 mb-10'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>Brand</span>
          <span className=''>{`(Example : Dice is Nice, White Luv)`}</span>
        </label>
        <Select
          styles={customStyles}
          isMulti
          name='brands'
          options={brands}
          className='basic-multi-select'
          classNamePrefix='select'
          value={selectedBrands}
          onChange={handleBrandChange}
          isDisabled={brands?.length === 0}
        />
      </div>
    </>
  )
}

export default AddBrands
