const getErrorMessage = (error: any): string => {
  let message: string

  if (error instanceof Error) {
    message = error.message
  } else if (error && typeof error === 'object' && 'message' in error) {
    message = error?.message as string
  } else if (typeof error === 'string') {
    message = error
  } else {
    message = 'Something Went Wrong'
  }

  return message
}

export default getErrorMessage
