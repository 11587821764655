import * as React from 'react'
import Select from 'react-select'
import axios from 'axios'
import { APIURL } from '../../../../config/config'
import Cookies from 'js-cookie'
import isEqual from 'react-fast-compare'

type LocationType = {
  value: string
  label: string
}

interface ILocation {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
}

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: '#1e1e2d',
    border: '1px solid #323248',
    minHeight: '44px',
    '&:hover': {
      border: '1px solid #323248',
    }
  }),
  menuList: (base: any, state: any) => ({
    ...base,
    padding: 0,
    backgroundColor: 'white',
    color: '#92929f',
  }),
  singleValue: (base: any, state: any) => ({
    ...base,
    color: '#92929f',
    fontSize: '1.1rem',
    fontWeight: 500,
  }),
  input: (base: any, state: any) => ({
    ...base,
    color: '#fff',
  })
}

const Location = ({ campaignState, setCampaignState }: ILocation) => {
  const [locations, setLocations] = React.useState<LocationType[]>([])

  const handleLocationChange = (location: LocationType) => {
    setCampaignState({ ...campaignState, location_id: location.value })
  }

  React.useEffect(() => {
    const getLocations = async () => {
      try {
        const { data } = await axios.get(`${APIURL}/me`, {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        });

        if (data?.data?.user_type === "4") {          
          console.log(data?.data?.rep_business_tier?.tier_amount, 'DATA');

          setCampaignState({
            ...campaignState,
            price: data?.data?.rep_business_tier?.tier_amount,
            defaultPrice: data?.data?.rep_business_tier?.tier_amount,
          })

          const locations = {
            value: data?.data?.rep_branch?.id,
            label: data?.data?.rep_branch?.name,
          };
          const apiResLocations: LocationType[] = [locations];
          setLocations(apiResLocations);
        } else if (data?.data?.user_type === "3") {
          const { data } = await axios.get(`${APIURL}/admin/business-location`, {
            headers: {
              Authorization: `Bearer ${Cookies.get('token')}`,
            },
          });

          if (data?.success) {
            const apiResLocations: LocationType[] = data?.data?.map((item: any) => ({
              value: item?.id,
              label: item?.name,
            }))
  
            setCampaignState({
              ...campaignState,
              price: parseInt(data?.campaign_default_price),
              defaultPrice: parseInt(data?.campaign_default_price),
            });

            setLocations(apiResLocations);
          }
        }
      } catch (error) {
        console.log('🚀 ~ file: Location.tsx:56 ~ getLocations ~ error:', error)
      }
    }

    getLocations()
  }, []);

  console.log('Locations', locations);

  return (
    <div className='col-sm-12 col-md-6'>
      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
        <span className='required'>Campaign Location</span>
      </label>

      <Select
        styles={customStyles}
        options={locations}
        name='location'
        value={locations?.find((item: LocationType) => item?.value === campaignState?.location_id)}
        placeholder='Select Location'
        onChange={(e) => handleLocationChange(e as LocationType)}
      // isDisabled={locations?.length === 0}
      />
    </div>
  )
}

export default React.memo(Location, isEqual)
