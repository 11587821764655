import React, {memo} from 'react'
import {FaPlus} from 'react-icons/fa'
import classes from './ReviewLinks.module.scss'
import {toast} from 'react-toastify'
import isEqual from 'react-fast-compare'

interface IIGHandleBusiness {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
}

const IGHandleBusiness = ({campaignState, setCampaignState}: IIGHandleBusiness) => {
  const [businessHandleInput, setBusinessHandleInput] = React.useState<string>('')

  const addHandle = () => {
    if (!businessHandleInput) return toast.error('Please enter a handle')

    if (businessHandleInput) {
      setCampaignState((pre) => ({
        ...pre,
        businessHandle: [
          ...campaignState.businessHandle.map((handle) => handle),
          `instagram.com/${businessHandleInput.replace(/@/g, "")}`,
        ],
      }))
      setBusinessHandleInput('')
    }
  }

  const removeHandle = (handle: string) => {
    const newBusinessHandle = campaignState?.businessHandle.filter((h) => h !== handle)

    setCampaignState({
      ...campaignState,
      businessHandle: newBusinessHandle,
    })
  }

  return (
    <>
      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-1 mt-4'>
        <span className='required'>Instagram Business Handle</span>
      </label>

      <div className='col-sm-12 col-md-6 d-flex align-items-center gap-4'>
        <input
          type='text'
          className='form-control'
          placeholder='@ Write Your Instagram Business Handle'
          value={businessHandleInput}
          onChange={(e) => setBusinessHandleInput(e.target.value)}
        />

        <button type='button' className='btn btn-primary btn-medium ms-4' onClick={addHandle}>
          <FaPlus />
        </button>
      </div>

      <div className={classes.linksWrapper}>
        {campaignState?.businessHandle?.map((handle, index) => (
          <div className={classes.link} key={index}>
            <span>{handle}</span>
            <span className={classes.remove} onClick={() => removeHandle(handle)}>
              x
            </span>
          </div>
        ))}
      </div>
    </>
  )
}

export default memo(IGHandleBusiness, isEqual)
