import Select from 'react-select'
import clsx from 'clsx'

import classes from './input.module.scss'

import { useField } from 'formik'
import { useEffect, useState } from 'react'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string
  value: string
  valueLabel: string
  containerClass?: string
  labelClass?: string
  inputClass?: string
  label?: string
  type?: string
  error?: boolean
  disabled?: boolean
  options: any
  errorText?: string | any
  errorColor?: string
  required?: boolean
  defaultValue?: any
  onDataChange: (name: string, value: any) => any
}

const SingleSelect: React.FC<InputProps> = (props) => {
  const {
    id = '',
    name = '',
    containerClass = '',
    labelClass = '',
    inputClass = '',
    placeholder = '',
    label = '',
    value = '',
    defaultValue = '',
    valueLabel = '',
    type = 'text',
    error = false,
    errorText = '',
    onDataChange,
    errorColor = 'text-red-600',
    required = false,
    disabled = false,
    options = [],

    ...rest
  } = props

  const inputClasses = clsx(
    `bg-transparent ${errorText && error ? 'is-invalid' : ''}  ${inputClass} text-black`
  )

  const [load, setLoad] = useState(true)
  const [field, state, { setValue, setTouched }] = useField(name);
  const [defaultData, setDefaultData] = useState(null);

  const onChange = (inputValue: any) => {
    setValue(inputValue[value])
    const item = { value: inputValue[valueLabel], id: inputValue?.id }
    onDataChange(name, item)
  }

  const foundItem = options?.find((item: { id: any }) => item?.id === field?.value)
  const foundItem_default = options?.find((item: { id: any }) => item?.id === defaultValue?.id);

  useEffect(() => {
    setLoad(true);
    // const foundItem_default = options?.find((item: { id: any }) => item?.id === defaultValue?.id);
    setDefaultData(defaultValue);

    // console.log('foundItem_default', foundItem_default);
    console.log('defaultData', id);
    console.log('defaultValue', defaultValue);
    setLoad(false);
  }, [defaultValue]);

  // console.log('options', options);
  // console.log('foundItem', foundItem);
  // console.log('defaultData', id);
  // console.log('defaultData', defaultData);


  return (
    <div className={`${classes.container_input} ${containerClass}`}>
      {label ? (
        <label htmlFor={id} className={`form-label fw-bolder text-dark fs-6 ${labelClass}`}>
          {label} {required && <span className='text-red'>*</span>}
        </label>
      ) : (
        ''
      )}
      {
        load ? null :
          <Select
            options={options}
            isDisabled={disabled}
            value={foundItem == undefined ? defaultData : foundItem }
            className={inputClasses}
            getOptionLabel={(option) => `${option[valueLabel]}`}
            getOptionValue={(option) => `${option[value]}`}
            id={id}
            placeholder={placeholder}
            onChange={onChange}
            /* @ts-ignore */
            onBlur={setTouched}
          />
      }

      {errorText && error ? <p className={`${classes.error}  ${errorColor}`}>{errorText}</p> : ''}
    </div>
  )
}

export default SingleSelect
