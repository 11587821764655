import {useState, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {Rating} from 'react-simple-star-rating'
import Form from 'react-bootstrap/Form'
import {APIURL} from '../../../config/config'
import {parseCookies} from 'nookies'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import {toast} from 'react-toastify'
import useCharacterLimit from '../../../_metronic/hooks/useCharacterLimit'

interface RProps {
  show: boolean
  onHide: () => void
  influencer_id?: string
  campaign_id?: string
  bookingListInfo?: any
}

const ReviewDialog = (props: RProps) => {
  const {bookingListInfo} = props
  const [review, setReview] = useState<string>('')
  const [rating, setRating] = useState<number>(0)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const maxWords = 500
  const {value, onChange, message} = useCharacterLimit('', maxWords)

  useEffect(() => {
    setReview(value)
  }, [value])

  const cookies = parseCookies()
  const token = cookies?.token

  // useEffect(() => {
  //   if (!props.show) {
  //     setReview('')
  //     setRating(0)
  //   }
  // }, [props.show])

  const handleRating = (rate: number) => {
    setRating(rate)
    setError('')
  }

  const handleSave = async () => {
    if (rating === 0) {
      setError('Please select atleast one star rating')
      return
    }

    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    const body = JSON.stringify({
      influencer_id: bookingListInfo?.influencer_id,
      campaign_id: bookingListInfo?.campaign_id,
      rating: rating,
      review: review,
    })

    const requestOptions: any = {
      method: 'POST',
      body,
      headers: myHeaders,
    }

    const response = await fetch(`${APIURL}/merchant/review`, requestOptions)
    const result = await response.json()

    if (result?.success === true) {
      setLoading(false)
      setReview('')
      setRating(0)
      props.onHide()
      toast.success('Review added successfully')
    }
  }

  return (
    <Modal {...props} size='sm' aria-labelledby='rating-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='rating-modal'>Add Review</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex mb-3 justify-content-center align-items-center flex-column'>
          <Rating
            onClick={handleRating}
            transition
            size={40}
            initialValue={rating}
            fillColor='orange'
            emptyColor='gray'
          />
        </div>

        <Form>
          <Form.Group className='mb-5' controlId='rating.description'>
            <Form.Label>Description</Form.Label>
            <Form.Control value={review} onChange={onChange} as='textarea' rows={4} />
          </Form.Group>
        </Form>

        <div
          style={{
            marginTop: '-10px',
          }}
          className='d-flex justify-content-between'
        >
          <p className='text-danger'>{message ? message : ''}</p>
          <p
            className='text-muted'
            style={{
              textAlign: 'right',
            }}
          >
            {value?.length}/{maxWords}
          </p>
        </div>

        <div className='d-flex justify-content-center'>
          <Button variant='success' size='sm' onClick={handleSave} disabled={loading}>
            {loading ? <Spinner animation='border' size='sm' /> : 'Save'}
          </Button>
        </div>

        {error && (
          <Alert className='mt-4' variant='danger'>
            {error}
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ReviewDialog
