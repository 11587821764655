/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {APIURL} from '../../../config/config'
import {parseCookies} from 'nookies'
import 'react-toastify/dist/ReactToastify.css'
import Table from 'react-bootstrap/Table'
import Pagination from 'replace-js-pagination'
import InfluencerSkeleton from '../campaign/Influencer/InfluencerSkeleton'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ActiveInactive from './ActiveInactive'
const AccountManager: FC = () => {
  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = useState(true)
  const [managerList, setManagerList] = useState([])
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerpage] = useState(0)
  const [lastPage, setLastPage] = useState(0)

  const fetchManager = async (page: number) => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/staffs?page=${page}`, requestOptions)
    const result = await response.json()
    if (result.success == true) {
      setManagerList(result?.data)
      setTotal(result?.paginate?.total)
      setCurrentPage(result?.paginate?.current_page)
      setPerpage(result?.paginate?.per_page)
      setLastPage(result?.paginate?.last_page)
    }

    setLoading(false)
    // console.log('result', result);
  }

  useEffect(() => {
    fetchManager(1)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [activePage, setActivePage] = useState(1)

  const handlePageChange = (page: number) => {
    setActivePage(page)
    setLoading(true)
    fetchManager(page)
    setLoading(false)
  }

  // console.log('brand', brand)
  return (
    <>
      <PageTitle>Account Manager List</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 bg-success py-5'>
              <h3 className='card-title fw-bolder text-white'>Manager List</h3>
            </div>
            <div className='card-body p-2'>
              <div className='row mb-4'>
                <div className='col-sm-12'>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className='fs-4 text-center'>Profile Photo</th>
                        <th className='fs-4 text-center'>Name</th>
                        <th className='fs-4 text-center'>Email</th>
                        <th className='fs-4 text-center'>Phone</th>
                        <th className='fs-4 text-center'>Brands</th>
                        <th className='fs-4 text-center'>Status</th>
                        <th className='fs-4 text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <>
                          {new Array(10).fill(0).map((_, index) => (
                            <InfluencerSkeleton column={5} height={40} key={index} />
                          ))}
                        </>
                      )}

                      {managerList.length === 0 && !loading && (
                        <tr>
                          <td colSpan={7} className='text-center'>
                            No data found
                          </td>
                        </tr>
                      )}

                      {managerList.map((item: any, index: number) => (
                        <tr key={`${item.id}.${index}`} className='odd align-middle'>
                          <td className='text-center'>
                            <picture className='symbol symbol-45px me-5'>
                              <img
                                src={item?.profile_photo}
                                alt={item?.f_name}
                                style={{width: '80px', height: '80px', objectFit: 'cover'}}
                              />
                            </picture>
                          </td>
                          <td className='text-center'>{`${item?.f_name} ${item?.l_name}`}</td>
                          <td className='text-center'>{item?.email}</td>
                          <td className='text-center'>{item?.phone}</td>
                          <td
                            className='text-center'
                            style={{
                              minHeight: '100px',
                            }}
                          >
                            <span
                              className='text-center badge badge-light mr-2'
                              style={{
                                fontSize: '1rem',
                              }}
                            >
                              {item?.brands
                                .map((brand: any) => {
                                  return brand?.brand_name
                                })
                                .join(', ')}
                            </span>
                          </td>

                          <td
                            className='text-center'
                            style={{
                              minHeight: '100px',
                            }}
                          >
                            <ActiveInactive id={item.id} status={item.status} token={token} />
                          </td>

                          <td
                            className='text-center'
                            style={{
                              minHeight: '100px',
                            }}
                          >
                            <Link to={`/account-manager/add-new-manager?staff_id=${item?.id}`}>
                              <Button
                                variant='outline-primary'
                                className='btn-sm btn btn-success me-2'
                              >
                                Edit
                              </Button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className='col-sm-12'></div>
              </div>
            </div>
            <div className='card-footer p-4 mb-2 bg-secondary'>
              <div className='d-flex flex-stack flex-wrap'>
                <div className='fs-6 fw-bold text-gray-700'>
                  Showing {currentPage} to 10 of {total} entries
                </div>
                {/*begin::Pages*/}
                <div className='pagination'>
                  <Pagination
                    hideFirstLastPages={false}
                    hideNavigation={true}
                    linkClassFirst='next'
                    linkClassPrev='previous'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
                {/*end::Pages*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default AccountManager
