
import clsx from 'clsx'
import { Field, FieldProps } from 'formik'
import classes from '../Input/input.module.scss'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface PhoneInputProps {
  field: FieldProps['field']
  form: FieldProps['form']
  country?: any
}

const PhoneInputField: React.FC<PhoneInputProps> = ({ field, form, country, ...props }) => {
  return (
    <div className='input-field'>
      <PhoneInput
        country={country?.id}
        {...field}
        inputClass={classes.phone}
        placeholder='Enter phone number'
        value={field.value}
        onChange={(value) => {
          if (!form.touched[field.name]) form.setFieldTouched(field.name)
          form.setFieldValue(field.name, value)
        }}
        onBlur={field.onBlur}
      />
    </div>
  )
}



interface IPhoneInput extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string
  containerClass?: string
  labelClass?: string
  inputClass?: string
  label?: string
  type?: string
  error?: boolean
  errorText?: string
  errorColor?: string
  required?: boolean
  country?: any
}

const Input: React.FC<IPhoneInput> = (props) => {
  const {
    id = '',
    containerClass = '',
    labelClass = '',
    inputClass = '',
    placeholder = '',
    label = '',
    type = 'number',
    error = false,
    errorText = '',
    errorColor = 'text-red-600',
    required = false,
    ...rest
  } = props

  const inputClasses = clsx(
    `form-control bg-transparent ${errorText && error ? 'is-invalid' : ''}  ${inputClass}`
  )
  return (
    <div className={`${classes.container_input} ${containerClass}`}>
      {label ? (
        <label htmlFor={id} className={`form-label fw-bolder text-dark fs-6 ${labelClass}`}>
          {label} {required && <span className='text-red'>*</span>}
        </label>
      ) : (
        ''
      )}

      <Field
        component={PhoneInputField}
        type={type}
        className={inputClasses}
        id={id}
        placeholder={placeholder}
        {...rest}
      />

      {errorText && error ? <p className={`${classes.error}  ${errorColor}`}>{errorText}</p> : ''}
    </div>
  )
}

export default Input
