/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { parseCookies } from 'nookies'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { baseUrl, APIURL } from '../../../config/config'
import { FaLocationDot } from 'react-icons/fa6'
import ChatBox from '../../../../src/app/components/Chats/ChatBox'
import AdminChatBox from '../../../../src/app/components/Chats/AdminChatBox'
import { NULL } from 'sass'

const InvoiceView: FC = () => {

    const params = useParams()
    const { id } = params
    const cookies = parseCookies()
    const token = cookies?.token;
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState<any>([]);

    const getInvoice = async () => {
        setLoading(true);
        var myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${token}`)
        myHeaders.append('Content-Type', 'application/json')

        var requestOptions: any = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        try {
            const getInvoice = await fetch(`${APIURL}/merchant/invoice?campaign_id=${id}`, requestOptions);
            const result = await getInvoice.json()

            console.log('Invoice', result);

            if (result.success == true) {
                setInvoices(result?.data);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }



    useEffect(() => {
        getInvoice();
    }, [])

    return (
        <>
            <PageTitle>Invoice View</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {
                    loading ? null : <>
                        <div className='col-xl-12'>
                            <div className='card card-xl-stretch'>
                                <div className='card-header border-0 py-4 flex align-items-center justify-content-between'>
                                    <h3 className='card-title fw-bolder text-white'>Price</h3>
                                </div>
                                <div className='card-body py-4'>
                                    <div className="w-100 row mb-4 p-4">
                                        <div className='col-sm-3 col-md-1'>Before</div>
                                        <div className='col-sm-9 col-md-11'><h4>${invoices?.tier_amount}</h4></div>
                                    </div>
                                    <div className="w-100 row mb-4 p-4">
                                        <div className='col-sm-3 col-md-1'>Special Offer</div>
                                        <div className='col-sm-9 col-md-11'><h4>${invoices?.billing_amount}</h4></div>
                                    </div>
                                    <div className="w-100 row mb-4 p-4">
                                        <div className='col-sm-12 col-md-12'>
                                            <img src={`${baseUrl}/assets/img/invoice.png`} alt="" />
                                        </div>
                                    </div>
                                    <div className="w-100 row mb-4 p-4"></div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default InvoiceView;