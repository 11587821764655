/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { parseCookies } from 'nookies'

import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'

import UpdateInformation from '../../app/modules/auth/components/UpdateInformation'
import { Singup } from '../../app/modules/auth/components/Singup'
import { RegistrationRepresentative } from '../pages/representative/RegistrationRepresentative'

import { ForgotPassword } from '../../app/modules/auth/components/ForgotPassword'
import { Login } from '../../app/modules/auth/components/Login'
import { AuthLayout } from '../../app/modules/auth/AuthLayout'

import { App } from '../App'
import PrivacyPolicy from '../pages/PrivacyPolicy'

import PendingStatus from '../modules/auth/components/PendingStatus'
import SuccessStatus from '../modules/auth/components/SuccessStatus'
import RejectStatus from '../modules/auth/components/RejectStatus'
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const cookies = parseCookies()
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [token, setToken] = useState('')
  const [isToken, setISToken] = useState(false)

  useEffect(() => {
    if (!cookies?.userAuth) {
    } else {
      let AuthData = cookies?.userAuth
      let userAuth: any = JSON.parse(AuthData)
      saveAuth(userAuth)
    }

    if (!cookies?.user) {
    } else {
      let cuser: any = cookies?.user
      let user: any = JSON.parse(cuser)

      console.log(user, 'user')
      setCurrentUser(user)
    }

    if (!cookies?.token) {
      setToken('')
      setISToken(false)
    } else {

      setToken(cookies?.token)
      setISToken(true)
    }
  }, [cookies?.token, cookies?.user, cookies?.userAuth]);
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isToken ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              {/* <Route path='login/*' element={<AuthPage />} /> */}

              <Route path='*' element={<Navigate to='/login' />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />

              <Route element={<AuthLayout />}>
                <Route path='/login' element={<Login />} />
                <Route index element={<Login />} />
                <Route path='/registration' element={<Singup />} />
                <Route
                  path='/registration-representative'
                  element={<RegistrationRepresentative />}
                />
                <Route path='/profile-update' element={<UpdateInformation />} />

                <Route path='/pending' element={<PendingStatus />} />
                <Route path='/success' element={<SuccessStatus />} />
                <Route path='/reject' element={<RejectStatus />} />

                <Route path='/forgot-password' element={<ForgotPassword />} />
              </Route>
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
