import {useState, ChangeEvent} from 'react'

const useCharacterLimit = (
  initialValue: string = '',
  maxCharacters: number
): {
  value: string
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  message: string
  setMessage: (message: string) => void
  remainingCharacters: number
  name: string
} => {
  const [inputValue, setInputValue] = useState<string>(initialValue)
  const [message, setMessage] = useState<string>('')
  const [name, setName] = useState<string>('')

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const {name, value} = event.target

    setName(name)

    if (value.length > maxCharacters) {
      setMessage(`Maximum ${maxCharacters} characters allowed`)
      return
    } else {
      setMessage('')
    }
    if (value.length <= maxCharacters) {
      setInputValue(value)
    }
  }

  const remainingCharacters: number = maxCharacters - inputValue.length

  return {value: inputValue, onChange: handleChange, message, setMessage, name, remainingCharacters}
}

export default useCharacterLimit
