import AsyncSelect from 'react-select/async'
import clsx from 'clsx'

import classes from './input.module.scss'
import { PlaceData } from '../../../_metronic/assets/ts/components/_auth'
import { useField } from 'formik'
import axios from '../../../_metronic/hooks/axiosPrivate'
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string
  containerClass?: string
  labelClass?: string
  inputClass?: string
  label?: string
  type?: string
  error?: boolean
  errorText?: string | any
  errorDuplicate?: string | any
  errorColor?: string
  required?: boolean
  country?: any
  index?: number
  setFieldValue?: any
  value?: any
}

const Select: React.FC<InputProps> = (props) => {
  const {
    id = '',
    name = '',
    containerClass = '',
    labelClass = '',
    inputClass = '',
    placeholder = '',
    label = '',
    type = 'text',
    error = false,
    errorText = '',
    errorDuplicate = '',
    errorColor = 'text-red-600',
    required = false,
    country,
    index,
    setFieldValue,
    ...rest
  } = props

  const inputClasses = clsx(
    `bg-transparent ${errorText && error ? 'is-invalid' : ''}  ${inputClass}`
  );

  const [field, state, { setValue, setTouched }] = useField(name);

  const onChangeSelect = async (inputValue: string) => {
    const res = await axios.get(`/admin/map/get-addresses?country=${country.id}&address=${inputValue}`);
    const arry: PlaceData[] = res.data.data.predictions
    return arry
  }

  const onChange = async (new_value: any) => {
    setValue(new_value.description)
    const getPlace = await axios.get(`/admin/map/get-place-details?place_id=${new_value.place_id}`);
    const place = await getPlace.data.data.results[0].geometry.location;
    setFieldValue(`representatives.${index}.latitude`, place.lat);
    setFieldValue(`representatives.${index}.longitude`, place.lng);
  }

  // console.log('errorT', error);
  console.log('errorDuplicate', errorDuplicate);
  
  return (
    <div className={`${classes.container_input} ${containerClass}`}>
      {label ? (
        <label htmlFor={id} className={`form-label fw-bolder fs-6 ${labelClass}`}>
          {label} {required && <span className='text-red'>*</span>}
        </label>
      ) : (
        ''
      )}

      <AsyncSelect
        loadOptions={onChangeSelect}
        className={inputClasses}
        getOptionLabel={(option) => `${option.description}`}
        getOptionValue={(option) => option.description}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        /* @ts-ignore */
        onBlur={setTouched}
      />

      {errorText && error ? <p className={`${classes.error}  ${errorColor}`}>{errorText}</p> : ''}
      {errorDuplicate && error ? <p className={`${classes.error}  ${errorColor}`}>{errorDuplicate}</p> : ''}
    </div>
  )
}

export default Select
