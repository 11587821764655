import * as React from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {parseCookies} from 'nookies'
import {APIURL} from '../../../../config/config'
import useSWR from 'swr'
import {Link, useParams} from 'react-router-dom'
import Pagination from 'replace-js-pagination'
import {Button, Table, Spinner} from 'react-bootstrap'
import InfluencerSkeleton from '../Influencer/InfluencerSkeleton'
import useDebounce from './useDebounce'

import {FaDownload} from 'react-icons/fa'
import moment from 'moment'
import * as XLSX from 'xlsx'
import {toast} from 'react-toastify'

const Invitation = () => {
  const params = useParams()
  const {id: campaign_id} = params
  const cookies = parseCookies();
  const token = cookies?.token;
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [skillId, setSkillId] = React.useState('');

  const [total, setTotal] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [perPage, setPerpage] = React.useState<number>(0);
  const [lastPage, setLastPage] = React.useState<number>(0);
  const [activePage, setActivePage] = React.useState<number>(1);
  const [nextPage, setNextPage] = React.useState<boolean>(false);
  const [prevPage, setPrevPage] = React.useState<boolean>(false);

  const [report, setReport] = React.useState<any[]>([]);

  const debouncedNameTerm: string = useDebounce(name, 500);
  const debouncedEmailTerm: string = useDebounce(email, 500);

  const fetcher = async (url: string) => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    const requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    console.log("ITEM", url);
    const response = await fetch(url, requestOptions)
    console.log("ITEM", response);
    
    const result = await response.json()
    if (result.success === true) {
      let newData: any[] = []

      result?.data?.forEach((item: any) => {
        console.log('ITEM', item);
        newData.push({
          profile_image: item?.photo_url,
          name: item?.full_name,
          email: item?.email,
          phone: item?.phone,
          followers: item?.social_stats
            ?.map((social: any) => {
              return `${social.name}: ${social.followers}`
            })
            .join(', '),
          skills: item?.skills
            ?.map((skill: any) => {
              return skill.skill_name
            })
            .join(', '),
        })
      });

      setReport(newData)

      setActivePage(result?.paginate?.current_page)
      setTotal(result?.paginate?.total)
      setCurrentPage(result?.paginate?.current_page)
      setPerpage(result?.paginate?.per_page)
      setLastPage(result?.paginate?.last_page)

      if (result?.paginate?.next_url === null) {
        setNextPage(false)
      } else {
        setNextPage(true)
      }

      if (result?.paginate?.prev_url === null) {
        setPrevPage(false)
      } else {
        setPrevPage(true)
      }

      return result?.data
    }
  }

  const {
    data: influencerList,
    error,
    isLoading,
    mutate,
  } = useSWR(
    `${APIURL}/merchant/influencer-list?campaign_id=${campaign_id}&page=${currentPage}&name=${debouncedNameTerm}&email=${debouncedEmailTerm}&skill_id=${skillId}`,
    fetcher
  );

  const generateExcelFile = () => {
    // make keys as headers for the excel sheet
    const headers = Object.keys(report[0])
    // make this headers as a capitalised format
    const titles = headers?.map((title) => title?.split('_').join(' ').toUpperCase())
    // make values as values for the excel sheet and take arrays on this sheet
    const values = report.map((item: any) => Object.values(item))
    // make a new array with headers and values
    const data = [titles, ...values]
    // make a new workbook
    const wb = XLSX.utils.book_new()
    // make a new sheet name
    const ws = XLSX.utils.aoa_to_sheet(data)
    // add the sheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')

    const date = moment().format('DD-MM-YYYY')
    const time = moment().format('HH:mm:ss')
    const dateTime = date + '_' + time
    const fileName = 'Influencer_list_' + dateTime + '.xlsx'

    // export the workbook
    XLSX.writeFile(wb, fileName)
  }

  const fetchSkills = async (url: string) => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(url, requestOptions)
    const result = await response.json()
    if (result.success === true) {
      return result?.data
    }
  }

  const {data: skillList} = useSWR(`${APIURL}/merchant/skills`, fetchSkills)

  const handlePageChange = (page: number) => {
    if (nextPage) {
      setCurrentPage((pre) => pre + 1)
    }

    if (prevPage) {
      setCurrentPage((pre) => pre - 1)
    }
  }

  return (
    <>
      <PageTitle>Invite Influencer</PageTitle>

      <div
        className='row g-5 g-xl-10 mb-xl-10'
        style={{
          marginTop: '-60px',
        }}
      >
        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='card-header align-items-center border-0 bg-success py-5'>
              <div className='card-toolbar'>
                <form className='d-flex align-items-center' onSubmit={(e) => e.preventDefault()}>
                  <div className='input-group input-group-sm input-group-solid ps-3'>
                    <input
                      type='text'
                      className='form-control ps-4 h-40px'
                      placeholder='Name'
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      type='text'
                      className='form-control ps-4 h-40px'
                      placeholder='Email'
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <select
                      className='form-select form-select-sm form-select-solid fw-bolder ps-2 pe-4 h-40px'
                      aria-label='...'
                      onChange={(e) => setSkillId(e.target.value)}
                    >
                      <option value=''>All Skill</option>
                      {skillList?.map((item: any, index: any) => (
                        <option key={`${item.id}.${index}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>

              {influencerList?.length !== 0 && (
                <FaDownload
                  style={{
                    cursor: 'pointer',
                    fontSize: '24px',
                  }}
                  onClick={() => generateExcelFile()}
                />
              )}
            </div>
            <div className='card-body p-2'>
              <div className='row mb-4'>
                <div className='col-sm-12'>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className='fs-4 text-center'>Profile Photo</th>
                        <th className='fs-4 text-center'>Name</th>
                        <th className='fs-4 text-center'>Email</th>
                        <th className='fs-4 text-center'>Phone</th>
                        <th className='fs-4 text-center'>Followers</th>
                        <th className='fs-4 text-center'>Skills</th>
                        <th className='fs-4 text-center'>Invite</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && (
                        <>
                          {new Array(10).fill(0).map((_, index) => (
                            <InfluencerSkeleton column={5} key={index} />
                          ))}
                        </>
                      )}

                      {!isLoading && influencerList?.length === 0 && (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            No Influencer Found
                          </td>
                        </tr>
                      )}

                      {error && (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            {error?.message || 'Unable to load Influencer'}
                          </td>
                        </tr>
                      )}

                      {!isLoading &&
                        influencerList &&
                        influencerList.map((item: any, index: number) => (
                          <tr key={`${item.influencer_id}.${index}`} className='align-middle'>
                            <td className='text-center'>
                              <picture className='symbol symbol-45px me-5'>
                                <img
                                  src={item?.photo_url}
                                  alt={item?.full_name}
                                  style={{width: '80px', height: '80px', objectFit: 'cover'}}
                                />
                              </picture>
                            </td>
                            <td className='text-center'><Link to={`/influencer/${item?.influencer_id}?cid=${campaign_id}`}>{item?.full_name}</Link></td>
                            <td className='text-center'>{item?.email}</td>
                            <td className='text-center'>{item?.phone}</td>
                            <td className='text-center'>
                              {item?.social_stats?.map((social: any, index: any) => (
                                <span
                                  key={`${social.social_id}.${index}`}
                                  className='mb-1 d-block'
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {social.name}: {social.followers}
                                  {index !== item?.social_stats?.length - 1 && ', '}
                                </span>
                              ))}
                            </td>
                            <td className='text-center'>
                              {item?.skills?.map((skill: any, index: any) => (
                                <span
                                  key={`${skill.skill_id}.${index}`}
                                  className='badge badge-info mb-1 me-1'
                                >
                                  {skill.skill_name}
                                </span>
                              ))}
                            </td>
                            <td className='text-center'>
                              <InviteBtn
                                campaign_id={campaign_id}
                                influencer_id={item.influencer_id}
                                invitation_status={item?.invitation_status}
                                mutate={mutate}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div className='col-sm-12'></div>
              </div>
            </div>
            <div className='card-footer p-4 mb-2 bg-secondary'>
              <div className='d-flex flex-stack flex-wrap'>
                <div className='fs-6 fw-bold text-gray-700'>
                  Showing {currentPage} to {perPage} of {total} entries
                </div>

                <div className='pagination'>
                  <Pagination
                    hideFirstLastPages={false}
                    hideNavigation={true}
                    linkClassFirst='next'
                    linkClassPrev='previous'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Invitation

const InviteBtn = ({
  campaign_id,
  influencer_id,
  invitation_status,
  mutate,
}: {
  campaign_id: any
  influencer_id: any
  invitation_status?: number | string
  mutate?: any
}) => {
  console.log('🚀 ~ file: index.tsx:345 ~ invitation_status:', invitation_status)
  const [loading, setLoading] = React.useState<boolean>(false)

  const cookies = parseCookies()
  const token = cookies?.token

  const addInvite = async (id: number) => {
    setLoading(true)
    const res = await fetch(`${APIURL}/merchant/request-for-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        influencer_id: id,
        campaign_id: campaign_id,
      }),
    })
    if (res?.status === 200) {
      toast.success('Invitation sent successfully')
      mutate()
      setLoading(false)
    } else {
      toast.error('You have already requested to the influencer')
      mutate()
      setLoading(false)
    }
  }

  return (
    <Button
      className='btn btn-light  btn-sm'
      size='sm'
      onClick={() => addInvite(influencer_id)}
      disabled={invitation_status === 1 || loading}
    >
      {loading ? (
        <Spinner animation='border' size='sm' />
      ) : invitation_status === 1 ? (
        'Invited'
      ) : (
        'Invite'
      )}
    </Button>
  )
}
