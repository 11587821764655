/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, FormEvent, Fragment, useEffect, useState, ChangeEvent, SetStateAction } from 'react'

import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

import { FaPlus } from 'react-icons/fa'
import { CgSoftwareUpload } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import { parseCookies } from 'nookies'
import { baseUrl, APIURL } from '../../../config/config'
import { FieldArray, Form, Formik } from 'formik'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import AsyncSelect from '../../components/Input/AsyncSelect';
import Input from '../../components/Input/Input'
import Select from '../../components/Input/Select'
import axios from '../../../_metronic/hooks/axiosPrivate';
import { useQuery } from 'react-query';

import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import CountrySelect from 'react-bootstrap-country-select';
import PhoneInput from '../../components/PhoneInput';
import { BusinessData } from '@/_metronic/assets/ts/components/_auth';

interface IRepresentative {
  id: string;
  r_first_name: string;
  r_last_name: string;
  r_email: string;
  business_location: string;
  isRepresentative: boolean;
}

const initialValues = {
  f_name: '',
  l_name: '',
  business_name: '',
  profile_photo: '',
  phone: '',
  email: '',
  about: '',
  address: '',
  business_type: null,
  business_category_id: null,
  business_sub_category_id: null,
  no_of_employee: null,
  annual_revenue: null,
  price: 0,
  representatives: [
    {
      id: '',
      r_first_name: '',
      r_last_name: '',
      r_email: '',
      business_location: '',
      isRepresentative: false,
      latitude: "",
      longitude: "",
    },
  ],
};

const prices = [
  { id: 1, value: '$' },
  { id: 2, value: '$$' },
  { id: 3, value: '$$$' },
  { id: 4, value: '$$$$' },
];


const MyProfile: FC = () => {
  const navigate = useNavigate();
  const cookies = parseCookies();
  const [businessSubCategory, setSubCategory] = useState([]);
  const token = cookies?.token
  const { currentUser, logout } = useAuth()
  const [loading, setLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(false)
  const [imgsLoading, setImgsLoading] = useState(false)
  const [logoImg, setLogoImg] = useState('')
  const [profilePhoto, setProfilePhoto] = useState('')

  
  const [viewData, setViewData] = useState({
    business_type: null,
    business_category_id: null,
    business_sub_category_id: null,
    no_of_employee: null,
    annual_revenue: null,
    price: '',
  });


  const [country, setCountry] = useState({
    id: "us",
    name: "United States",
    flag: "🇺🇸",
    alpha2: "us",
    alpha3: "usa",
    ioc: "usa"
  });

  const getSubCategory = async (business_category_id: any) => {
    try {
      const response = await axios.get(
        `admin/get-sub-categories?category_id=${business_category_id}`
      )
      setSubCategory(response?.data?.data)
      if (!response?.data?.data?.length) {
        // setFieldValue('business_sub_category_id', null)
      }
    } catch (error) {
      console.error('Error fetching sub-categories:', error)
    }
  }

  const onViewData = (name: string, item: any) => {
    if (name === 'business_category_id') {
      getSubCategory(item.id)
    }

    setViewData((prev) => ({
      ...prev, // Spread the previous state
      [name]: item.value, // Update the specific property using computed property name
    }))
  };

  const { data: businessTypes } = useQuery(
    'business-types',
    () => axios.get('/admin/get-business-types').then((res) => res?.data?.data),
    {
      cacheTime: 5 * 60 * 1000, // Cache data for 5 minutes
      staleTime: 1 * 60 * 1000, // Data is considered stale after 1 minute
      refetchOnWindowFocus: true,
    }
  );

  const { data: businessCategory } = useQuery(
    'categories',
    () => axios.get('/admin/categories').then((res) => res?.data?.data),
    {
      cacheTime: 5 * 60 * 1000, // Cache data for 5 minutes
      staleTime: 1 * 60 * 1000, // Data is considered stale after 1 minute
      refetchOnWindowFocus: true,
    }
  );

  const { data: employees } = useQuery(
    'employees',
    () => axios.get('/admin/get-no-employees').then((res) => res?.data?.data),
    {
      enabled: true,
    }
  );

  const { data: businessRrevenues } = useQuery(
    'business-revenues',
    () => axios.get('/admin/get-business-revenues').then((res) => res?.data?.data),
    {
      enabled: true,
    }
  );

  const [user, setUser] = useState({
    f_name: '',
    l_name: '',
    business_name: '',
    profile_photo: '',
    email: '',
    phone: '',
    about: '',
    address: '',
    business_type: null,
    business_category_id: null,
    business_sub_category_id: null,
    no_of_employee: null,
    annual_revenue: null,

    business_type_old: null,
    business_category_id_old: null,
    business_sub_category_id_old: null,
    no_of_employee_old: null,
    annual_revenue_old: null,
    price_old: null,
    price: 0,
    representatives: [
      {
        id: '',
        r_first_name: '',
        r_last_name: '',
        r_email: '',
        business_location: '',
        isRepresentative: false,
        latitude: "",
        longitude: "",
      },
    ],
    step: 1,
  });

  const getUser = async () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    axios.get('me')
      .then((res) => {
        console.log('RESULT', res);
        if (res.status === 200) {
          const resUser = res?.data?.data;
          console.log('resUser', resUser);

          const representatives = resUser?.representatives?.map((rep: any, ind: number) => ({
            id: rep?.id,
            r_first_name: rep?.f_name,
            r_last_name: rep?.l_name,
            r_email: rep?.email,
            business_location: rep?.branche_name,
            isRepresentative: true,
            latitude: "",
            longitude: "",
          }));
          
          setProfilePhoto(resUser?.profile_photo);
          setUser({
            ...user,
            f_name: resUser?.f_name,
            l_name: resUser?.l_name,
            email: resUser?.email,
            phone: resUser?.phone,
            about: resUser?.about,
            address: resUser?.address,
            profile_photo: "",

            business_name: resUser?.business_name,

            business_type: resUser?.business_type?.id,
            business_category_id: resUser?.business_category?.id,
            business_sub_category_id: resUser?.business_subcategory?.id,
            no_of_employee: resUser?.number_of_employee?.id,
            annual_revenue: resUser?.revenue?.id,
            price: parseInt(resUser?.price),

            business_type_old: resUser?.business_type,
            business_category_id_old: resUser?.business_category,
            business_sub_category_id_old: resUser?.business_subcategory,
            no_of_employee_old: resUser?.no_of_employee,
            annual_revenue_old: resUser?.revenue,
            price_old: resUser?.price,
            representatives: representatives || resUser?.representatives,
          });
          setLogoImg(resUser?.profile_photo);
        }
      })
      .catch((err) => {
        console.log('RESULT', err);
      });
  }

  useEffect(() => {
    getUser()
  }, [])

  const updateUser = (e: { target: { name: any; value: any } }) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  function getBase64(name: string, file: any, file_name: string) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      let fileType = file.type
      if (fileType.match(/image/g) == 'image') {
        let databs64 = `data:${fileType};base64,`

        //@ts-ignore
        let imageFile = reader.result.replace(String(databs64), '')

        //@ts-ignore
        setLogoImg(reader.result)

        setUser({
          ...user,
          [name]: imageFile,
        })
        setImgLoading(false)
      } else {
        toast.error('Please select image file')
        setImgLoading(false)
      }
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const uploadFile = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    setImgLoading(true)
    getBase64(e.target.name, e.target.files[0], e.target.files[0].name)
  }

  async function handleProfileUpdate(e: FormEvent) {
    e.preventDefault()
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({ ...user })
    var requestOptions: any = {
      method: 'PUT',
      body: raw,
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/profile-update`, requestOptions)
    const result = await response.json()
    console.log('ERROR', result);
    if (result.success === true) {
      toast.success(result?.message)
      // navigate('/my-profile')
    } else if (result.success === false) {
      toast.error(result?.message);
    }
    setLoading(false)
  }

  function removeFields(jsonData: any) {
    let modifiedData: any = { ...jsonData }

    //@ts-ignore
    modifiedData.representatives = modifiedData.representatives.map((rep) => {
      const { isRepresentative, ...rest } = rep
      return rest
    })

    //@ts-ignore
    delete modifiedData.step
    delete modifiedData.business_type_old
    delete modifiedData.business_category_id_old
    delete modifiedData.business_sub_category_id_old
    delete modifiedData.no_of_employee_old
    delete modifiedData.annual_revenue_old
    delete modifiedData.price_old

    return modifiedData
  }

  const submitHandler = async (
    _values: typeof initialValues,
    {
      setStatus,
      setSubmitting,
    }: { setStatus: (status?: any) => void; setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    console.log(_values);

    try {
      setLoading(true)
      //@ts-ignore
      let fromData = removeFields(_values)
      console.log('form-data', fromData);
      const result = await axios.put(`/profile-update`, fromData)
      if (result.status === 200 && result.data.success === true) {
        toast.success(result.data.message)
      } else if (result.status === 415) {
        setSubmitting(false)
        setLoading(false)
        const fieldsToCheck = ['email', 'f_name', 'l_name', 'password', 'phone']
        fieldsToCheck.forEach((field) => {
          if (result?.data?.message?.[field]?.length > 0) {
            setStatus(result.data.message[field][0])
          }
        })
      }

    } catch (err: any) {
      let message =
        typeof err.response !== 'undefined'
          ? (err.response.data.message as string)
          : (err.message as string)
      setLoading(false)
      toast.error(message)
    } finally{
      setLoading(false);
    }
  }

  return (
    <>
      <PageTitle>Update My Profile</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Profile Update</h3>
            </div>
          </div>

          <div>
            <div className='card-body border-top p-9'>
              <Formik
                initialValues={user}
                onSubmit={(values, { resetForm, setStatus, setSubmitting }) =>
                  submitHandler(values, { setStatus, setSubmitting })
                }
                enableReinitialize
              >
                {({ errors, values, setFieldValue, isValidating, isSubmitting, status, handleBlur, touched }) => (
                  <Form>

                    <div className='row mb-6'>
                      <div className='col-sm-4 mx-auto text-center'>
                        {imgLoading ? (
                          <>
                            <div
                              className='d-flex justify-content-center img-fluid form-control'
                              style={{ width: '150px', height: '100px' }}
                            >
                              <div className='spinner-border' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <Fragment>
                            <label htmlFor="profile_photo" className="d-block col-form-label fw-bold fs-6">Upload Profile Picture</label>
                            <label htmlFor="profile_photo" className="preview-wrapper w-175px h-175px">
                              <input
                                type='file'
                                id="profile_photo"
                                className="profile-input-hidden"
                                name='profile_photo'
                                onChange={(e) => uploadFile(e)}
                              />
                              <img
                                src={logoImg || profilePhoto ||currentUser?.profile_photo || "/assets/media/avatars/blank.png"}
                                alt={currentUser?.f_name}
                                className="w-100 h-100 rounded-circle"
                              />
                              <span>
                                <CgSoftwareUpload size={24} className="upload-icon" />
                              </span>
                            </label>
                          </Fragment>
                        )}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <Input
                        id='f_name'
                        name='f_name'
                        label='First Name'
                        type='text'
                        errorText={errors.f_name}
                        error={touched.f_name}
                        onBlur={handleBlur}
                        inputClass=''
                        placeholder='Your First Name'
                        containerClass='col-md-6 col-sm-12'
                        value={values?.f_name}
                      />
                      {/* <div className="col-md-6 col-sm-12">
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>First Name</label>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='First Name'
                          name='f_name'
                          value={values.f_name}
                          onChange={updateUser}
                        />
                      </div> */}
                      <Input
                        id='l_name'
                        name='l_name'
                        label='Last Name'
                        type='text'
                        errorText={errors.l_name}
                        error={touched.l_name}
                        onBlur={handleBlur}
                        inputClass=''
                        placeholder='Your Last Name'
                        containerClass='col-md-6 col-sm-12'
                        value={values?.l_name}
                      />
                      {/* <div className="col-md-6 col-sm-12">
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Last Name</label>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Last Name'
                          name='f_name'
                          value={user.l_name}
                          onChange={updateUser}
                        />
                      </div> */}
                    </div>
                    <div className="row mb-6">

                      {/* <PhoneInput
                        id='phone'
                        name='phone'
                        label='Phone Number'
                        type='tel'
                        errorText={errors.phone}
                        error={touched.phone}
                        onBlur={handleBlur}
                        inputClass=''
                        placeholder='Enter Phone Number'
                        containerClass='col-md-6 col-sm-12'
                        country={country}
                      /> */}

                      <Input
                        id='phone'
                        name='phone'
                        label='Phone number'
                        type='tel'
                        errorText={errors.phone}
                        error={touched.phone}
                        onBlur={handleBlur}
                        inputClass=''
                        placeholder='Enter Phone Number'
                        containerClass='col-md-6 col-sm-12'
                        value={values?.phone}
                      />
                      {/* <div className="col-md-6 col-sm-12">
                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                          Phone number
                        </label>
                        <input
                          type='tel'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Phone number'
                          name='phone'
                          value={user.phone}
                          onChange={updateUser}
                        />
                      </div> */}
                      {/* <div className="col-md-6 col-sm-12">
                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                          Your Email
                        </label>
                        <input
                          type="email"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Email"
                          name="email"
                          value={values.email}
                          onChange={updateUser}
                        />
                      </div> */}
                      <Input
                        id='email'
                        name='email'
                        label='Your Email'
                        type='email'
                        errorText={errors.email}
                        error={touched.email}
                        onBlur={handleBlur}
                        inputClass=''
                        placeholder='Enter First Name'
                        containerClass='col-md-6 col-sm-12'
                        value={values?.email}
                      />
                    </div>
                    <div className="row mb-6">
                      <div className="col-md-6 col-sm-12">
                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                          Your Address
                        </label>
                        <textarea
                          name='address'
                          defaultValue={user.address}
                          value={user.address}
                          onChange={updateUser}
                          className='form-control'
                          rows={3}
                        />
                      </div>
                      {/* <Input
                        id='l_name'
                        name='l_name'
                        label='Last Name'
                        type='textarea'
                        errorText={errors.phone}
                        error={touched.phone}
                        onBlur={handleBlur}
                        inputClass=''
                        placeholder='Your Last Name'
                        containerClass='col-md-6 col-sm-12'
                        value={values?.l_name}
                      /> */}
                      <div className="col-md-6 col-sm-12">
                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                          About
                        </label>
                        <textarea
                          name='about'
                          defaultValue={user.about}
                          value={user.about}
                          onChange={updateUser}
                          className='form-control'
                          rows={3}
                          cols={40}
                        />
                      </div>
                    </div>
                    <div className="row mb-6">
                      {/* <div className="col-sm-12">
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name of Business</label>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Business Name'
                          name='business_name'
                          value={user.f_name}
                          onChange={updateUser}
                        />
                      </div> */}

                      {/* Country Select */}
                      <div className='mb-6 col-md-6 col-sm-12'>
                        <label htmlFor="country" className={`form-label fw-bolder text-dark fs-6`}>
                          Country
                        </label>
                        <CountrySelect
                          value={country}
                          //@ts-ignore
                          onChange={setCountry}
                          onTextChange={function (text: string, changeEvent: ChangeEvent<Element>): void {
                            // throw new Error('Function not implemented.')
                          }} />
                      </div>
                      {/* Country Select */}

                      <Input
                        id='business_name'
                        name='business_name'
                        label='Your Business Name'
                        type='text'
                        errorText={errors.business_name}
                        error={touched.business_name}
                        onBlur={handleBlur}
                        inputClass=''
                        placeholder='Enter Business Name'
                        containerClass='col-md-6 col-sm-12'
                        value={values?.business_name}
                      />
                    </div>

                    <FieldArray name="representatives">
                      {({ insert, remove, push }) => (
                        <>
                          {values?.representatives?.map((value: IRepresentative, index: number) => (
                            <div className="row mb-6" key={index}>
                              <div className="col-sm-12">
                                {index === 0 ? ("") : (
                                  <div className="row">
                                    <div className="col-md-6 mb-6">
                                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Business Location {index + 1}</label>
                                    </div>
                                    <div className="col-md-6 text-end">
                                      <AiOutlineCloseCircle onClick={() => remove(index)} className="text-danger fs-1 cursor-pointer" />
                                    </div>
                                  </div>
                                )}
                                <AsyncSelect
                                  name={`representatives.${index}.business_location`}
                                  id={`representatives.${index}.id`}
                                  // @ts-ignore
                                  errorText={errors.representatives?.[index]?.business_location}
                                  error={touched?.representatives?.[index]?.business_location}
                                  label="Business Location"
                                  inputClass="text-block"
                                  placeholder="Enter Business Location"
                                  containerClass="mb-6 col-md-12"
                                  country={country}
                                  value={values?.representatives?.[index]?.business_location}
                                  index={index}
                                  setFieldValue={setFieldValue}
                                />
                                {value?.isRepresentative ? (
                                  <div className="row">
                                    <div className="col-sm-12 my-5">
                                      <h2>Representative Details</h2>
                                    </div>
                                    <Input
                                      name={`representatives.${index}.r_first_name`}
                                      id={`representatives.${index}.r_first_name`}
                                      /* @ts-ignore */
                                      errorText={errors?.representatives?.[index]?.r_first_name}
                                      error={touched?.representatives?.[index]?.r_first_name}
                                      label='First Name'
                                      type='text'
                                      onBlur={handleBlur}
                                      inputClass=''
                                      placeholder='Enter First Name'
                                      containerClass='mb-6 col-md-6'
                                    />
                                    <Input
                                      name={`representatives.${index}.r_last_name`}
                                      id={`representatives.${index}.r_last_name`}
                                      /* @ts-ignore */
                                      errorText={errors?.representatives?.[index]?.r_last_name}
                                      error={touched?.representatives?.[index]?.r_last_name}
                                      label='Last Name'
                                      type='text'
                                      onBlur={handleBlur}
                                      inputClass=''
                                      placeholder='Enter Last Name'
                                      containerClass='mb-6 col-md-6'
                                    />
                                    <Input
                                      name={`representatives.${index}.r_email`}
                                      id={`representatives.${index}.r_email`}
                                      /* @ts-ignore */
                                      errorText={errors?.representatives?.[index]?.r_email}
                                      error={touched?.representatives?.[index]?.r_email}
                                      label='Email'
                                      type='email'
                                      onBlur={handleBlur}
                                      inputClass=''
                                      placeholder='Enter Your Email'
                                      containerClass='mb-6 col-md-12'
                                    />
                                  </div>
                                ) : (
                                  <div className="row">
                                    <div className="col-sm-12 text-end">
                                      <button
                                        type='button'
                                        onClick={(e) =>
                                          setFieldValue(`representatives.${index}.isRepresentative`, true)
                                        }
                                        className='btn btn-sm text-primary pe-0 mb-1'
                                      >
                                        <FaPlus /> Add Representative for above location
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                          <div className="row justify-content-start">
                            <div className="col-md-6">
                              <button
                                type="button"
                                className="btn btn-sm text-primary mb-1"
                                onClick={() => push({
                                  r_first_name: "",
                                  r_last_name: "",
                                  r_email: "",
                                  business_location: "",
                                })}
                              >
                                <FaPlus /> Add More Location
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </FieldArray>


                    <div className="row justify-content-start">
                      <div className="col-md-12">
                        <Select
                          options={businessTypes}
                          id='business_type'
                          name='business_type'
                          label='Business Type'
                          valueLabel='value'
                          onDataChange={onViewData}
                          value='id'
                          defaultValue={user?.business_type_old}
                          type='text'
                          errorText={errors.business_type}
                          error={touched.business_type}
                          onBlur={handleBlur}
                          inputClass='text-black'
                          placeholder='Select Type'
                          containerClass='mb-6 col-md-12'
                        />
                        <Select
                          value='id'
                          defaultValue={user?.business_category_id_old}
                          onDataChange={onViewData}
                          options={businessCategory}
                          id='business_category_id'
                          name='business_category_id'
                          label='Business Category'
                          valueLabel='name'
                          type='text'
                          errorText={errors.business_category_id}
                          error={touched.business_category_id}
                          onBlur={handleBlur}
                          inputClass='text-black'
                          placeholder='Select Type'
                          containerClass='mb-6 col-md-12'
                        />
                        <Select
                          value='id'
                          defaultValue={user?.business_sub_category_id_old}
                          onDataChange={onViewData}
                          disabled={!businessSubCategory?.length && !values?.business_category_id}
                          options={businessSubCategory}
                          id='business_sub_category_id'
                          name='business_sub_category_id'
                          label='Sub Category'
                          valueLabel='name'
                          type='text'
                          errorText={errors.business_sub_category_id}
                          error={touched.business_sub_category_id}
                          onBlur={handleBlur}
                          inputClass='text-black'
                          placeholder='Select Sub Category'
                          containerClass='mb-6 col-md-12'
                        />

                        <Select
                          options={prices}
                          id='price'
                          name='price'
                          label='Price'
                          valueLabel='value'
                          onDataChange={onViewData}
                          value='id'
                          defaultValue={user?.price_old}
                          errorText={errors.price}
                          error={touched.price}
                          onBlur={handleBlur}
                          inputClass='text-black'
                          placeholder='$$$$'
                          containerClass='mb-6 col-md-12'
                        />
                        <Select
                          options={employees}
                          id='no_of_employee'
                          name='no_of_employee'
                          label='No. Of Employees'
                          valueLabel='value'
                          onDataChange={onViewData}
                          value='id'
                          defaultValue={user?.no_of_employee_old}
                          type='text'
                          errorText={errors.no_of_employee}
                          error={touched.no_of_employee}
                          onBlur={handleBlur}
                          inputClass='text-black'
                          placeholder='Select'
                          containerClass='mb-6 col-md-12'
                        />
                        <Select
                          options={businessRrevenues}
                          id='annual_revenue'
                          name='annual_revenue'
                          label='Annual Business Revenue'
                          valueLabel='value'
                          onDataChange={onViewData}
                          value='id'
                          defaultValue={user?.annual_revenue_old}
                          type='text'
                          errorText={errors.annual_revenue}
                          error={touched.annual_revenue}
                          onBlur={handleBlur}
                          inputClass='text-black'
                          placeholder='Revenue'
                          containerClass='mb-6 col-md-12'
                        />

                      </div>
                      <div className='col-md-12 mt-5'>
                        <button
                          type='submit'
                          id='kt_sign_up_submit'
                          className='btn btn-lg btn-primary mb-5'
                          disabled={
                            isSubmitting
                          }
                        >
                          {!loading && <span className='indicator-label'>Update</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>

                  </Form>
                )}
              </Formik>
            </div>

            {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {loading ? (
                <button className='btn btn-lg btn-primary mb-5' disabled>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </button>
              ) : (
                <>
                  <button className='btn btn-lg btn-primary mb-5'>Save Changes</button>
                </>
              )}
            </div> */}
          </div>
        </div>
      </div >
      {/* end::Row */}
    </>
  )
}

export default MyProfile
