import React, { useEffect, useState } from "react";
import axios from '../../../_metronic/hooks/axiosPrivate';
import Back from '../../../app/components/common/BackButton';
import { LuMapPin } from 'react-icons/lu';
import { AiOutlineCalendar } from 'react-icons/ai';
import { InProcessDataType, bookedDataType } from "./type";
import { useParams } from "react-router-dom";
import moment from "moment";
import { isNotEmpty } from "../../../_metronic/helpers";


const CampaignDetail = () => {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<InProcessDataType>({} as InProcessDataType);
  const [timeSlot, setTimeSlot] = useState<string[]>();

  useEffect(() => {
    setLoading(true);
    // merchant/campaign-booking-time?id=15&booking_date=2023-10-18&booking_time=14:22-16:22
    // axios.get("/merchant/booking-by-campaign", { params: { cam_id: params?.id } })
    // axios.get("/merchant/campaign", { params: { id: params?.id } })
    const times = params?.booking_time?.split("-");
    setTimeSlot(times);

    console.log('PARAMS', params);
    axios.get("/merchant/campaign-booking-time", {
      params: {
        id: params?.id,
        booking_date: params?.booking_date,
        booking_time: params?.booking_time
      }
    })
    // axios.get("/merchant/campaign", { params: { id: params?.id } })
      .then((res) => {
        console.log('RES', res);
        if (res?.status === 200 && res?.data?.success) {
          const result = res?.data;
          setCampaign(result?.data);
        }
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
  }, [params]);

  return loading ?
    <div
      style={{ zIndex: '109', width: '100%', height: '100%' }}
      className='drawer-overlay d-flex justify-content-center text-center align-items-center'
    >
      <div
        className='d-flex justify-content-center text-center align-items-center'
        style={{ width: '100%', height: 'auto', overflow: 'hidden' }}
      >
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    </div>
    : !isNotEmpty(campaign) ? (
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 justify-content-center'>
        <div className='col-sm-6'>
          <div className='card'>
            <div className='card-body text-center'>
              <h2>No Data Found</h2>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <>
        <div className="d-flex mb-5 gap-3 align-items-center">
          <Back /> <h2 className="mb-0">Campaign - {campaign?.name}</h2>
        </div>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10 justify-content-center'>
          <div className='col-sm-6'>
            <div className='card'>
              <div className='card-body'>
                <img src={campaign?.thumb_image} className="card-img-top rounded" alt={campaign?.name} style={{ height: '280px', borderRadius: '30px' }} />
                <h3 className="fw-medium fs-4 mt-4">{campaign?.name}</h3>
                <div className="d-flex gap-4">
                  <div className="d-flex gap-2 mt-3 align-items-center">
                    <img src={campaign?.merchant_photo} width={20} height={20} alt="Campaign Icon" />
                    <span className="mb-0 fs-6">{campaign?.merchant_business_name}</span>
                  </div>
                  <div className="d-flex gap-2 mt-3 align-items-center">
                    <LuMapPin size={20} color="#C21616" />
                    <span className="mb-0">{campaign?.location?.location_name}</span>
                  </div>
                </div>
                <div className="mt-6">
                  <h3 className="fw-medium fs-3">About Campaign</h3>
                  <p className="">{campaign?.about}</p>
                </div>

                <div className="mt-6">
                  <span className="fs-5" style={{ color: "#9CA3AF" }}>Campaign Date & Time</span>
                  <p className="">
                    <AiOutlineCalendar size={20} color="148FF9" /> {" "}
                    {moment(params?.booking_date).format("DD MMMM, YYYY")},
                    {" "}
                    {timeSlot && moment(timeSlot[0], "HH:mm").format('hh:mm A')} - {timeSlot && moment(timeSlot[1], "HH:mm").format('hh:mm A')}
                  </p>
                </div>

                {Array.isArray(campaign?.booked)
                  && campaign?.booked?.length > 0
                  && campaign?.booked?.map((booked: bookedDataType) => (
                    <div className="mt-6 d-flex gap-3 align-items-center">
                      <img src={booked?.influencer?.photo_url} alt={booked?.influencer?.full_name} className="rounded-circle" style={{ width: '120px', height: '120px' }} />
                      <div>
                        <h4 className="fs-4">{booked?.influencer?.full_name}</h4>
                        <span>Promo</span>
                        <h4 className="fs-4 text-uppercase">{booked?.voucher || campaign?.voucher}</h4>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default CampaignDetail;