/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {parseCookies} from 'nookies'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser, setCurrentUser} = useAuth()
  const cookies = parseCookies()

  useEffect(() => {
    let cuser = cookies?.user
    let luser
    if (cuser) {
      luser = JSON.parse(cuser)
    } else {
      luser = undefined
    }
    setCurrentUser(luser)
  }, [cookies?.user])

  // console.log('currentUser', currentUser)

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/* {['1', '2', '3'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItem
          to='/brand'
          icon='/media/icons/duotune/general/gen003.svg'
          title='Brand'
          fontIcon='bi-layers'
        />
      )} */}

      <SidebarMenuItemWithSub
        to='/campaign'
        title='Campaign'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
        fontIcon='bi-layers'
      >
        {['1', '2', '3'].includes(currentUser?.user_type as string) && (
          <SidebarMenuItem to='/campaign/add-campaign' title='Add Campaign' hasBullet={true} />
        )}

        {/* check is it user_type 4 or not if 4 the render compaign */}
        {['1', '2', '3', '4'].includes(currentUser?.user_type as string) && (
          <SidebarMenuItem to='/campaign' title='Campaign List' hasBullet={true} />
        )}
      </SidebarMenuItemWithSub>

      {['1', '2', '3'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItemWithSub
          to='/influencer'
          icon='/media/icons/duotune/communication/com014.svg'
          title='Influencer'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/influencer' title='Influencer List' hasBullet={true} />

          <SidebarMenuItem
            to='/influencer/suspense-influencer'
            title='Suspended Influencer'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {/* {['1', '2', '3', '4'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItemWithSub
          to='/influencer'
          icon='/media/icons/duotune/communication/com014.svg'
          title='Influencer'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/influencer' title='Influencer List' hasBullet={true} />

          <SidebarMenuItem
            to='/influencer/suspense-influencer'
            title='Suspended Influencer'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )} */}

      {['1', '2', '3'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItemWithSub
          to='/account-manager'
          title='Account Manager'
          icon='/media/icons/duotune/general/gen051.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem
            to='/account-manager/add-new-manager'
            title='Add New Manager'
            hasBullet={true}
          />
          <SidebarMenuItem to='/account-manager' title='Manager List' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )}

      {['1', '2', '3'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItem
          to='/category'
          icon='/media/icons/duotune/general/gen059.svg'
          title='Category'
          fontIcon='bi-layers'
        />
      )}

      {['1', '2', '3', '4'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItem
          to='/tutorials'
          icon='/media/icons/duotune/coding/cod003.svg'
          title='Tutorials'
          fontIcon='bi-layers'
        />
      )}

      {['1', '2', '3'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItem
          to='/subscription-packages'
          icon='/media/icons/duotune/general/gen037.svg'
          title='Subscription'
          fontIcon='bi-layers'
        />
      )}

      {['1', '2', '3', '4'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItemWithSub
          to='/invoice'
          title='Invoice'
          icon='/media/icons/duotune/finance/fin010.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/invoice' title='Campaigns' hasBullet={true} />
          {/* <SidebarMenuItem to='/support/open-ticket' title='Open Ticket' hasBullet={true} /> */}
        </SidebarMenuItemWithSub>
      )}

      {['1', '2', '3', '4'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItemWithSub
          to='/inbox'
          title='Inbox'
          icon='/media/icons/duotune/communication/com002.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/inbox' title='Active Campaigns' hasBullet={true} />
          {/* <SidebarMenuItem to='/support/open-ticket' title='Open Ticket' hasBullet={true} /> */}
        </SidebarMenuItemWithSub>
      )}

      {/* {['1', '2', '3', '4'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItemWithSub
          to='/support'
          title='Support'
          icon='/media/icons/duotune/communication/com002.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/support/tickets' title='Tickets' hasBullet={true} />
          <SidebarMenuItem to='/support/open-ticket' title='Open Ticket' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )} */}

      {['1', '2', '3'].includes(currentUser?.user_type as string) && (
        <SidebarMenuItemWithSub
          to='/content'
          title='Content'
          icon='/media/icons/duotune/communication/com012.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/faqs' title='FAQs' hasBullet={true} />
          <SidebarMenuItem to='/about' title='About us' hasBullet={true} />
          <SidebarMenuItem to='/privacy-policy' title='Privacy Policy' hasBullet={true} />
          <SidebarMenuItem to='/mission-vision' title='Mission Vision' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )}
    </>
  )
}

export {SidebarMenuMain}
