import * as React from 'react'
import classes from './ReviewLinks.module.scss'
import { FaPlus } from 'react-icons/fa'
import { toast } from 'react-toastify'
import isEqual from 'react-fast-compare'

interface IReviewLinks {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
  reviewLinks: Link[]
  setReviewLinks: React.Dispatch<React.SetStateAction<Link[]>>
}

const links: Link[] = [
  {
    name: 'Google',
    link: 'https://www.google.com/',
    icon: '/media/icons/duotune/social/google.svg',
  },
  {
    name: 'Yelp',
    link: 'https://www.yelp.com/',
    icon: '/media/icons/duotune/social/yelp.svg',
  },
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/',
    icon: '/media/icons/duotune/social/Facebook.svg',
  },
  {
    name: 'Other',
    link: 'https://',
    icon: '/media/icons/duotune/social/others.svg',
  },
]

const ReviewLinks = ({
  campaignState,
  setCampaignState,
  reviewLinks,
  setReviewLinks,
}: IReviewLinks) => {
  const [expandList, setExpandList] = React.useState<boolean>(false)
  const [selectedLink, setSelectedLink] = React.useState<Link>(links[0])



  const isUrlValid = (url: string) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(url);
  }

  const isValidUrl = (url: string) => {
    const urlValid = isUrlValid(url);
    try {
      if (urlValid) {
        return Boolean(new URL(url));
      } else {
        return false;
      }
    }
    catch (e) {
      return false;
    }
  }

  const handleAddLink = () => {

    // Url validation
    const valid_url = isValidUrl(selectedLink.link);

    if (valid_url) {
      // if link already exists in review links array
      const isLinkExists = reviewLinks.find((link) => link.link === selectedLink.link)
      if (isLinkExists) return toast.error('Link already exists')

      // if static link and input link are same
      if (
        selectedLink.link === links.map(({ link }) => link).find((link) => link === selectedLink.link)
      )
        return toast.error('Please enter a valid link')

      if (selectedLink) {
        setReviewLinks([...reviewLinks, selectedLink])

        setCampaignState((pre) => ({
          ...pre,
          reviewLinks: [
            ...reviewLinks.map(({ link, name }) => ({
              reviewer_name: name,
              link: link,
            })),
            {
              reviewer_name: selectedLink.name.toLocaleLowerCase(),
              link: selectedLink.link,
            },
          ],
        }))
        setSelectedLink(links[0])
      }
    } else {
      return toast.error('Please enter a valid link')
    }
  }

  const handleExpandList = () => {
    setExpandList((pre: boolean) => !pre)
  }

  const handleRemove = (index: number) => {
    const newLinks = reviewLinks.filter((_, i) => i !== index)
    setReviewLinks(newLinks)

    setCampaignState((pre) => ({
      ...pre,
      reviewLinks: [
        ...newLinks.map(({ link, name }) => ({
          reviewer_name: name,
          link: link,
        })),
      ],
    }))
  }

  return (
    <>
      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-1 mt-4'>
        <span className='required'>Add Review Links</span>
      </label>
      <div className={`${classes.wrapper} col-sm-12 col-md-6 d-flex align-items-center gap-4`}>
        <div className={classes.selected} onClick={handleExpandList}>
          <img src={selectedLink?.icon} alt={selectedLink?.name} className={classes.selectedIcon} />
          <img src='/media/icons/duotune/social/arrow-right.svg' alt='arrow' />

          {expandList && (
            <ul className={classes.list}>
              {links.map((link, index) => (
                <li key={index} onClick={() => setSelectedLink(link)}>
                  <img src={link.icon} alt={link.name} />
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className={classes.inputWrapper}>
          <input
            type='text'
            placeholder={selectedLink?.link}
            value={selectedLink?.link}
            className='form-control'
            onChange={(e) => setSelectedLink({ ...selectedLink, link: e.target.value })}
          />
        </div>

        <button type='button' className='btn btn-primary btn-medium ms-4' onClick={handleAddLink}>
          <FaPlus />
        </button>
      </div>
      <div className={classes.linksWrapper}>
        {campaignState?.reviewLinks?.map(({ link }: review_link, index) => (
          <div className={classes.link} key={index}>
            <span>{link}</span>
            <span className={classes.remove} onClick={() => handleRemove(index)}>
              x
            </span>
          </div>
        ))}
      </div>
    </>
  )
}

export default React.memo(ReviewLinks, isEqual)
