import * as Yup from 'yup'
import parsePhoneNumber from 'libphonenumber-js'
import axios from '../../../_metronic/hooks/axios';

const phoneSchema = Yup.string().test('is-phone', 'Invalid phone number', function (value) {
  console.log('ERROR', value)
  if (!value) {
    return true
  }
  const phoneNumber = parsePhoneNumber('+' + value)
  if (phoneNumber && phoneNumber.isValid()) {
    return true
  }
  return false
});


Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a: any) => a) {
  return this.test('unique', message, function (list: any) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

Yup.addMethod(Yup.string, 'availability', function (message) {
  return this.test('email-availability', message, async function (email) {

    let data = JSON.stringify({
      email: email
    });
    
    const option = {
      method: 'post',
      url: '/check-email-availibility',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    //@ts-ignore
    const checkEmail = await axios(option);
    
    return checkEmail?.data?.data?.is_available_email == true ? false: true;
  });
});

export const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    //@ts-ignore
    .availability('Email is already taken')
    .required('Email is required'),

  password: Yup.string()
    .min(8, 'Minimum of 8 characters')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .matches(/^(?=.*[a-z])/, 'Include at least one lowercase (a-z)')
    .matches(/^(?=.*[A-Z])/, 'Include at least one Uppercase (A-Z)')
    .matches(/^(?=.*[0-9])/, 'Include at least one Number (0-9)')
    .matches(/^(?=.*[!@#$%^&*])/, 'Include at least one Symbol~!@#$%^&*()_-+={[}]|:;”’<,>.?/'),
  c_password: Yup.string()
    .min(8, 'Minimum of 8 characters')
    .required('Please Re-enter your password')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .matches(/^(?=.*[a-z])/, 'Include at least one lowercase (a-z)')
    .matches(/^(?=.*[A-Z])/, 'Include at least one Uppercase (A-Z)')
    .matches(/^(?=.*[0-9])/, 'Include at least one Number (0-9)')
    .matches(/^(?=.*[!@#$%^&*])/, 'Include at least one Symbol~!@#$%^&*()_-+={[}]|:;”’<,>.?/'),
  c_email: Yup.string()
    .email('Wrong email format')
    .required('Please Re-enter your email')
    .oneOf([Yup.ref('email')], 'email must match'),
})

export const registrationSchema2 = Yup.object().shape({
  f_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  l_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  profile_photo: Yup.string().required('Profile photo is required'),
  business_name: Yup.string().required('Business name is required'),
  phone: phoneSchema.max(13, 'Phone Number is invalid').required('Phone Number is not valid'),
  representatives: Yup.array().of(
    Yup.object().shape({
      // r_first_name: Yup.string()
      //   .min(3, 'Minimum 3 symbols')
      //   .max(50, 'Maximum 50 symbols')
      //   .required('First name is required'),
      // r_last_name: Yup.string()
      //   .min(3, 'Minimum 3 symbols')
      //   .max(50, 'Maximum 50 symbols')
      //   .required('Last name is required'),
      business_location: Yup.string().required('Business location is required'),
      r_email: Yup.string().email('Wrong email format'),
    }),
    //@ts-ignore
  ).unique('Business location is duplicate', (a: any) => a.business_location)
})

export const registrationSchema3 = Yup.object().shape({
  business_type: Yup.string().required('Business type location is required'),
  business_category_id: Yup.string().required('Business Category is required'),
  // business_sub_category_id: Yup.string().required('Business location is required'),
  no_of_employee: Yup.string().required('No of employee is required'),
  annual_revenue: Yup.string().required('Annual revenue is required'),
  price: Yup.string().required('Price is required'),
})

export const RegistrationRepresentativeSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Minimum of 8 characters')
    .max(50, 'Maximum 50 symbols')
    .matches(/^(?=.*[a-z])/, 'Include at least one lowercase (a-z)')
    .matches(/^(?=.*[A-Z])/, 'Include at least one Uppercase (A-Z)')
    .matches(/^(?=.*[0-9])/, 'Include at least one Number (0-9)')
    .matches(/^(?=.*[!@#\$%\^&\*])/, 'Include at least one Symbol~!@#$%^&*()_-+={[}]|:;”’<,>.?/'),
  c_password: Yup.string()
    .required('Please Re-enter your password')
    .min(8, 'Minimum of 8 characters')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .matches(/^(?=.*[a-z])/, 'Include at least one lowercase (a-z)')
    .matches(/^(?=.*[A-Z])/, 'Include at least one Uppercase (A-Z)')
    .matches(/^(?=.*[0-9])/, 'Include at least one Number (0-9)')
    .matches(/^(?=.*[!@#\$%\^&\*])/, 'Include at least one Symbol~!@#$%^&*()_-+={[}]|:;”’<,>.?/'),
})

export const RepresentativeRegisterSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum of 8 characters')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .matches(/^(?=.*[a-z])/, 'Include at least one lowercase (a-z)')
    .matches(/^(?=.*[A-Z])/, 'Include at least one Uppercase (A-Z)')
    .matches(/^(?=.*[0-9])/, 'Include at least one Number (0-9)')
    .matches(/^(?=.*[!@#\$%\^&\*])/, 'Include at least one Symbol~!@#$%^&*()_-+={[}]|:;”’<,>.?/'),
  c_password: Yup.string()
    .min(8, 'Minimum of 8 characters')
    .required('Please Re-enter your password')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .matches(/^(?=.*[a-z])/, 'Include at least one lowercase (a-z)')
    .matches(/^(?=.*[A-Z])/, 'Include at least one Uppercase (A-Z)')
    .matches(/^(?=.*[0-9])/, 'Include at least one Number (0-9)')
    .matches(/^(?=.*[!@#\$%\^&\*])/, 'Include at least one Symbol~!@#$%^&*()_-+={[}]|:;”’<,>.?/'),
})
