import axios from '../../_metronic/hooks/axiosPrivate'
import {toast} from 'react-toastify'
import getErrorMessage from '../../_metronic/lib/ErrorHandler'

export const makeFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export const uploadImage = async (file: string, type: string, directory: string) => {
  try {
    const dataFile = file?.toString().split(',')[1]

    const {data} = await axios.post('/admin/upload-file', {
      file: dataFile,
      type,
      directory_name: directory,
    })

    if (data?.success) {
      return data?.data?.file_url
    } else {
      return {
        message: toast.error(data?.message),
      }
    }
  } catch (error) {
    return {
      message: toast.error(getErrorMessage(error)),
    }
  }
}
