import {toast} from 'react-toastify'
import {uploadImage} from '../../../utils/makeFileToBase64'
import * as React from 'react'
import getErrorMessage from '../../../../_metronic/lib/ErrorHandler'
import axios from '../../../../_metronic/hooks/axiosPrivate'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {defaultCampaignState} from '.'
import {useNavigate} from 'react-router-dom'
import isEqual from 'react-fast-compare'

const MySwal = withReactContent(Swal)

interface ICreateButton {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
  setReviewLinks: React.Dispatch<React.SetStateAction<Link[]>>
}

const CreateButton = ({campaignState, setCampaignState, setReviewLinks}: ICreateButton) => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<boolean>(false)

  const handleCampaignCreate = async () => {
    if (!campaignState?.location_id) return toast.error('Please select campaign location')
    if (!campaignState?.title) return toast.error('Please enter campaign name')
    if (!campaignState?.startDate) return toast.error('Please select campaign start date')
    if (!campaignState?.endDate) return toast.error('Please select campaign end date')
    if (campaignState?.time_slot?.length === 0)
      return toast.error('Please enter campaign time slots')
    if (!campaignState?.about) return toast.error('Please enter campaign about')
    if (!campaignState?.voucher) return toast.error('Please enter campaign voucher')
    if (campaignState?.reviewLinks?.length === 0)
      return toast.error('Please enter campaign review links')
    if (campaignState?.businessHandle?.length === 0)
      return toast.error('Please enter campaign business handle')
    if (!campaignState?.coverPhoto) return toast.error('Please select campaign cover photo')
    if (!campaignState?.price) return toast.error('Please enter campaign price')

    MySwal.fire({
      title: 'Are you sure?',
      showDenyButton: true,
      confirmButtonText: 'Create',
      denyButtonText: `Don't Create`,
      icon: 'info',
    }).then(async (result) => {
      try {
        if (result.isConfirmed) {
          setLoading(true)
          let images = await Promise.all(
            campaignState.gallery?.map(
              async (image) => await uploadImage(image, 'image', 'campaign')
            )
          )

          images = images?.map((image) => ({
            link: image,
            type: 'image',
          }));

          const campaignData = {
            filters: campaignState.filter.map(({id, type_id}) => ({
              item_id: id,
              type_id: type_id,
            })),
            thumb_image: await uploadImage(campaignState.coverPhoto, 'image', 'campaign'),
            images,
            name: campaignState.title,
            location_id: campaignState.location_id,
            start_date: campaignState.startDate,
            end_date: campaignState.endDate,
            about: campaignState.about,
            voucher: campaignState.voucher,
            time_slot: campaignState.time_slot,
            review_links: campaignState.reviewLinks,
            insta_handle: campaignState.businessHandle,
          }
          const {data} = await axios.post('/merchant/campaign', campaignData)
          Swal.showLoading()

          if (data?.success) {
            setLoading(false)
            setCampaignState(defaultCampaignState)
            setReviewLinks([])
            Swal.fire('Created', 'Campaign Successfully Created', 'success')

            setTimeout(() => {
              Swal.close()
            }, 1500)

            setTimeout(() => {
              navigate('/campaign')
            }, 2000)
          }
        } else if (result.isDenied) {
          Swal.fire('Cancelled', 'Campaign Creation Cancelled', 'error')
        }
      } catch (error) {
        setLoading(false)
        return {
          message: toast.error(getErrorMessage(error)),
        }
      }
    })
  }

  return (
    <div className='col-md-12 col-sm-12 mt-4'>
      <div className='col-sm-12 col-md-6'>
        <button
          type='button'
          className='btn btn-primary w-100'
          onClick={handleCampaignCreate}
          disabled={loading}
        >
          {loading ? (
            <div className='d-flex align-items-center justify-content-center gap-2'>
              Campaign Creating...
              <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
            </div>
          ) : (
            'Create'
          )}
        </button>
      </div>
    </div>
  )
}

export default React.memo(CreateButton, isEqual)
