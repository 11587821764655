import {Modal} from 'react-bootstrap'
import ReactPlayer from 'react-player'

interface PreviewDialogProps {
  show: boolean
  onHide: () => void
  doc_url?: string
  type?: string
  merchant_name?: string
  description?: string
}

const PreviewDialog = ({
  doc_url,
  type,
  merchant_name,
  description,
  ...props
}: PreviewDialogProps) => {
  return (
    <Modal {...props} size='lg' aria-labelledby='preview-tutorial-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='preview-tutorial-modal'>Tutorial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type === 'video' ? (
          <ReactPlayer url={doc_url} playing={true} controls={true} width='100%' height='300px' />
        ) : type === 'image' ? (
          <img
            src={doc_url}
            alt={merchant_name}
            style={{width: '100%', height: '300px', objectFit: 'contain'}}
          />
        ) : (
          <object
            width='100%'
            height='400'
            data={doc_url}
            type='application/pdf'
            style={{border: '1px solid #000000', borderRadius: '5px'}}
            aria-label='pdf'
          ></object>
        )}

        <div className='mt-3'>
          <p
            style={{
              fontSize: '1rem',
              fontWeight: '500',
            }}
          >
            {description}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PreviewDialog
