


import OtpInput from '../../../components/OtpInput'


interface VerifyProps {
  setOtp: (value: string) => void
  email: string
  status: string
  isSubmitting: boolean
  loading: boolean
  handleSubmit: () => void
  resentOtp: (value: string) => void;
}

export function Verify({ status, email, isSubmitting, loading, handleSubmit, setOtp, resentOtp }: VerifyProps) {
  return (
    <>
      <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' id='kt_verify'>
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Hi There! </h1>
          {/* end::Title */}

          <div className='text-gray-500 fw-semibold fs-6'>
            We sent a code to ( {email} ). Enter it here to verify your identity
          </div>
        </div>
        {/* end::Heading */}

        {status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{status}</div>
          </div>
        )}

        <div className='row'>
          <OtpInput
            length={4}
            className='otpContainer'
            inputClassName='otpInput'
            isNumberInput
            autoFocus
            onChangeOTP={(otp: string) => setOtp(otp)}
          />
        </div>
        <div className='text-center'>
          <button
            type='button'
            // id='kt_login_signup_form_cancel_button'
            className='w-10 my-5 border-0 fs-4 text-primary fw-bold activeClass'
            onClick={() => resentOtp(email)}
          >
            Resend Code
          </button>
          <button
            type='button'
            onClick={handleSubmit}
            id='kt_verify_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={isSubmitting}
          >
            {!loading && <span className='indicator-label'>Confirm</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {/* end::Form group */}
      </div>
    </>
  )
}
