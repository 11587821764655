/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {parseCookies} from 'nookies'
import {APIURL} from '../../../config/config'
import Button from 'react-bootstrap/Button'
import AddTutorialDialog from './AddTutorialDialog'
import PreviewDialog from './PreviewDialog'
import Pagination from 'replace-js-pagination'
import InfluencerSkeleton from '../campaign/Influencer/InfluencerSkeleton'

import {FaFilePdf, FaFileImage, FaFileVideo} from 'react-icons/fa'
import {Spinner} from 'react-bootstrap'
import {useAuth} from '../../modules/auth'
import getErrorMessage from '../../../_metronic/lib/ErrorHandler'

type Tutorial = {
  created_at?: string
  description?: string
  doc_url?: string
  id?: number | string
  merchant_id?: string | number
  merchant_name?: string
  type?: string
  status?: string | number
  screenshot?: string | null | any
}

const Tutorials: React.FC = () => {
  const [tutorialModal, setTutorialModal] = React.useState<boolean>(false)
  const [addModal, setAddModal] = React.useState<boolean>(false)
  const {currentUser, setCurrentUser} = useAuth()
  const cookies = parseCookies()

  React.useEffect(() => {
    let cuser = cookies?.user
    let luser
    if (cuser) {
      luser = JSON.parse(cuser)
    } else {
      luser = undefined
    }
    setCurrentUser(luser)
  }, [cookies?.user])

  const [tutorialInfo, setTutorialInfo] = React.useState<Tutorial>({})
  const [tutorials, setTutorials] = React.useState<Tutorial[]>([])
  const [editTutorialInfo, setEditTutorialInfo] = React.useState<Tutorial>({})

  const [total, setTotal] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [perPage, setPerpage] = React.useState<number>(0)
  const [lastPage, setLastPage] = React.useState<number>(0)
  const [activePage, setActivePage] = React.useState<number>(1)
  const [nextPage, setNextPage] = React.useState<boolean>(false)
  const [prevPage, setPrevPage] = React.useState<boolean>(false)

  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false)

  const [loading, setLoading] = React.useState<boolean>(true)

  const token = cookies?.token

  const getTutorials = async () => {
    try {
      var myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${token}`)

      const requestOptions: any = {
        method: 'GET',
        headers: myHeaders,
      }

      const response = await fetch(
        `${APIURL}/merchant/get-tutorials?page=${currentPage}`,
        requestOptions
      )
      const result = await response.json()
      if (result.success == true) {
        setActivePage(result?.paginate?.current_page)
        setTotal(result?.paginate?.total)
        setCurrentPage(result?.paginate?.current_page)
        setPerpage(result?.paginate?.per_page)
        setLastPage(result?.paginate?.last_page)
        if (result?.paginate?.next_url === null) {
          setNextPage(false)
        } else {
          setNextPage(true)
        }
        if (result?.paginate?.prev_url === null) {
          setPrevPage(false)
        } else {
          setPrevPage(true)
        }

        setTutorials(result?.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return {
        message: getErrorMessage(error),
      }
    }
  }

  React.useEffect(() => {
    getTutorials()
  }, [addModal, currentPage])

  const handlePageChange = () => {
    if (nextPage) {
      setCurrentPage((pre) => pre + 1)
    }

    if (prevPage) {
      setCurrentPage((pre) => pre - 1)
    }
  }

  const deleteTutorial = async (id: number | string) => {
    setDeleteLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions: any = {
      method: 'DELETE',
      headers: myHeaders,
    }

    const response = await fetch(`${APIURL}/merchant/tutorial?tutorial_id=${id}`, requestOptions)
    const result = await response.json()
    if (result.success == true) {
      getTutorials()
      setDeleteLoading(false)
    } else {
      setDeleteLoading(false)
    }
  }

  return (
    <>
      <AddTutorialDialog
        show={addModal}
        onHide={() => {
          setAddModal(false)
          setEditTutorialInfo({})
        }}
        tutorialInfo={editTutorialInfo}
      />
      <PreviewDialog
        show={tutorialModal}
        onHide={() => {
          setTutorialModal(false)
        }}
        doc_url={tutorialInfo?.doc_url}
        type={tutorialInfo?.type}
        merchant_name={tutorialInfo?.merchant_name}
        description={tutorialInfo?.description}
      />
      <PageTitle>Tutorial List</PageTitle>

      <Button
        variant='primary'
        className='btn btn-primary mb-4'
        style={{
          marginTop: '-20px',
        }}
        onClick={() => setAddModal(true)}
      >
        + Add Tutorial
      </Button>

      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card'>
            <div className='card-body pt-0'>
              <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    id='kt_subscriptions_table'
                  >
                    <thead>
                      <tr className='text-start text-dark fw-bolder fs-7 gs-0'>
                        <th className='min-w-125px sorting'>Merchant</th>
                        <th className='min-w-125px sorting'>Type</th>
                        <th className='min-w-125px sorting'>Description</th>
                        <th className='min-w-125px sorting text-center'>Status</th>
                        <th className='min-w-70px sorting_disabled text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600  fw-bold'>
                      {loading ? (
                        <>
                          {new Array(10).fill(0).map((_, index) => (
                            <InfluencerSkeleton key={index} />
                          ))}
                        </>
                      ) : tutorials.length === 0 ? (
                        <tr>
                          <td colSpan={4} className='text-center'>
                            No Data Found
                          </td>
                        </tr>
                      ) : (
                        tutorials &&
                        tutorials?.map(
                          (
                            {
                              type,
                              merchant_name,
                              doc_url,
                              description,
                              status,
                              id,
                              screenshot,
                            }: Tutorial,
                            i: number
                          ) => {
                            const fileType =
                              type === 'video' ? (
                                <FaFileVideo
                                  style={{
                                    fontSize: '26px',
                                  }}
                                />
                              ) : type === 'image' ? (
                                <FaFileImage
                                  style={{
                                    fontSize: '26px',
                                  }}
                                />
                              ) : (
                                <FaFilePdf
                                  style={{
                                    fontSize: '26px',
                                  }}
                                />
                              )

                            return (
                              <tr className='odd' key={`${i}.${merchant_name}`}>
                                <td>{merchant_name}</td>
                                <td>{fileType}</td>
                                <td>
                                  {description && description?.length > 20
                                    ? `${description?.slice(0, 20)}...`
                                    : description}
                                </td>
                                <td className='text-center'>
                                  {status == 1 ? 'Active' : 'Inactive'}
                                </td>
                                <td className='d-flex justify-content-center'>
                                  <Button
                                    variant='primary'
                                    size='sm'
                                    onClick={() => {
                                      setTutorialInfo({
                                        doc_url,
                                        description,
                                        type,
                                        merchant_name,
                                      })
                                      setTutorialModal(true)
                                    }}
                                  >
                                    Preview
                                  </Button>

                                  {['1', '2', '3'].includes(currentUser?.user_type as string) && (
                                    <Button
                                      variant='success'
                                      size='sm'
                                      className='ms-2'
                                      onClick={() => {
                                        setEditTutorialInfo({
                                          doc_url,
                                          description,
                                          type,
                                          merchant_name,
                                          id,
                                          screenshot,
                                        })
                                        setAddModal(true)
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  )}

                                  {['1', '2', '3'].includes(currentUser?.user_type as string) && (
                                    <Button
                                      variant='danger'
                                      size='sm'
                                      className='ms-2'
                                      onClick={() => deleteTutorial(id as number | string)}
                                      disabled={deleteLoading}
                                    >
                                      {deleteLoading ? (
                                        <Spinner animation='border' size='sm' />
                                      ) : (
                                        'Delete'
                                      )}
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            )
                          }
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='col-sm-12'></div>
              </div>
            </div>

            <div className='card-footer p-4 mb-2 bg-secondary'>
              <div className='d-flex flex-stack flex-wrap'>
                <div className='fs-6 fw-bold text-gray-700'>
                  Showing {currentPage} to {perPage} of {total} entries
                </div>

                <div className='pagination'>
                  <Pagination
                    hideFirstLastPages={false}
                    hideNavigation={true}
                    linkClassFirst='next'
                    linkClassPrev='previous'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default Tutorials
