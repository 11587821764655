/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { parseCookies } from 'nookies'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { baseUrl, APIURL } from '../../../config/config'
import { FaLocationDot } from 'react-icons/fa6'

const Invoice: FC = () => {

    const cookies = parseCookies()
    const token = cookies?.token;
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [campaigns, setCampaigns] = useState([]);


    const getCampaigns = async () => {
        setLoading(true);
        var myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${token}`)
        myHeaders.append('Content-Type', 'application/json')

        var requestOptions: any = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        try {
            const campaigns = await fetch(`${APIURL}/merchant/campaigns?search_type=upcoming`, requestOptions)
            const result = await campaigns.json()

            console.log('campaigns', result);

            if (result.success == true) {
                setCampaigns(result?.data)
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getCampaigns();
    }, [])

    return (
        <>
            <PageTitle>Campaigns</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col-xl-12'>
                    <div className='card card-xl-stretch'>
                        <div className='card-header border-0 bg-success py-5 flex align-items-center justify-content-between'>
                            <h3 className='card-title fw-bolder text-white'>Campaigns List</h3>
                        </div>
                        <div className='card-body p-2'>
                            {
                                loading ? null : <>
                                    {
                                        campaigns.map((campaign: any, index: number) => (
                                            <NavLink to={`/invoice/invoice-view/${campaign.id}`} key={index} className="w-100 row g-0 border rounded overflow-hidden d-flex mb-4 p-4 shadow-sm position-relative cursor-pointer">
                                                <div className="col-auto d-none d-lg-block">
                                                    <img
                                                        className="rounded bd-placeholder-img"
                                                        width={120}
                                                        height={120}
                                                        src={campaign.thumb_image}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="col p-4 d-flex flex-column position-static">
                                                    <div className='w-100 mb-4'>
                                                        <h1 className="d-inline-block mb-2 text-success-emphasis">
                                                            {campaign.name}
                                                        </h1>
                                                    </div>
                                                    <div className='w-100 mb-2 d-flex'>
                                                        <img
                                                            className="rounded-circle"
                                                            width={20}
                                                            height={20}
                                                            src={campaign.merchant_photo}
                                                            alt=""
                                                        />
                                                        <h3 className="mb-0" style={{ marginLeft: '5px' }}>{campaign.merchant_name}</h3>
                                                    </div>
                                                    <div className='w-100 d-flex'>
                                                        <FaLocationDot className='text-danger fs-2' />
                                                        <span className="mb-auto" style={{ marginLeft: '5px' }}>{campaign.location.location_name}</span>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        ))
                                    }</>
                            }

                            <div className="w-100 row mb-4 p-4"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Invoice;
