import useDetectMode from '../../../../hooks/useDetectMode'
import {FaPen} from 'react-icons/fa'
import {useState} from 'react'
import {FaImage} from 'react-icons/fa'
import {toast} from 'react-toastify'
interface CoverPhotoProps {
  step?: number
  profileErr: string|any
  setFieldValue: (e: any) => void
}

const CoverPhoto: React.FC<CoverPhotoProps> = ({step = 0, profileErr, setFieldValue}) => {
  const mode = useDetectMode()
  const [imgLoading, setImgLoading] = useState(false)
  const [profilePhoto, setProfilePhoto] = useState('')
  const [profileImg, setProfileImg] = useState('')


  function getBase64(_name: string, file: any, _file_name: string) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      let fileType = file.type
      if (fileType.match(/image/g) == 'image') {
        let databs64 = `data:${fileType};base64,`

        //@ts-ignore
        let imageFile = reader.result.replace(String(databs64), '')

        //@ts-ignore
        setProfileImg(reader.result)

        setProfilePhoto(imageFile)
        setFieldValue(imageFile)
        setImgLoading(false)
      } else {
        toast.error('Please select image file')
        setImgLoading(false)
      }
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }
  const uploadFile = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    setImgLoading(true)
    getBase64(e.target.name, e.target.files[0], e.target.files[0].name)
  }

  return (
    <div className=' my-10'>
      {step === 1 ? (
        <div className='row'>
          <div className='col-sm-12 d-flex justify-content-center'>
            <label
              className='text-center justify-content-center d-flex align-items-center flex-column'
              style={{
                width: '140px',
                height: '140px',
                cursor: 'pointer',
                borderStyle: 'dotted',
                overflow: 'hidden',
                borderRadius: '50%',
              }}
            >
              {imgLoading ? (
                <>
                  <div
                    className='d-flex justify-content-center img-fluid form-control'
                    style={{width: '140px', height: '140px'}}
                  >
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {profileImg == '' ? (
                    <>
                      <FaImage
                        style={{
                          width: '23px',
                          height: '23px',
                          marginTop: '0.5rem',
                        }}
                      />
                      <br />
                      <span
                        style={{
                          width: '100%',
                          marginTop: '0.25rem',
                          fontSize: '0.875rem',
                          fontWeight: 700,
                          lineHeight: '1.25rem',
                        }}
                      >
                        <div className='text-primary'> Upload</div> Profile Photo
                      </span>
                      <input
                        style={{visibility: 'hidden'}}
                        type='file'
                        onChange={(e) => uploadFile(e)}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className='d-flex justify-content-center'
                        style={{
                          width: '140px',
                          height: '140px',
                          overflow: 'hidden',
                        }}
                      >
                        <img
                          src={profileImg}
                          alt=''
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        />
                        <input className='d-none' type='file' onChange={(e) => uploadFile(e)} />
                      </div>
                    </>
                  )}
                </>
              )}
            </label>
          </div>
        </div>
      ) : step === 3 ? (
        <div
          className='position-relative'
          style={{
            width: '140px',
            height: '140px',
          }}
        >
          <div
            className='d-flex justify-content-center overflow-hidden rounded-circle'
            style={{
              width: '140px',
              height: '140px',
              overflow: 'hidden',
              border:
                mode === 'light' || mode === 'system' ? '1px dashed #fff' : '1px dashed #252525',
            }}
          >
            <img
              src={profileImg}
              alt=''
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>

          <label
            htmlFor='file'
            style={{
              height: '40px',
              width: '40px',
            }}
            className='position-absolute top-0 end-0 fs-2 cursor-pointer rounded-circle d-flex justify-content-center align-items-center p-2 bg-black text-white'
          >
            <FaPen />
          </label>

          <input
            hidden
            type='file'
            name='file'
            id='file'
            accept='image/*'
            onChange={(e) => uploadFile(e)}
          />
        </div>
      ) : (
        ''
      )}
      {profileErr ? (
        <>
          <div className='col-sm-12 mt-2 d-flex justify-content-center'>
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>Profile image is required</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default CoverPhoto
