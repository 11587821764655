/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react'
import useCharacterLimit from '../../../../_metronic/hooks/useCharacterLimit'
import isEqual from 'react-fast-compare'

interface IAbout {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
}

const About = ({ campaignState, setCampaignState }: IAbout) => {
  const maxWords = 500
  const { value, onChange, message } = useCharacterLimit('', maxWords)

  React.useEffect(() => {
    setCampaignState((pre) => ({
      ...pre,
      about: value,
    }))
  }, [value])

  return (
    <div className="row mt-6">
      <div className='col-sm-12'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>About / Requirements</span>
        </label>
        <textarea
          name='about'
          value={campaignState.about}
          onChange={onChange}
          className='form-control'
          rows={4}
          cols={40}
          placeholder='About Campaign'
        />

        <div className='d-flex justify-content-between mt-1 fw-bold fs-7'>
          <p className='text-danger'>{message ? message : ''}</p>
          <p
            className='text-muted'
            style={{
              textAlign: 'right',
            }}
          >
            <span className={message ? 'text-danger' : 'text-muted'}>{value?.length}</span>/
            {maxWords}
          </p>
        </div>
      </div>
    </div>
  )
}

export default memo(About, isEqual)
