/* eslint-disable eqeqeq */
import React from 'react'
import useSWR from 'swr'
import {APIURL} from '../../config/config'
import {PageTitle} from '../../_metronic/layout/core'
import {Button} from 'react-bootstrap'

const PrivacyPolicy = () => {
  const [page, setPage] = React.useState(1)
  const [pagination, setPagination] = React.useState({} as any)

  const fetcher = async (url: string) => {
    const response = await fetch(url)
    const result = await response.json()

    setPage(result?.paginate?.current_page)
    setPagination(result?.paginate)
    return result?.data
  }

  const {data, error, isLoading, isValidating} = useSWR(
    `${APIURL}/merchant/privacies?page=${page}`,
    fetcher
  )

  if (isValidating) {
    return (
      <div className='card mb-2'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Loading...</span>
          </h3>
        </div>
      </div>
    )
  }

  if (error) {
    return <div>{error.message}</div>
  }

  return (
    <>
      <PageTitle>Privacy Policy</PageTitle>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xxl-12'>
          {isLoading && (
            <div className='card mb-2'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Loading...</span>
                </h3>
              </div>
            </div>
          )}

          {!isLoading && !data?.length && (
            <div className='card mb-2'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>No data found</span>
                </h3>
              </div>
            </div>
          )}

          {data?.map(({title, content, id, status}: any) => (
            <>
              {status == '1' ? (
                <>
                  <div className='card mb-2' key={id}>
                    <div className='card-header border-0 pt-5'>
                      <h3
                        className='card-title align-items-start flex-column'
                        style={{
                          wordWrap: 'break-word',
                        }}
                      >
                        <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
                        <pre
                          className='text-muted mt-2 mb-1 fw-bold fs-5'
                          style={{
                            overflowX: 'auto',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                          }}
                        >
                          {content}
                        </pre>
                      </h3>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ))}
        </div>
      </div>

      {pagination?.next_url && (
        <div className='d-flex align-items-center'>
          <ul className='pagination'>
            <li className='page-item'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => setPage(page - 1)}
                disabled={pagination?.prev_url ? false : true}
              >
                Previous
              </Button>
            </li>

            <li className='page-item'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => setPage(page + 1)}
                disabled={pagination?.next_url ? false : true}
              >
                Next
              </Button>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default PrivacyPolicy
