import React, { useEffect, useState } from 'react'
import axios from '../../../_metronic/hooks/axiosPrivate';
import Back from '../../../app/components/common/BackButton';
import { Button } from 'react-bootstrap';
import InfluencerSkeleton from './Influencer/InfluencerSkeleton';
import { ApprovedStateType, PaginationInfoType, PendingInvitesType } from './type';
import Pagination from 'replace-js-pagination';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';


const PendingInvites = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [approveData, setApproveData] = useState<ApprovedStateType>({
    loading: false,
    data: {},
    error: null,
  });

  const [rejectData, setRejectData] = useState<ApprovedStateType>({
    loading: false,
    data: {},
    error: null,
  });

  const [invites, setInvites] = useState<PendingInvitesType[]>([] as PendingInvitesType[]);
  const [paginationInfo, setPaginagtionInfo] = useState<PaginationInfoType>({
    total: 0,
    currentPage: 1,
    perPage: 0,
    lastPage: 0,
    activePage: 1,
    nextPage: false,
    prevPage: false,
  });

  useEffect(() => {
    setLoading(true);
    axios.get("/merchant/booking-list", { params: { search_type: 'pending', page: paginationInfo?.currentPage } }).then((res) => {
      if (res?.status === 200 && res?.data?.success) {
        const result = res?.data;
        setPaginagtionInfo((prevState) => ({
          ...prevState,
          activePage: result?.paginate?.current_page,
          currentPage: result?.paginate?.current_page,
          total: result?.paginate?.total,
          perPage: result?.paginate?.per_page,
          lastPage: result?.paginate?.last_page,
        }));

        if (result?.paginate?.next_url === null) {
          setPaginagtionInfo((prevState) => ({
            ...prevState,
            nextPage: false,
          }));
        } else {
          setPaginagtionInfo((prevState) => ({
            ...prevState,
            nextPage: true,
          }));
        }

        if (result?.paginate?.prev_url === null) {
          setPaginagtionInfo((prevState) => ({
            ...prevState,
            prevPage: false,
          }));
        } else {
          setPaginagtionInfo((prevState) => ({
            ...prevState,
            prevPage: true,
          }));
        }

        setInvites(result?.data);
      }
    })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const approveBookingHandler = (bookingId: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Approve this influencer?",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      confirmButtonColor: '#148FF9',
      cancelButtonColor: 'transparent',
    }).then((result) => {
      if (result?.isConfirmed) {
        axios.post("/merchant/approve-booking", { booking_id: bookingId })
          .then((res) => {
            console.log('APPROVED', res);
            if (res?.status === 200 && res?.data?.success) {
              const result = res?.data;
              console.log('APPROVED', result);
              setApproveData((prevState) => ({
                ...prevState,
                data: result?.data,
                error: "There is something wrong",
              }));

              toast.success("Invites has been approved successfully!");
            }
          })
          .catch((err) => {
            console.log('APPROVED', err.response);
            setApproveData((prevState) => ({
              ...prevState,
              error: "There is something wrong",
            }));
          });
      }
    })
  }

  const rejectBookingHandler = (bookingId: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Reject this influencer?",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#148FF9',
    }).then((result) => {
      if (result?.isConfirmed) {
        axios.post("/merchant/reject-booking", { booking_id: bookingId })
          .then((res) => {
            // console.log(res);
            if (res?.status === 200 && res?.data?.success) {
              const result = res?.data;
              // console.log('APPROVED', result);
              setRejectData({
                data: result?.data,
                loading: false,
                error: null,
              });
              toast.success("Invites has been rejected successfully!");
            }
          })
          .catch((err) => {
            // console.log(err.response);
            setRejectData((prevState) => ({
              ...prevState,
              loading: false,
              error: "There is something wrong",
            }));
          });
      }
    })
  }

  const handlePageChange = () => {
    if (paginationInfo?.nextPage) {
      setPaginagtionInfo((prevState) => ({
        ...prevState,
        currentPage: prevState?.currentPage + 1,
      }));
    }

    if (paginationInfo?.prevPage) {
      setPaginagtionInfo((prevState) => ({
        ...prevState,
        currentPage: prevState?.currentPage - 1,
      }));
    }
  };

  return loading ? (<>...loading</>) : (
    <>
      <div className="d-flex mb-5 gap-3 align-items-center">
        <Back /> <h2 className="mb-0">Pending Invites</h2>
      </div>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card'>
            <div className='card-body pt-0'>
              <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    id='kt_subscriptions_table'
                  >
                    <thead>
                      <tr className='text-start text-dark fw-bolder fs-7 text-capitalize gs-0'>
                        <th className='min-w-125px sorting'>Image</th>
                        <th className='min-w-125px sorting'>Influencer Name</th>
                        <th className='min-w-125px sorting'>Campaign Name</th>
                        <th className='min-w-125px sorting'>Schedule</th>
                        <th className='min-w-125px  sorting_disabled text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                      {loading ? (
                        <>
                          {new Array(3).fill(0).map((_, index) => (
                            <InfluencerSkeleton key={index} />
                          ))}
                        </>
                      ) : invites?.length === 0 ? (
                        <>
                          <tr>
                            <td colSpan={6} className='text-center'>
                              No Data Found
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {invites?.map((invite: any, index: any) => (
                            <tr className='odd align-middle' key={`${invite?.booking_id}.${index}`}>
                              <td>
                                <picture>
                                  <img
                                    src={invite?.influencer?.profile_photo}
                                    alt={invite?.influencer?.full_name}
                                    style={{
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      borderRadius: '10px',
                                    }}
                                  />
                                </picture>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-center flex-column'>
                                    {invite?.influencer?.full_name}
                                  </div>
                                </div>
                              </td>
                              <td>{invite?.campaign?.name}</td>
                              <td>{invite?.booking_date} - {invite?.start_time}</td>
                              <td>
                                <div className='w-100 d-flex justify-content-center gap-1'>
                                  <Button
                                    variant='light'
                                    className='btn btn-light btn-active-light-primary btn-sm'
                                    onClick={() => approveBookingHandler(invite?.booking_id)}
                                    title='Approve'
                                    disabled={approveData?.loading}
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    variant='light'
                                    className='btn btn-outline-warning btn-sm'
                                    onClick={() => rejectBookingHandler(invite?.booking_id)}
                                    title='Reject'
                                    disabled={rejectData?.loading}
                                  >
                                    Reject
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='col-sm-12'></div>
              </div>
            </div>

            <div className='card-footer p-4 mb-2 bg-secondary'>
              <div className='d-flex flex-stack flex-wrap'>
                <div className='fs-6 fw-bold text-gray-700'>
                  Showing {paginationInfo?.currentPage} to {paginationInfo?.perPage} of {paginationInfo?.total} entries
                </div>

                <div className='pagination'>
                  <Pagination
                    hideFirstLastPages={false}
                    hideNavigation={true}
                    linkClassFirst='next'
                    linkClassPrev='previous'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={paginationInfo?.activePage}
                    itemsCountPerPage={paginationInfo?.perPage}
                    totalItemsCount={paginationInfo?.total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingInvites