import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardBackspace } from 'react-icons/md';


const Back = () => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className="btn bg-black text-white"><MdKeyboardBackspace size={20} /></button>
  );
}

export default Back;