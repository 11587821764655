/* eslint-disable react-hooks/exhaustive-deps */
import classes from './CoverPhoto.module.scss'
import { makeFileToBase64 } from '../../../utils/makeFileToBase64'
import { toast } from 'react-toastify'
import isEqual from 'react-fast-compare'
import { memo } from 'react'

interface IGallery {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
}

const Gallery = ({ campaignState, setCampaignState }: IGallery) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      for (let i = 0; i < e.target.files.length; i++) {
        // detect file type
        const fileType = e.target.files[i].type

        // check file type SVG, PNG, JPG or GIF
        if (
          fileType !== 'image/png' &&
          fileType !== 'image/jpg' &&
          fileType !== 'image/jpeg' &&
          fileType !== 'image/svg+xml' &&
          fileType !== 'image/gif'
        ) {
          toast.error('Only PNG, JPG, JPEG, SVG or GIF file are allowed')
        } else {
          const file = e.target.files[i]
          const convertFileToBase64 = makeFileToBase64(file)

          convertFileToBase64.then((result) => {
            setCampaignState((pre) => ({
              ...pre,
              gallery: [...pre.gallery, result],
            }))
          })
        }
      }
    }
  }

  const handleDelete = (index: number) => {
    const updatedImages = campaignState.gallery.filter((_, i) => i !== index)

    setCampaignState((pre) => ({
      ...pre,
      gallery: updatedImages,
    }))
  }

  return (
    <>
      <div className='col-sm-12 col-md-6'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span>Add More Photo For Gallery </span>
        </label>
        <div className={classes.wrapper}>
          <div className={classes.imageContainer}>
            <img
              src='/media/campaign/placeholder_image.svg'
              className={classes.image}
              alt='cover'
            />
          </div>

          <div className={classes.upload}>
            <label htmlFor='upload-photos' className={classes.uploadLabel}>
              <span className={classes.label}>Upload</span> Photos
            </label>

            <input
              type='file'
              name='photos'
              id='upload-photos'
              className={classes.uploadInput}
              hidden
              multiple
              accept='image/*'
              onChange={handleFileChange}
            />

            <p className={classes.uploadText}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </div>
        </div>

        <div className={classes.galleryContainer}>
          {campaignState.gallery.map((image: string, index: number) => (
            <div className='position-relative' key={index}>
              <div
                style={{
                  width: '146px',
                  height: '146px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
              >
                <img src={image} className={classes.image} alt='cover' key={index} />
              </div>

              <button
                className={`${classes.deleteButton} position-absolute`}
                onClick={() => handleDelete(index)}
                type='button'
              >
                <img
                  src='/media/icons/duotune/arrows/arr097.svg'
                  alt='delete'
                  className='img-fluid'
                />
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default memo(Gallery, isEqual)
