import {FaArrowLeft} from 'react-icons/fa'
import useDetectMode from '../../../hooks/useDetectMode'
import styles from './common.module.scss'
import {Link} from 'react-router-dom'

const PendingStatus = () => {
  const mode = useDetectMode()

  return (
    <section
      className={
        mode === 'light' || mode === 'system' ? styles.pending__wrapper : styles.pendingWrapperDark
      }
    >
      <div className='d-flex justify-content-start align-items-center w-100 mb-10'>
        <Link
          to='/login'
          onClick={() => {}}
          type='button'
          className='btn btn-sm mb-3 d-flex btn-primary align-items-center'
        >
          <FaArrowLeft />{' '}
          <span
            style={{
              marginLeft: '8px',
            }}
          >
            Back
          </span>
        </Link>
      </div>

      <div className={styles.img_container}>
        <img src='/media/icons/duotune/status/logo.png' alt='logo' />
      </div>
      <div className='flex-grow-1' />
      <div>
        <h1 className='text-center mb-5'>Thank You for Applying!</h1>
        <p className='text-muted text-center'>
          Our Creator team is reviewing your application, we’ll send you an email when you’ve been
          accepted as an Elite Lister.
        </p>
      </div>
      <div className='flex-grow-1' />
      <div>
        <p className='text-center fw-medium'>Account Status</p>
        <h1
          className='text-center mb-5 h1 fw-bolder'
          style={{
            color: '#FFC107',
          }}
        >
          Pending
        </h1>
      </div>
    </section>
  )
}

export default PendingStatus
