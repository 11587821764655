/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import Select from 'react-select'
import { FaPlus, FaTrash, FaCheckCircle, FaWindowClose } from 'react-icons/fa'
import moment from 'moment'
import { add } from 'date-fns'
import { toast } from 'react-toastify'
import isEqual from 'react-fast-compare'

interface ITimeSlot {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
}

type rangeTimeType = {
  value: string
  label: string
}

const personSlots = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
];

const TimeSlot: React.FC<ITimeSlot> = ({ campaignState, setCampaignState }) => {
  const [inRangeDates, setInRangeDates] = React.useState<rangeTimeType[]>([])
  const [times, setTimes] = React.useState<any>([])
  const [selectedDate, setSelectedDate] = React.useState<any>('')
  const [startTime, setStartTime] = React.useState<any>('')
  const [endTime, setEndTime] = React.useState<any>('')
  const [timeSlots, setTimeSlots] = React.useState<any>([])
  const [personSlot, setPersonSlot] = React.useState<any>("");
  const [isTimeSlotChange, setIsTimeSlotChange] = React.useState<boolean>(false)

  const getDatesInRange = (startDate: any, endDate: any) => {
    const date = new Date(startDate.getTime())

    const dates = []

    while (date <= endDate) {
      dates.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }

    return dates?.map((date: any) => moment(date).format('YYYY-MM-DD'))
  }

  React.useEffect(() => {
    if (campaignState?.startDate && campaignState?.endDate) {
      const startDate = new Date(campaignState?.startDate)
      const endDate = new Date(campaignState?.endDate)
      const dates = getDatesInRange(startDate, endDate)

      const dateOptions = dates?.map((date: any) => {
        return { value: date, label: date }
      })

      setInRangeDates(dateOptions)
    }
  }, [campaignState?.startDate, campaignState?.endDate])

  const handleDateChange = (e: any) => {
    setSelectedDate(e)
    getTimes(e)
  }

  const getTimes = (date: any) => {
    const beginning = add(new Date(date?.value), { hours: 0, minutes: 0 })
    const end = add(new Date(date?.value), { hours: 23, minutes: 59 })
    const interval = 5 // 5 min interval

    const times: any[] = []

    for (let i = beginning; i <= end; i = add(i, { minutes: interval })) {
      times.push(moment(i).format('hh:mm A'))
    }

    const timeOptions = times?.map((time: any) => {
      return { value: time, label: time }
    })

    setTimes(timeOptions)
  }

  const addTimeSlot = () => {
    if (!startTime?.value || !endTime?.value || !personSlot) {
      return toast.error('Please Select Start Time , End Time and Time Slots');
    } else if (startTime?.value === endTime?.value) {
      return toast.error('Start Time and End Time can not be same');
    }

    // check if start time is greater than end time
    const startTimeInMinutes = moment(startTime?.value, 'hh:mm A').format('HH:mm')
    const endTimeInMinutes = moment(endTime?.value, 'hh:mm A').format('HH:mm')

    if (startTimeInMinutes > endTimeInMinutes) {
      return toast.error('Start Time can not be greater than End Time')
    } else {
      // make the time 24 hours format
      const startTimeIn24Hours = moment(startTime?.value, 'hh:mm A').format('HH:mm:ss')
      const endTimeIn24Hours = moment(endTime?.value, 'hh:mm A').format('HH:mm:ss')

      const times = [{ time: `${startTimeIn24Hours} - ${endTimeIn24Hours}`, participant_limit: personSlot?.value, }]

      // const times = [`${startTime?.value}-${endTime?.value}`]
      console.log(times);
      const time_slot = {
        date: selectedDate?.value,
        slot: times,
      }

      // merge time slot as an array when date is same
      const newTimeSlots = [...timeSlots]
      const index = newTimeSlots.findIndex((item: any) => item?.date === selectedDate?.value)
      if (index !== -1) {
        newTimeSlots[index].slot = [...newTimeSlots[index].slot, ...times]
      } else {
        newTimeSlots.push(time_slot)
      }
      setTimeSlots(newTimeSlots);

      const newCampaign = { ...campaignState, time_slot: newTimeSlots }
      setCampaignState(newCampaign)
      console.log('TIMES', newCampaign);
      // setSelectedDate('')
      setStartTime('')
      setEndTime('')
      setPersonSlot('')
      setIsTimeSlotChange(true)
    }
  }


  const timeSlot = (time: any) => {
    const timeSlotToAMTM = (time: any) => {
      const [hours, minutes] = time.split(':')
      const AMPM = hours >= 12 ? 'PM' : 'AM'
      const hoursIn12HrFormat = hours % 12 || 12
      return `${hoursIn12HrFormat}:${minutes} ${AMPM}`
    }
    const [startTime, endTime] = time.time.split('-')
    return `${timeSlotToAMTM(startTime)} - ${timeSlotToAMTM(endTime)} & Min Slot: ${time?.participant_limit}`;
  }


  const removeSlot = (date: string, slot: any) => {
    const newTimeSlots: any = [];
    timeSlots.forEach((item: any) => {
      if (item.date === date) {
        newTimeSlots.push({
          date: item.date,
          slot: item.slot.filter((time: any) => time.time !== slot?.time),
        })
      } else {
        newTimeSlots.push(item)
      }
      setTimeSlots(newTimeSlots);
    })
  }

  const removeTimeSlot = (index: number) => {
    let newTimeSlots = [...timeSlots]
    newTimeSlots.splice(index, 1)
    setTimeSlots(newTimeSlots)
    setIsTimeSlotChange(true)
  }

  const customStyles = {
    container: (base: any, state: any) => ({
      ...base,
      'react-select-3-listbox': {
        background: '#323248 !important'
      }
    }),
    control: (base: any, state: any) => ({
      ...base,
      backgroundColor: '#1e1e2d',
      border: '1px solid #323248',
      minHeight: '44px',
      '&:hover': {
        border: '1px solid #323248',
      }
    }),
    menuList: (base: any, state: any) => ({
      ...base,
      padding: 0,
      backgroundColor: 'white',
      color: '#92929f',
    }),
    singleValue: (base: any, state: any) => ({
      ...base,
      color: '#92929f',
      fontSize: '1.1rem',
      fontWeight: 500,
    }),
    input: (base: any, state: any) => ({
      ...base,
      color: '#fff',
    })
  }

  React.useEffect(() => {
    if (isTimeSlotChange) {
      setIsTimeSlotChange(false)
    }
  }, [isTimeSlotChange])

  React.useEffect(() => {
    if (startTime?.value === '' && endTime?.value === '') {
      return
    } else {
      // merge time as an array when date is same
      const newTimeSlots = [...timeSlots]
      const dateWiseTimeSlots: any[] = []
      newTimeSlots?.forEach((item: any) => {
        const index = dateWiseTimeSlots.findIndex((date: any) => date?.date === item?.date)
        if (index !== -1) {
          dateWiseTimeSlots[index].slot = [...dateWiseTimeSlots[index].slot, ...item?.slot]
        } else {
          dateWiseTimeSlots.push(item)
        }
      })

      const newCampaign = { ...campaignState, time_slot: dateWiseTimeSlots }
      setCampaignState(newCampaign)
    }
  }, [isTimeSlotChange]);



  return (
    <>
      <div className="row mt-6">
        <div className="col-sm-12 col-md-3">
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Date</span>
            <span className=''>{`(Example : 2023-03-08)`}</span>
          </label>
          <Select
            styles={customStyles}
            options={inRangeDates}
            name='date'
            placeholder='Select Date'
            value={selectedDate}
            defaultValue={inRangeDates[0]}
            onChange={handleDateChange}
            isDisabled={inRangeDates?.length === 0}
          />
        </div>
        <div className="col-sm-12 col-md-3">
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Start Time</span>
            <span className=''>{`(Example : 12:30,2:30)`}</span>
          </label>
          <Select
            styles={customStyles}
            options={times}
            name='start_time'
            placeholder='Select Start Time'
            value={startTime}
            defaultValue={times[0]}
            onChange={(e: any) => {
              setStartTime(e)
              // remove start time from end time
              const newTimes = times?.filter((item: any) => item?.value !== e?.value)
              setTimes(newTimes)
            }}
            isDisabled={times?.length === 0}
          />
        </div>
        <div className='col-sm-12 col-md-3'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>End Time</span>
            <span className=''>{`(Example : 3:30,4:30)`}</span>
          </label>
          <Select
            styles={customStyles}
            options={times}
            name='end_time'
            value={endTime}
            defaultValue={times[0]}
            placeholder='Select End Time'
            onChange={(e: any) => setEndTime(e)}
            isDisabled={times?.length === 0}
          />
        </div>
        <div className='col-sm-12 col-md-2'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Slot</span>
            <span className=''>{`(Minimum Limit)`}</span>
          </label>
          <Select
            styles={customStyles}
            options={personSlots}
            name="participant_limit"
            value={personSlot}
            defaultValue={personSlots[0]}
            placeholder='Select Slot'
            onChange={(e: any) => setPersonSlot(e)}
          // onChange={(e: any) => setEndTime(e)}
          />
        </div>
        <div className="col-sm-12 col-md-1 d-flex align-items-end justify-content-end">
          <button type="button" className="btn btn-medium btn-primary" onClick={addTimeSlot}>
            <FaPlus />
          </button>
        </div>
      </div>

      {timeSlots?.map((item: any, index: number) => (
        <div key={index} className="row mt-4">
          <div className='col-sm-11 col-md-11'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                {/* tick */}
                <span className='me-2'>
                  <FaCheckCircle
                    style={{
                      color: '#00b74a',
                    }}
                  />
                </span>
                <span className='me-2'>Date: {item?.date},</span>
                <span className='me-2'>
                  Time slot: {item?.slot?.map((time: any, index: number) => (
                    <>
                      {timeSlot(time)} <FaWindowClose style={{ cursor: "pointer", marginLeft: "3px" }}
                        onClick={() => removeSlot(item?.date, time)} /> {index === item?.slot?.length - 1 ? '' : ', '}
                    </>
                  ))}
                </span>
              </div>
            </div>
          </div>
          <div className='col-sm-1 col-md-1 d-flex align-items-end justify-content-end'>
            <button
              type='button'
              className='btn btn-sm btn-danger'
              onClick={() => removeTimeSlot(index)}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      ))}

    </>
  )
}



export default React.memo(TimeSlot, isEqual)
