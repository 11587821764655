/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { MdOutlineReviews } from 'react-icons/md'
import { IoPersonAddSharp } from 'react-icons/io5'
import { FaUserCheck } from 'react-icons/fa'
import { parseCookies } from 'nookies'
import moment from 'moment'
import { PageTitle } from '../../../_metronic/layout/core'
import { APIURL } from '../../../config/config'
// import CamStatus from '../../../app/components/CamStatus'
import InfluencerSkeleton from './Influencer/InfluencerSkeleton'
import { Button } from 'react-bootstrap'
import CampaignReviews from './CampaignReviews'
import { useAuth } from '../../modules/auth'
import getErrorMessage from '../../../_metronic/lib/ErrorHandler'
import Pagination from 'replace-js-pagination'
import CampaignState from './CampaignState'
import axios from '../../../_metronic/hooks/axiosPrivate';
import { ReportStateType } from './type'
import { toast } from 'react-toastify';

const Campaign: FC = () => {
  const cookies = parseCookies();
  const navigate = useNavigate();
  const token = cookies?.token
  const [loading, setLoading] = useState<boolean>(true)
  const [show, setShow] = useState<boolean>(false)
  const [reports, setReports] = useState<ReportStateType>({
    pending: 0,
    upcoming: 0,
    inProcess: 0,
    completed: 0,
    noShow: 0,
  });

  const [campaigns, setCampaigns] = useState([])
  const [campaignId, setCampaignId] = useState<string>('')
  const { currentUser, setCurrentUser } = useAuth()

  const [total, setTotal] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [perPage, setPerpage] = React.useState<number>(0)
  const [lastPage, setLastPage] = React.useState<number>(0)
  const [activePage, setActivePage] = React.useState<number>(1)
  const [nextPage, setNextPage] = React.useState<boolean>(false)
  const [prevPage, setPrevPage] = React.useState<boolean>(false)
  const [cancelLoad, setCancelLoad] = React.useState<boolean>(false);

  useEffect(() => {
    let cuser = cookies?.user
    let luser
    if (cuser) {
      luser = JSON.parse(cuser)
    } else {
      luser = undefined
    }
    setCurrentUser(luser)
  }, [cookies?.user])

  const getCampaigns = () => {
    setLoading(true);
    axios.get(`/merchant/campaigns?page=${currentPage}`)
      .then((res) => {
        if (res.status === 200 && res?.data?.success) {
          const result = res?.data;
          setActivePage(result?.paginate?.current_page)
          setTotal(result?.paginate?.total)
          setCurrentPage(result?.paginate?.current_page)
          setPerpage(result?.paginate?.per_page)
          setLastPage(result?.paginate?.last_page)
          if (result?.paginate?.next_url === null) {
            setNextPage(false)
          } else {
            setNextPage(true)
          }
          if (result?.paginate?.prev_url === null) {
            setPrevPage(false)
          } else {
            setPrevPage(true)
          }
          setCampaigns(result?.data)
        }
      })
      .catch((err) => console.log(err.response))
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getCampaigns()
  }, [currentPage])

  useEffect(() => {
    setLoading(true);
    axios.get("merchant/dashboard-counts")
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res?.data?.success) {
          const result = res?.data?.data;
          setReports({
            pending: Number(result?.pending_invites),
            upcoming: Number(result?.upcoming_campaigns),
            completed: Number(result?.completed_campaigns),
            inProcess: Number(result?.inprogress_campaigns),
            noShow: Number(result?.no_show),
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handlePageChange = () => {
    if (nextPage) {
      setCurrentPage((pre) => pre + 1)
    }

    if (prevPage) {
      setCurrentPage((pre) => pre - 1)
    }
  }

  const handleCancelCampaign = (id: number) => {
    setCancelLoad(true);
    axios.get("/merchant/cancel-campaign",{ params: { id: id } })
      .then((res) => {
        console.log('cancel campaign', res);
        if (res?.status === 200 && res?.data?.success) {
          toast.success("Campaign has been in active successfully!");
        }
      })
      .catch((err) => {
        toast.error("There is something wrong!");
      }).finally(() => {
        setCancelLoad(false);
      })
  }

  return (
    <>
      <PageTitle>Active Campaign List</PageTitle>
      <CampaignState reports={reports} />
      <div className="d-flex justify-content-end">
        <Link className="btn btn-primary mb-4 text-end ml-auto d-inline-block" to="add-campaign">
          + Add New Campaign
        </Link>
      </div>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card'>
            <div className='card-body pt-0'>
              <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    id='kt_subscriptions_table'
                  >
                    <thead>
                      <tr className='text-start text-dark fw-bolder fs-7 text-capitalize gs-0'>
                        <th className='min-w-125px sorting'>Image</th>
                        <th className='min-w-125px sorting'>Campaign Name</th>
                        <th className='min-w-125px sorting'>Merchant</th>
                        <th className='min-w-125px sorting'>Date</th>
                        <th className='min-w-70px sorting_disabled text-center'>Status</th>
                        <th className='min-w-125px  sorting_disabled text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                      {loading ? (
                        <>
                          {new Array(3).fill(0).map((_, index) => (
                            <InfluencerSkeleton key={index} />
                          ))}
                        </>
                      ) : campaigns.length === 0 ? (
                        <tr>
                          <td colSpan={6} className='text-center'>
                            No Data Found
                          </td>
                        </tr>
                      ) : (
                        <>
                          {campaigns.map((item: any, index: any) => (
                            <tr className='odd align-middle' key={`${item.id}.${index}`}>
                              <td>
                                <picture>
                                  <img
                                    src={item.thumb_image}
                                    alt={item.name}
                                    style={{
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      borderRadius: '10px',
                                    }}
                                  />
                                </picture>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-center flex-column'>
                                    {/* <a
                                      href='#'
                                      className='text-dark fw-bolder text-hover-primary fs-6'
                                    > */}
                                    {item.name}
                                    {/* </a> */}
                                  </div>
                                </div>
                              </td>
                              <td>{item.merchant_name}</td>
                              <td>
                                {moment(item.start_date).format('Do, MMMM YYYY')} -{' '}
                                {moment(item.end_date).format('Do, MMMM YYYY')}
                              </td>
                              <td>
                                {/* <span className='mr-2 h-8 d-flex align-items-center justify-content-center'>
                                  <CamStatus id={item.id} status={item.status} />
                                </span> */}

                                <span className='mr-2 h-8 d-flex align-items-center justify-content-center'>
                                  {item.status == 0
                                    ? 'Pending'
                                    : item.status == 1
                                      ? 'Live'
                                      : item.status == 2
                                        ? 'Expired'
                                        : item.status == 3
                                          ? 'Rejected'
                                          : 'Pending'}
                                </span>
                              </td>
                              <td
                                className='d-flex gap-3 align-items-center justify-content-center'
                                style={{
                                  minHeight: '103px',
                                }}
                              >
                                <NavLink
                                  to={`/campaign/${item.id}`}
                                  className='btn btn-light btn-active-light-primary btn-sm'
                                >
                                  View
                                </NavLink>

                                {/* <Button
                                  variant='light'
                                  className='btn btn-light btn-active-light-primary btn-sm'
                                  onClick={() => {
                                    setShow(true)
                                    setCampaignId(item.id)
                                  }}
                                  title='Reviews'
                                >
                                  <MdOutlineReviews />
                                </Button> */}
                                {/* if end date is available in my current date then show button */}
                                {['3', '4'].includes(currentUser?.user_type as string) && (
                                  <>
                                    {new Date(item?.end_date).getTime() >= new Date().getTime() && (
                                      <NavLink
                                        to={`/campaign/${item.id}/invitation`}
                                        className='btn btn-primary btn-active-light-primary btn-sm'
                                        style={{
                                          whiteSpace: 'nowrap',
                                        }}
                                        // title='Invite Influencer'
                                        title='Invite'
                                      >
                                        Invite
                                        {/* <IoPersonAddSharp /> */}
                                      </NavLink>
                                    )}
                                  </>
                                )}

                                <Button
                                  variant='light'
                                  className='btn btn-light btn-active-light-primary btn-sm'
                                  onClick={() => handleCancelCampaign(item.id)}
                                  title='Cancel'
                                  disabled={cancelLoad}
                                >
                                  In Active
                                  {/* <MdOutlineReviews /> */}
                                </Button>


                                {/* {['3', '4'].includes(currentUser?.user_type as string) && (
                                  <>
                                    {new Date(item?.end_date).getTime() >= new Date().getTime() && (
                                      <NavLink
                                        to={`/campaign/${item.id}/sent-request`}
                                        className='btn btn-primary btn-active-light-primary btn-sm'
                                        style={{
                                          whiteSpace: 'nowrap',
                                        }}
                                        title='View Invited Influencer'
                                      >
                                        <FaUserCheck />
                                      </NavLink>
                                    )}
                                  </>
                                )} */}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='col-sm-12'></div>
              </div>
            </div>

            <div className='card-footer p-4 mb-2 bg-secondary'>
              <div className='d-flex flex-stack flex-wrap'>
                <div className='fs-6 fw-bold text-gray-700'>
                  Showing {currentPage} to {perPage} of {total} entries
                </div>

                <div className='pagination'>
                  <Pagination
                    hideFirstLastPages={false}
                    hideNavigation={true}
                    linkClassFirst='next'
                    linkClassPrev='previous'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CampaignReviews show={show} campaign_id={campaignId} onHide={() => setShow(false)} />
    </>
  )
}

export default Campaign
