/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import {parseCookies} from 'nookies'
import {Alert, Button, Form, Modal, Spinner} from 'react-bootstrap'
import {APIURL} from '../../../config/config'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {FaFilePdf, FaFileVideo} from 'react-icons/fa'
import useCharacterLimit from '../../../_metronic/hooks/useCharacterLimit'
import getErrorMessage from '../../../_metronic/lib/ErrorHandler'

interface AddTutorialDialogProps {
  show: boolean
  onHide: () => void
  tutorialInfo?: any
}

type InitialTutorialState = {
  description: string
  type: string
  file: File | null
  directory_name: string
  thumbnail: string
  video: string
}

type Request = {
  method: string
  body: FormData
  headers: Headers
  redirect: RequestRedirect | undefined
}

const AddTutorialDialog: React.FC<AddTutorialDialogProps> = (props) => {
  const initialTutorialState: InitialTutorialState = {
    description: '',
    type: '',
    file: null,
    directory_name: 'tutorial',
    thumbnail: '',
    video: '',
  }

  const maxWords = 500
  const {value, onChange, message} = useCharacterLimit('', maxWords)

  React.useEffect(() => {
    setTutorialState({
      ...tutorialState,
      description: value,
    })
  }, [value])

  const [tutorialState, setTutorialState] =
    React.useState<InitialTutorialState>(initialTutorialState)

  React.useEffect(() => {
    setTutorialState({
      ...tutorialState,
      description: props.tutorialInfo.description || '',
      type: props.tutorialInfo.type || '',
    })
  }, [props.tutorialInfo.type])

  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const uploadTutorial = async () => {
    if (props?.tutorialInfo?.type) {
      setLoading(true)

      // check props?.tutorialInfo?.type === tutorialState file type if not same then don't update
      if (props?.tutorialInfo?.type !== tutorialState?.type) {
        setLoading(false)
        toast.error('Your file type is not same as previous file type')
        return
      }

      const getFileUrl = async (file: any) => {
        const pdfType = file?.type === 'application/pdf'
        const imageType = file?.type === 'image/png' || file?.type === 'image/jpeg'
        const videoType = file?.type === 'video/mp4' || file?.type === 'video/webm'

        let fileType = ''
        if (pdfType) {
          fileType = 'pdf'
        } else if (imageType) {
          fileType = 'image'
        } else if (videoType) {
          fileType = 'video'
        } else {
          return toast.error('Invalid file type')
        }

        setError('')
        try {
          var myHeaders = new Headers()
          myHeaders.append('Authorization', `Bearer ${token}`)

          const convertFile = async (file: any) => {
            if (
              file?.type === 'application/pdf' ||
              file?.type === 'image/png' ||
              file?.type === 'image/jpeg' ||
              file?.type === 'image/jpg'
            ) {
              const base64 = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                  const base64 = reader.result?.toString().split(',')[1]
                  resolve(base64)
                }
                reader.onerror = (error) => {
                  setError('something went wrong')
                }
              })
              return base64
            } else {
              return file
            }
          }

          const formData = new FormData()
          formData.append('file', await convertFile(file))
          formData.append('directory_name', tutorialState?.directory_name)
          formData.append('type', fileType)

          const requestOptions: any = {
            method: 'POST',
            body: formData,
            headers: myHeaders,
            redirect: 'follow',
          }

          const response = await fetch(`${APIURL}/admin/upload-file`, requestOptions)
          const result = await response.json()
          if (result?.success) {
            return result?.data?.file_url
          }
        } catch (error) {
          setError('something went wrong')
        }
      }

      try {
        if (!tutorialState?.description) {
          setLoading(false)
          return toast.error('Please enter description')
        }

        if (tutorialState?.file) {
          const res = await getFileUrl(
            tutorialState?.file?.type === 'application/pdf' ||
              tutorialState?.file?.type === 'image/png' ||
              tutorialState?.file?.type === 'image/jpeg' ||
              tutorialState?.file?.type === 'image/jpg' ||
              tutorialState?.file?.type === 'video/mp4' ||
              tutorialState?.file?.type === 'video/webm'
              ? tutorialState?.file
              : tutorialState?.thumbnail
          )

          const myHeaders = new Headers()
          myHeaders.append('Authorization', `Bearer ${token}`)
          myHeaders.append('Content-Type', 'application/json')

          const formdata = new FormData()
          formdata.append('id', props?.tutorialInfo?.id)
          formdata.append('doc_url', res)
          formdata.append('type', tutorialState?.type)
          formdata.append('description', tutorialState?.description)

          // const formData = {
          //   type: tutorialState?.type,
          //   description: tutorialState?.description,
          //   id: props?.tutorialInfo?.id,
          //   doc_url: res,
          // }

          const requestOptions: Request = {
            method: 'PUT',
            body: formdata,
            headers: myHeaders,
            redirect: 'follow',
          }

          if (res) {
            const response = await fetch(`${APIURL}/merchant/update-tutorial`, requestOptions)
            const result = await response.json()

            if (result?.success) {
              toast.success(result?.message)
            }
          }
        } else {
          const myHeaders = new Headers()
          myHeaders.append('Authorization', `Bearer ${token}`)
          myHeaders.append('Content-Type', 'application/json')

          const formData = new FormData()
          formData.append('description', tutorialState?.description)
          formData.append('id', props?.tutorialInfo?.id)
          formData.append('type', tutorialState?.type)

          // const formData = {
          //   type: tutorialState?.type,
          //   description: tutorialState?.description,
          //   id: props?.tutorialInfo?.id,
          // }

          const requestOptions: Request = {
            method: 'PUT',
            body: formData,
            headers: myHeaders,
            redirect: 'follow',
          }

          const response = await fetch(`${APIURL}/merchant/update-tutorial`, requestOptions)
          const result = await response.json()

          if (result?.success) {
            toast.success(result?.message)
          } else {
            toast.error(result?.message)
          }
        }
      } catch (error) {
        setError('something went wrong')
      }

      setLoading(false)
      setTutorialState(initialTutorialState)
      props.onHide()
    } else {
      if (!tutorialState?.file) return toast.error('Please Select a File')
      if (!tutorialState?.description) return toast.error('Please Enter Description')

      setLoading(true)

      const getFileUrl = async (file: any) => {
        const pdfType = file?.type === 'application/pdf'
        const imageType = file?.type === 'image/png' || file?.type === 'image/jpeg'
        const videoType = file?.type === 'video/mp4' || file?.type === 'video/webm'

        let fileType = ''
        if (pdfType) {
          fileType = 'pdf'
        } else if (imageType) {
          fileType = 'image'
        } else if (videoType) {
          fileType = 'video'
        } else {
          return toast.error('Invalid file type')
        }

        setError('')
        try {
          var myHeaders = new Headers()
          myHeaders.append('Authorization', `Bearer ${token}`)

          const convertFile = async (file: any) => {
            if (
              file?.type === 'application/pdf' ||
              file?.type === 'image/png' ||
              file?.type === 'image/jpeg' ||
              file?.type === 'image/jpg'
            ) {
              const base64 = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                  const base64 = reader.result?.toString().split(',')[1]
                  resolve(base64)
                }
                reader.onerror = (error) => {
                  setError('something went wrong')
                }
              })
              return base64
            } else {
              return file
            }
          }

          const formData = new FormData()
          formData.append('file', await convertFile(file))
          formData.append('directory_name', tutorialState?.directory_name)
          formData.append('type', fileType)

          const requestOptions: Request = {
            method: 'POST',
            body: formData,
            headers: myHeaders,
            redirect: 'follow',
          }

          const response = await fetch(`${APIURL}/admin/upload-file`, requestOptions)
          const result = await response.json()
          if (result?.success) {
            return result?.data?.file_url
          }
        } catch (error) {
          setError('something went wrong')
        }
      }

      try {
        if (!tutorialState?.file) {
          setLoading(false)
          return toast.error('Please Select a File')
        }
        if (!tutorialState?.description) {
          setLoading(false)
          return toast.error('Please enter description')
        }

        const res = await getFileUrl(
          tutorialState?.file?.type === 'application/pdf' ||
            tutorialState?.file?.type === 'image/png' ||
            tutorialState?.file?.type === 'image/jpeg' ||
            tutorialState?.file?.type === 'image/jpg' ||
            tutorialState?.file?.type === 'video/mp4' ||
            tutorialState?.file?.type === 'video/webm'
            ? tutorialState?.file
            : tutorialState?.thumbnail
        )

        var myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${token}`)

        const formData = new FormData()
        formData.append('type', tutorialState?.type)
        formData.append('doc_url', res)
        formData.append('description', tutorialState?.description)

        const requestOptions: any = {
          method: 'POST',
          body: formData,
          headers: myHeaders,
          redirect: 'follow',
        }

        if (res) {
          const response = await fetch(`${APIURL}/merchant/save-tutorial`, requestOptions)
          const result = await response.json()

          if (result?.success) {
            toast.success(result?.message)
          } else {
            toast.error(result?.message)
          }
        }
      } catch (error) {
        setError(getErrorMessage(error))
      }

      setLoading(false)
      setTutorialState(initialTutorialState)
      props.onHide()
    }
  }

  return (
    <Modal {...props} size='lg' aria-labelledby='add-tutorial-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='add-tutorial-modal'>
          {props?.tutorialInfo?.type ? 'Edit' : 'Add'} Tutorial
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formFile'>
            <Form.Control
              type='file'
              name='file'
              onChange={(e: any) => {
                setTutorialState({
                  ...tutorialState,
                  file: e.target.files[0],
                  type:
                    e.target.files[0].type === 'application/pdf'
                      ? 'pdf'
                      : e.target.files[0].type === 'image/png' ||
                        e.target.files[0].type === 'image/jpeg'
                      ? 'image'
                      : 'video',
                })
              }}
            />
          </Form.Group>

          {tutorialState?.file?.type === 'image/png' ||
          tutorialState?.file?.type === 'image/jpeg' ||
          tutorialState?.file?.type === 'image/jpg' ? (
            <div className='d-flex justify-content-center mb-2 mt-4 h-100px w-100px'>
              <img
                src={URL.createObjectURL(tutorialState?.file)}
                alt='preview'
                className='img-fluid'
              />
            </div>
          ) : props?.tutorialInfo?.type ? (
            <div className='d-flex justify-content-center overflow-hidden mb-2 mt-4 h-100px w-100px'>
              {props?.tutorialInfo?.type === 'video' ? (
                <FaFileVideo size={100} />
              ) : props?.tutorialInfo?.type === 'image' ? (
                <img
                  src={props?.tutorialInfo?.doc_url}
                  alt='preview'
                  className='img-fluid object-fit-cover'
                />
              ) : (
                <FaFilePdf size={100} />
              )}
            </div>
          ) : (
            <></>
          )}

          <Form.Select
            className='mb-5 mt-4'
            aria-label='Select type of file'
            value={props?.tutorialInfo?.type ? props?.tutorialInfo?.type : tutorialState?.type}
            onChange={(e) => setTutorialState({...tutorialState, type: e.target.value})}
            disabled={true}
          >
            <option>Select type of file</option>
            <option value='video'>Video</option>
            <option value='image'>Image</option>
            <option value='pdf'>PDF</option>
          </Form.Select>

          {/* {
            tutorialState?.type === 'video' && (
              <Form.Group className='mb-5' controlId='rating.description'>
                <Form.Label>Thumbnail</Form.Label>
                <Form.Control
                  type='file'
                  name='thumbnail'
                  onChange={(e: any) => {
                    setTutorialState({
                      ...tutorialState,
                      thumbnail: e.target.files[0],
                    })
                  }}
                />
              </Form.Group>
            )
          } */}

          {/* preview image */}
          {/* {(tutorialState?.thumbnail?.type === 'image/png' ||
            tutorialState?.thumbnail?.type === 'image/jpeg') && (
            <div className='d-flex justify-content-center overflow-hidden mb-2 h-100px w-100px'>
              <img
                src={URL.createObjectURL(tutorialState?.thumbnail)}
                alt='preview'
                className='img-fluid object-fit-cover'
              />
            </div>
          )} */}
          {/* : props?.tutorialInfo?.type === 'video' ? (
            <div className='d-flex justify-content-center overflow-hidden mb-2 h-100px w-100px'>
              {props?.tutorialInfo?.type === 'video' &&
                (props?.tutorialInfo?.screenshot === null ? (
                  <FaFileVideo size={100} />
                ) : (
                  <img
                    src={
                      props?.tutorialInfo?.type === 'video' ? (
                        props?.tutorialInfo?.screenshot
                      ) : (
                        <FaFileVideo size={100} />
                      )
                    }
                    alt=''
                  />
                ))
                // <img
                //   src={
                //     props?.tutorialInfo?.type === 'video' ? (
                //       props?.tutorialInfo?.screenshot === null ? <FaFileVideo size={100} /> : props?.tutorialInfo?.screenshot
                //     ) : (
                //       <FaFileVideo size={100} />
                //     )
                //   }
                //   alt=''
                // />
              }
            </div>
          ) : (
            <></>
          ) */}

          <Form.Group className='mb-5 mt-4 position-relative' controlId='rating.description'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              value={value}
              // onChange={(e) => setTutorialState({...tutorialState, description: e.target.value})}
              onChange={onChange}
              as='textarea'
              rows={3}
            />

            {/* <div
              className='position-absolute'
              style={{
                bottom: '5px',
                right: '20px',
              }}
            >
              <span className='text-muted'>
                {value?.length}/{maxWords}
              </span>
            </div> */}
          </Form.Group>
        </Form>

        <div
          style={{
            marginTop: '-10px',
          }}
          className='d-flex justify-content-between'
        >
          <p className='text-danger'>{message ? message : ''}</p>
          <p
            className='text-muted'
            style={{
              textAlign: 'right',
            }}
          >
            {value?.length}/{maxWords}
          </p>
        </div>

        <div className='d-flex justify-content-center'>
          <Button variant='success' size='lg' disabled={loading} onClick={() => uploadTutorial()}>
            {loading ? (
              <Spinner animation='border' size='sm' />
            ) : props?.tutorialInfo?.type ? (
              'Update'
            ) : (
              'Save'
            )}
          </Button>
        </div>

        {error && (
          <Alert className='mt-4' variant='danger'>
            {error}
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default AddTutorialDialog
