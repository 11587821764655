import * as React from 'react'
import Location from './Location'
import TitleInput from './TitleInput'
import CampaignRun from './CampaignRun'
import TimeSlot from './TimeSlot'
import About from './About'
import ReviewLinks from './ReviewLinks'
import IGHandleBusiness from './IGHandleBusiness'
import CoverPhoto from './CoverPhoto'
import Gallery from './Gallery'
import moment from 'moment'
import Pricing from './Pricing'
import Voucher from './Voucher'
import CreateButton from './CreateButton'
import isEqual from 'react-fast-compare'

export const defaultCampaignState: CampaignState = {
  location_id: '',
  title: '',
  startDate: moment(new Date()).format('yyyy-MM-D'),
  endDate: moment(new Date()).add(1, 'months').format('yyyy-MM-D'),
  about: '',
  businessHandle: [],
  coverPhoto: '',
  gallery: [],
  price: 0,
  reviewLinks: [],
  time_slot: [],
  voucher: '',
  filter: [],
  defaultPrice: 0,
}

const AddCampaign: React.FC = () => {
  const [campaignState, setCampaignState] = React.useState<CampaignState>(defaultCampaignState)
  const [reviewLinks, setReviewLinks] = React.useState<Link[]>([])

  return (
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder text-dark'>Create New Campaign</span>
          </h3>
        </div>

        <div className='card-body'>
          <form className="form">
            <div className="row mb-4">
              <Location campaignState={campaignState} setCampaignState={setCampaignState} />
              <TitleInput campaignState={campaignState} setCampaignState={setCampaignState} />
            </div>

            <CampaignRun campaignState={campaignState} setCampaignState={setCampaignState} />

            <TimeSlot campaignState={campaignState} setCampaignState={setCampaignState} />
            <About campaignState={campaignState} setCampaignState={setCampaignState} />
            <Voucher campaignState={campaignState} setCampaignState={setCampaignState} />

            <div className="row">
              <ReviewLinks
                campaignState={campaignState}
                setCampaignState={setCampaignState}
                reviewLinks={reviewLinks}
                setReviewLinks={setReviewLinks}
              />
              <IGHandleBusiness campaignState={campaignState} setCampaignState={setCampaignState} />
            </div>

            <div className="row mt-6">
              <CoverPhoto campaignState={campaignState} setCampaignState={setCampaignState} />
              <Gallery campaignState={campaignState} setCampaignState={setCampaignState} />
            </div>
            <Pricing campaignState={campaignState} setCampaignState={setCampaignState} />
            <CreateButton
              campaignState={campaignState}
              setCampaignState={setCampaignState}
              setReviewLinks={setReviewLinks}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AddCampaign, isEqual)
