import React, { useEffect, useState } from 'react'
import axios from '../../../_metronic/hooks/axiosPrivate';
import Back from '../../../app/components/common/BackButton';
import { CompletedDataType, InProcessDataType, PaginationInfoType } from './type';
import Pagination from 'replace-js-pagination';
import InfluencerSkeleton from './Influencer/InfluencerSkeleton';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const CompletedCampaign = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<CompletedDataType[]>([] as CompletedDataType[]);
  const [paginationInfo, setPaginagtionInfo] = useState<PaginationInfoType>({
    total: 0,
    currentPage: 1,
    perPage: 0,
    lastPage: 0,
    activePage: 1,
    nextPage: false,
    prevPage: false,
  });

  useEffect(() => {
    setLoading(true);
    axios.get("/merchant/campaigns", { params: { search_type: 'completed' } })
      .then((res) => {
        console.log(res)
        if (res?.status === 200 && res?.data?.success) {
          const result = res?.data;
          setPaginagtionInfo((prevState) => ({
            ...prevState,
            activePage: result?.paginate?.current_page,
            currentPage: result?.paginate?.current_page,
            total: result?.paginate?.total,
            perPage: result?.paginate?.per_page,
            lastPage: result?.paginate?.last_page,
          }));

          if (result?.paginate?.next_url === null) {
            setPaginagtionInfo((prevState) => ({
              ...prevState,
              nextPage: false,
            }));
          } else {
            setPaginagtionInfo((prevState) => ({
              ...prevState,
              nextPage: true,
            }));
          }

          if (result?.paginate?.prev_url === null) {
            setPaginagtionInfo((prevState) => ({
              ...prevState,
              prevPage: false,
            }));
          } else {
            setPaginagtionInfo((prevState) => ({
              ...prevState,
              prevPage: true,
            }));
          }

          setCampaigns(result?.data);
        }
      }).catch((err) => {

      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const handlePageChange = () => {
    if (paginationInfo?.nextPage) {
      setPaginagtionInfo((prevState) => ({
        ...prevState,
        currentPage: prevState?.currentPage + 1,
      }));
    }

    if (paginationInfo?.prevPage) {
      setPaginagtionInfo((prevState) => ({
        ...prevState,
        currentPage: prevState?.currentPage - 1,
      }));
    }
  };

  return loading ? (<>...loading</>) : (
    <>
      <div className="d-flex mb-5 gap-3 align-items-center">
        <Back /> <h2 className="mb-0">Completed Campaigns</h2>
      </div>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card'>
            <div className='card-body pt-0'>
              <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    id='kt_subscriptions_table'
                  >
                    <thead>
                      <tr className='text-start text-dark fw-bolder fs-7 text-capitalize gs-0'>
                        <th className='min-w-125px sorting'>Image</th>
                        <th className='min-w-125px sorting'>Campaign Name</th>
                        {/* <th className='min-w-125px sorting'>Campaign Type</th> */}
                        <th className='min-w-125px sorting'>Location</th>
                        <th className='min-w-125px  sorting_disabled text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                      {loading ? (
                        <>
                          {new Array(3).fill(0).map((_, index) => (
                            <InfluencerSkeleton key={index} />
                          ))}
                        </>
                      ) : campaigns?.length === 0 ? (
                        <tr>
                          <td colSpan={6} className='text-center'>
                            No Data Found
                          </td>
                        </tr>
                      ) : (
                        <>
                          {campaigns?.map((campaign: InProcessDataType, index: any) => (
                            <tr className='odd align-middle' key={`${campaign?.id}.${index}`}>
                              <td>
                                <picture>
                                  <img
                                    src={campaign?.thumb_image}
                                    alt={campaign?.name}
                                    style={{
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      borderRadius: '10px',
                                    }}
                                  />
                                </picture>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-center flex-column'>
                                    {campaign?.name}
                                  </div>
                                </div>
                              </td>
                              <td>{campaign?.location?.location_name}</td>
                              {/* <td>{campaign?.booking_date} - {campaign?.booking_start_time}</td> */}
                              <td>
                                <div className='w-100 d-flex justify-content-center gap-1'>
                                <NavLink
                                  to={`/campaign/${campaign?.id}/invitation`}
                                  className='btn btn-primary btn-active-light-primary btn-sm'
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                  title='View'
                                >
                                  View
                                </NavLink>
                                </div>
                                {/* <Button
                                  variant='light'
                                  className='btn btn-light btn-active-light-primary btn-sm'
                                  onClick={() => { }}
                                  title="View"
                                >
                                  View
                                </Button> */}
                                {/* <Button
                                  variant='light'
                                  className='btn btn-light btn-active-light-primary btn-sm'
                                  onClick={() => { }}
                                  title="No-Show"
                                >
                                  No-Show
                                </Button> */}
                              </td>
                            </tr>
                          ))}
                          {/* {['3', '4'].includes(currentUser?.user_type as string) && ()} */}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='col-sm-12'></div>
              </div>
            </div>

            <div className='card-footer p-4 mb-2 bg-secondary'>
              <div className='d-flex flex-stack flex-wrap'>
                <div className='fs-6 fw-bold text-gray-700'>
                  Showing {paginationInfo?.currentPage} to {paginationInfo?.perPage} of {paginationInfo?.total} entries
                </div>

                <div className='pagination'>
                  <Pagination
                    hideFirstLastPages={false}
                    hideNavigation={true}
                    linkClassFirst='next'
                    linkClassPrev='previous'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={paginationInfo?.activePage}
                    itemsCountPerPage={paginationInfo?.perPage}
                    totalItemsCount={paginationInfo?.total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedCampaign;