import * as React from 'react'

const useDetectMode = () => {
  const [mode, setMode] = React.useState<'light' | 'dark' | 'system'>('system')

  React.useEffect(() => {
    const mode = localStorage.getItem('kt_theme_mode_value')
    if (mode) {
      setMode(mode as 'light' | 'dark' | 'system')
    }

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

   

    const handleChange = () => {
      setMode(mediaQuery.matches ? 'dark' : 'light')
    }

    mediaQuery.addEventListener('change', handleChange)

    return () => mediaQuery.removeEventListener('change', handleChange)
  }, [])

  return mode
}

export default useDetectMode
