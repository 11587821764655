/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useMemo } from 'react'
import { moneyFormatter } from '../../../utils/MoneyFormatter'
import isEqual from 'react-fast-compare'

interface IFilterItems {
  filterOptions: filterTypeItem[]
  name: string
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
  removeCheckedAll: React.Dispatch<React.SetStateAction<CheckedAllType>>;
  checkedAll: CheckedAllType,
}

const FilterItems = ({ filterOptions, name, campaignState, setCampaignState, removeCheckedAll, checkedAll }: IFilterItems) => {

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>, option: filterTypeItem, name: string) => {
    console.log('CHECKALL', checkedAll);
    removeCheckedAll({
      ...checkedAll,
      [name]: false,
    });
    const newOption = {
      ...option,
      itemName: name,
    };

    if (e.target.checked) {
      if (campaignState?.filter?.length) {
        setCampaignState({
          ...campaignState,
          filter: [...campaignState?.filter, newOption],
        });
      } else {
        setCampaignState({
          ...campaignState,
          filter: [newOption],
        });
      }
    } else {
      const filterItems = campaignState?.filter?.filter((filter: filterTypeItem) => {
        console.log('FILTERS OBJECT', filter, option)
        return filter?.type_id !== option?.type_id || filter?.id !== option?.id
      }
      );

      setCampaignState({
        ...campaignState,
        filter: filterItems,
      });
    }
  };

  const handleOnlyChange = (e: React.MouseEvent<HTMLElement>, option: filterTypeItem, name: string) => {
    console.log('CHECKALL', checkedAll);
    removeCheckedAll({
      ...checkedAll,
      [name]: false,
    });

    const newOption = {
      ...option,
      itemName: name,
    };

    const filterItems = campaignState?.filter?.filter((filter: filterTypeItem) => {
      return filter?.type_id !== option?.type_id;
    }
    );

    setCampaignState({
      ...campaignState,
      filter: [...filterItems, newOption],
    });
  };

  const hasFilterOptions = useMemo(() => {
    return campaignState?.filter?.length
  }, [campaignState]);

  useEffect(() => {
    console.log("campaignState", campaignState?.filter);

    if (hasFilterOptions) {
      const updatedPrice = campaignState?.filter?.reduce((acc: number, filter: filterTypeItem) => {
        const price = filter.price ? +filter.price : 0
        return acc + price
      }, 0);

      setCampaignState({
        ...campaignState,
        price: updatedPrice + +campaignState?.defaultPrice,
      });
    } else {
      setCampaignState({
        ...campaignState,
        price: +campaignState?.defaultPrice,
      });
    }
  }, [hasFilterOptions]);

  return (
    <div className="col-lg-8 col-md-8 col-sm-12">
      {filterOptions.map((filter: filterTypeItem, idx: number) => (
        <FilterItem
          key={idx}
          filterOption={filter}
          name={name}
          defaultChecked={campaignState?.filter.find((cFilter) => cFilter?.id === filter?.id)}
          handleFilterChange={handleFilterChange}
          handleOnlyChange={handleOnlyChange}
        />
      ))}
    </div>
  );
}

export default memo(FilterItems, isEqual)

interface IFilterItem {
  filterOption: filterTypeItem;
  name: string;
  defaultChecked?: filterTypeItem;
  handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>, option: filterTypeItem, name: string) => void;
  handleOnlyChange: (e: React.MouseEvent<HTMLElement>, option: filterTypeItem, name: string) => void;
}

const FilterItem = ({ filterOption, name, defaultChecked, handleFilterChange, handleOnlyChange }: IFilterItem) => {
  console.log(defaultChecked);
  return (
    <div className={`wrap-filters d-flex align-items-start mb-4 ${filterOption?.name === "Any no. of followers" ||
      filterOption?.name === "Any Gender (Default)" ||
      filterOption?.name === "Age NO Bar (Default)" ||
      filterOption?.name === "Any Influencer (Default)" ||
      filterOption?.name === "Any Location (Default)" ? `d-none` : ``}`}>
      <div>
        <input
          className="form-check-input me-2 bg-auto-size"
          type="checkbox"
          name={name}
          value={filterOption?.id}
          // defaultChecked={defaultChecked?.id === filterOption?.id}
          checked={defaultChecked?.id === filterOption?.id}
          onChange={(e) => handleFilterChange(e, filterOption, name)}
          id={`${filterOption?.name}_${filterOption?.id}`}
        />
        <label
          htmlFor={`${filterOption?.name}_${filterOption?.id}`}
          className="form-check-label fs-6 fw-normal text-gray-800 cursor-pointer"
        >
          {filterOption.name} {filterOption.price && `USD - ${moneyFormatter(+filterOption.price)}`}
        </label>
      </div>
      <label
        className="show-only text-primary cursor-pointer d-none ms-auto"
        onClick={(e) => handleOnlyChange(e, filterOption, name)}
      >
        Only
      </label>
    </div>
  );
}
