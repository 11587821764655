import React, {memo} from 'react'
import classes from './CoverPhoto.module.scss'
import {toast} from 'react-toastify'
import isEqual from 'react-fast-compare'

interface ICoverPhoto {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
}

const CoverPhoto = ({campaignState, setCampaignState}: ICoverPhoto) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // detect file type
    const fileType = e.target.files && e.target.files[0].type

    if (
      fileType !== 'image/png' &&
      fileType !== 'image/jpg' &&
      fileType !== 'image/jpeg' &&
      fileType !== 'image/svg+xml' &&
      fileType !== 'image/gif'
    ) {
      toast.error('Only PNG, JPG, JPEG, SVG or GIF file are allowed')
    }

    const file = e.target.files && e.target.files[0]
    const reader = new FileReader()
    if (!file) return
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setCampaignState((pre) => ({
        ...pre,
        coverPhoto: reader.result as string,
      }))
    }
  }

  return (
    <div className='col-sm-12 col-md-6'>
      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
        <span className='required'>Upload Cover Photo</span>
      </label>

      <div className={classes.wrapper}>
        <div className={classes.imageContainer}>
          {campaignState.coverPhoto ? (
            <img src={campaignState.coverPhoto} className={classes.image} alt='cover' />
          ) : (
            <img
              src='/media/campaign/placeholder_image.svg'
              className={classes.image}
              alt='cover'
            />
          )}
        </div>

        <div className={classes.upload}>
          <label htmlFor='upload-photo' className={classes.uploadLabel}>
            <span className={classes.label}>Upload</span> Campaign Cover Photo
          </label>

          <input
            type='file'
            name='photo'
            id='upload-photo'
            className={classes.uploadInput}
            accept='image/*'
            hidden
            onChange={handleChange}
          />

          <p className={classes.uploadText}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
        </div>
      </div>
    </div>
  )
}

export default memo(CoverPhoto, isEqual)
