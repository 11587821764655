const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_URL = process.env.REACT_APP_API_URL;
const MAPKEY = process.env.MAPKEY;
const CLIENT_ID = process.env.CLIENT_ID;

// console.log('CLIENT_ID', CLIENT_ID);

const APIURL = API_URL;
const baseUrl = BASE_URL;
export { baseUrl, APIURL, MAPKEY, CLIENT_ID };

