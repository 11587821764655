import React from 'react'
import {useSearchParams} from 'react-router-dom'

import styles from './Information.module.scss'
import {BusinessData} from '../../../../../_metronic/assets/ts/components/_auth'

interface ItemProps {
  title: string
  value: string|null
}

const Item: React.FC<ItemProps> = ({title, value}) => {
  return (
    <div>
      <p className='text-muted '>{title}</p>
      <p>{value ? value : 'No'}</p>
    </div>
  )
}

interface PreviewInformationProps {
  values: BusinessData
  viewData: {
    business_type: string
    business_category_id: string
    business_sub_category_id: string
    no_of_employee: string
    annual_revenue: string
    price: string
  }
}

const PreviewInformation: React.FC<PreviewInformationProps> = ({values, viewData}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <>
      <hr />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Business Information</h1>

        <div className={styles.layout_grid}>
          <Item title='First Name' value={values.f_name} />
          <Item title='Last Name' value={values.l_name} />
        </div>
        <div className={styles.layout_grid}>
          <Item title='Name of Business' value={values.business_name} />
          <Item title='Annual Business Revenue' value={viewData.annual_revenue} />
        </div>
        <div className={styles.layout_grid}>
          <Item title='Telephone Number' value={values.phone} />
          <Item title='Email' value={searchParams.get('email')} />
        </div>

        {values.representatives.length &&
          values.representatives.map((item) => (
            <div className={styles.layout_grid} key={item?.r_email}>
              <Item title='Location' value={item?.business_location} />
              <Item title='Representative' value={`${item?.r_first_name} ${item?.r_last_name}`} />
            </div>
          ))}

        <div className={styles.layout_grid}>
          <Item title='Business Type' value={viewData.business_type} />
        </div>

        <div className={styles.layout_grid}>
          <Item title='Category' value={viewData.business_category_id} />
          <Item title='Sub Category' value={viewData.business_sub_category_id} />
        </div>

        <div className={styles.layout_grid}>
          <Item title='Number Of Employees' value={viewData.no_of_employee} />
          <Item title='Price' value={viewData.price} />
        </div>
      </div>
      <hr />
    </>
  )
}

export default PreviewInformation
