/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import { APIURL } from '../../../../config/config';
import { moneyFormatter } from '../../../utils/MoneyFormatter';
import { parseCookies } from 'nookies'
import FilterItems from './FilterItems';
import isEqual from 'react-fast-compare';

interface IPricing {
  campaignState: CampaignState
  setCampaignState: React.Dispatch<React.SetStateAction<CampaignState>>
};

const Pricing = ({ campaignState, setCampaignState }: IPricing) => {

  const cookies = parseCookies()
  const token = cookies?.token

  const [myAccount, setMyAccount] = useState<any>([])
  const [defaultPrice, setDefaultPrice] = useState<number>(0.00);
  const [filterTypes, setFilterTypes] = React.useState<filterType[]>([]);
  const [checkedAll, setCheckedAll] = React.useState<CheckedAllType>({
    no_of_followers: false,
    type_of_influencer: false,
    age: false,
    gender: false,
    location: false,
  });


  const getMyAccount = async () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/me`, requestOptions)
    const result = await response.json()
    if (result.success === true) {
      setMyAccount(result?.data)
      if (result?.data?.user_type === "4") {
        setDefaultPrice(result?.data?.rep_business_tier?.tier_discount_amount ?? result?.data?.rep_business_tier?.tier_amount);
      } else {
        setDefaultPrice(result?.data?.business_tier?.tier_discount_amount ?? result?.data?.business_tier?.tier_amount);
      }
    }
  }


  useEffect(() => {
    getMyAccount()
    const getFilterOptions = async () => {
      try {
        const { data } = await axios.get(`${APIURL}/admin/filter-type`)
        if (data?.success) {
          const filters = data.data.map((filter: filterType) => ({
            id: filter?.id,
            items: filter?.items,
            name: filter?.name?.toLocaleLowerCase()?.replaceAll(" ", "_").replace(".", ""),
          }));
          setFilterTypes(filters);
        }
      } catch (error) {
        console.log('🚀 ~ file: Pricing.tsx:21 ~ getFilterOptions ~ error:', error)
      }
    }
    getFilterOptions();
  }, []);

  useEffect(() => {
    const defaltFilter = filterTypes.map((filter: filterType) => filter.items[0]);
    setCampaignState({
      ...campaignState,
      filter: defaltFilter,
    });
  }, [filterTypes])

  const handleAllFilterChecked = (e: React.ChangeEvent<HTMLInputElement>, items: filterTypeItem[], name: string) => {
    if (e.target.checked) {
      setCheckedAll((prevChecked) => ({
        ...prevChecked,
        [name]: true,
      }));

      setCampaignState({
        ...campaignState,
        filter: [...campaignState?.filter, ...items],
      });
    } else {
      const filterItem = campaignState?.filter.filter((filterItem) => {
        return filterItem?.type_id !== items[0].type_id;
      });

      setCheckedAll((prevChecked) => ({
        ...prevChecked,
        [name]: false,
      }));
      setCampaignState({
        ...campaignState,
        filter: [...filterItem],
      });
    }
  };

  return (
    <div className='col-md-6 col-sm-12 mt-4'>
      <div className='col-sm-12 col-md-12'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span>Influencer Filter</span>
        </label>
        <p className='d-flex align-items-center mb-2'>*Subject to Change</p>
        <div className='accordion' id='accordionExample'>
          <div className='accordion-item'>
            <div className='accordion-header'>
              <div
                className='accordion-button text-white'
                data-bs-toggle='collapse'
                data-bs-target='#collapseOne'
                aria-expanded='false'
                aria-controls='collapseOne'
              >
                <div className='flex-grow-1  w-100'>
                  <p className='mb-0 fs-6 fw-semibold text-gray-800'>Pricing / Month</p>

                  <h3 style={{ fontSize: '18px' }}>                    
                    USD {defaultPrice}
                    {/* business_tier	Object { tier_name: "Tier Two", tier_amount: "990.00", tier_discount_amount: null } */}
                    {/* {moneyFormatter(+campaignState?.price)}{' '}
                    {campaignState?.filter?.length === 0 ? '(Default)' : '(Filtered)'} */}
                  </h3>
                </div>
              </div>
            </div>

            <div
              id='collapseOne'
              className='accordion-collapse collapse'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                {filterTypes.map(({ items, name }: filterType, idx: number) => {
                  console.log('CHECKALL ITEMS', name, checkedAll[name as keyof CheckedAllType], checkedAll[name as keyof typeof checkedAll]);
                  console.log('items', items);

                  return (
                    <div className='mb-10' key={idx}>
                        <h2 className='mb-4'>{name.replaceAll("_", " ").charAt(0).toLocaleUpperCase() + name.replaceAll("_", " ")?.slice(1)}</h2>
                      <div className="d-flex justify-content-between">
                        <h4 className='mb-4'>{items[0].name}</h4>
                        <div className="form-check form-switch cursor-pointer">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAllFilterChecked(e, items, name)}
                            checked={checkedAll[name as keyof CheckedAllType]}
                          />
                        </div>
                      </div>
                      <FilterItems
                        filterOptions={items}
                        name={name}
                        campaignState={campaignState}
                        setCampaignState={setCampaignState}
                        removeCheckedAll={setCheckedAll}
                        checkedAll={checkedAll}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Pricing, isEqual)
