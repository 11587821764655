import React, { useEffect, useState } from 'react'
import Back from '../../../app/components/common/BackButton';
import moment from 'moment';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const CampaignTimeSlot = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [timeSlots, setTimeSlots] = useState();
  const [date, setDate] = useState();
  const [campaignId, setCampaignId] = useState();

  const [loading] = useState<boolean>(false);

  useEffect(() => {
    if (location) {
      // @ts-ignore
      setTimeSlots(location?.state?.time_slots);
      // @ts-ignore            
      setDate(location?.state?.date);
      // @ts-ignore            
      setCampaignId(location?.state?.campaign_id);
    }
  }, [location]);

  return loading ? (<>...loading</>) : (
    <>
      <div className="d-flex mb-5 gap-3 align-items-center">
        <Back /> <h2 className="mb-0">Campaigns Time Slot</h2>
      </div>
      <div>{date && moment(date).format('ddd, Do MMMM, YYYY')}</div>

      {
        //@ts-ignore
        timeSlots && Array.isArray(timeSlots) && timeSlots.length > 0 ? timeSlots.map((time) => {
          const times = time?.time.split(" - ");
          console.log('TIMES', times[0], times[1]);
          return (
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10 justify-content-center'>
              <div className='col-sm-3'>
                <Link
                  to={`/campaign/in-process/${campaignId}/${moment(date).format("YYYY-MM-DD")}/${encodeURIComponent(time?.time)}`}
                  className="w-100 d-flex flex-row gap-6 justify-content-center py-6 align-items-center btn bg-black">
                  <span style={{ color: "#98A2B3", fontSize: '1.1rem' }}>
                    {moment(times[0], "HH:mm").format('HH:mm')} - {moment(times[1], "HH:mm").format('HH:mm')}
                  </span>
                </Link>
              </div>
            </div>
          );
        }) : <></>
      }
    </>
  );
};

export default CampaignTimeSlot;