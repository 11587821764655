/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {FaEyeSlash, FaEye} from 'react-icons/fa'

import {useAuth} from '../core/Auth'
import {FaImage} from 'react-icons/fa'

const API_URL = process.env.REACT_APP_API_URL

const initialValues = {
  f_name: '',
  l_name: '',
  email: '',
  phone: '',
  password: '',
  profile_photo: '',
}

const registrationSchema = Yup.object().shape({
  f_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  l_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  phone: Yup.number().required('Phone number is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

export function Registration() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(false)
  const [profilePhoto, setProfilePhoto] = useState('')
  const [profileImg, setProfileImg] = useState('')
  const [profileErr, setProfileErr] = useState(false)

  const [showPassword, setShowPassword] = useState(false)

  const {saveAuth, setCurrentUser} = useAuth()

  function getBase64(name: string, file: any, file_name: string) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      let fileType = file.type
      if (fileType.match(/image/g) == 'image') {
        let databs64 = `data:${fileType};base64,`

        //@ts-ignore
        let imageFile = reader.result.replace(String(databs64), '')

        //@ts-ignore
        setProfileImg(reader.result)

        setProfilePhoto(imageFile)
        setImgLoading(false)
      } else {
        toast.error('Please select image file')
        setImgLoading(false)
      }
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const uploadFile = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    setImgLoading(true)
    getBase64(e.target.name, e.target.files[0], e.target.files[0].name)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      console.log("Hello Register");
      toast.success('Hello');
      if (profilePhoto == '') {
        setProfileErr(true)
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      } else {
        const register = await fetch(`${API_URL}/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            f_name: values.f_name,
            l_name: values.l_name,
            email: values.email,
            phone: values.phone,
            password: values.password,
            profile_photo: profilePhoto,
            user_type: 3,
          }),
        })
        const result = await register.json()
        console.log('result :- ', result)

        if (register.status === 200) {
          if (result.success === true) {
            toast.success(result.message)
            setTimeout(() => {
              navigate('/login')
            }, 1500)
          }
        } else if (register.status === 415) {
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
          // console.log("message", result);
          if (result?.message?.email?.length > 0) {
            setStatus(result?.message?.email[0])
          }

          if (result?.message?.f_name?.length > 0) {
            setStatus(result?.message?.f_name[0])
          }

          if (result?.message?.l_name?.length > 0) {
            setStatus(result?.message?.l_name[0])
          }

          if (result?.message?.password?.length > 0) {
            setStatus(result?.message?.password[0])
          }

          if (result?.message?.phone?.length > 0) {
            setStatus(result?.message?.phone[0])
          }
          setStatus(result?.message);
        }
      }
      // try {
      //   const { data: auth } = await register(
      //     values.email,
      //     values.f_name,
      //     values.l_name,
      //     values.password
      //   )
      //   saveAuth(auth)
      //   const { data: user } = await getUserByToken(auth.api_token)
      //   setCurrentUser(user)
      // } catch (error) {
      //   console.error(error)
      //   saveAuth(undefined)
      //   setStatus('The registration details is incorrect')
      //   setSubmitting(false)
      //   setLoading(false)
      // }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      <ToastContainer />
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Register In to Your Account</h1>
          {/* end::Title */}

          <div className='text-gray-500 fw-semibold fs-6'>Welcome to Elite list</div>
        </div>
        {/* end::Heading */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        <div className='row'>
          {profileErr ? (
            <>
              <div className='col-sm-12 mt-2 mb-2 d-flex justify-content-center'>
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>Profile image is required</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className='col-sm-12 mb-5 d-flex justify-content-center'>
            <label
              className='text-center'
              style={{
                width: '140px',
                height: '140px',
                cursor: 'pointer',
                borderStyle: 'dotted',
                overflow: 'hidden',
              }}
            >
              {imgLoading ? (
                <>
                  <div
                    className='d-flex justify-content-center img-fluid form-control'
                    style={{width: '140px', height: '140px'}}
                  >
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {profileImg == '' ? (
                    <>
                      <FaImage
                        style={{
                          width: '80px',
                          height: '80px',
                          marginTop: '0.5rem',
                        }}
                      />
                      <br />
                      <span
                        style={{
                          width: '100%',
                          marginTop: '0.25rem',
                          fontSize: '0.875rem',
                          lineHeight: '1.25rem',
                        }}
                      >
                        Upload profile image
                      </span>
                      <input
                        style={{visibility: 'hidden'}}
                        type='file'
                        onChange={(e) => uploadFile(e)}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className='d-flex justify-content-center'
                        style={{
                          width: '140px',
                          height: '140px',
                          overflow: 'hidden',
                        }}
                      >
                        <img
                          src={profileImg}
                          alt=''
                          style={{
                            width: '140px',
                            height: '140px',
                          }}
                        />
                        <input
                          style={{visibility: 'hidden'}}
                          type='file'
                          onChange={(e) => uploadFile(e)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </label>
          </div>

          <div className='col-sm-6 mt-2'>
            {/* begin::Form group Firstname */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>First name</label>
              <input
                placeholder='First name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('f_name')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.f_name && formik.errors.f_name,
                  },
                  {
                    'is-valid': formik.touched.l_name && !formik.errors.l_name,
                  }
                )}
              />
              {formik.touched.f_name && formik.errors.f_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.f_name}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>
          <div className='col-sm-6  mt-2'>
            {/* begin::Form group Lastname */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
              <input
                placeholder='Last name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('l_name')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.l_name && formik.errors.l_name,
                  },
                  {
                    'is-valid': formik.touched.l_name && !formik.errors.l_name,
                  }
                )}
              />
              {formik.touched.l_name && formik.errors.l_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.l_name}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>
        </div>

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Phone */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Phone</label>
          <input
            placeholder='+1 xxxx xxxx xxx'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('phone')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.phone && formik.errors.phone},
              {
                'is-valid': formik.touched.phone && !formik.errors.phone,
              }
            )}
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phone}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <div className='position-relative mb-3'>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />

              <div
                className='position-absolute'
                style={{
                  right: '2.8rem',
                  top: '1rem',
                }}
              >
                {showPassword ? (
                  <FaEye
                    onClick={() => setShowPassword(!showPassword)}
                    className='cursor-pointer'
                    size={20}
                  />
                ) : (
                  <FaEyeSlash
                    onClick={() => setShowPassword(!showPassword)}
                    className='cursor-pointer'
                    size={20}
                  />
                )}
              </div>

              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Cancel
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
