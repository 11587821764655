/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'

import cookie from 'js-cookie'
import { parseCookies } from 'nookies'

import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { FaEyeSlash, FaEye } from 'react-icons/fa'
import axios from '../../../../_metronic/hooks/axios'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from 'react-google-button'

const API_URL = process.env.REACT_APP_API_URL

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

export function Login() {
  const cookies = parseCookies()
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()


  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState<string | null>(localStorage.getItem('app-email') || '')
  const [password, setPassword] = useState<string | null>(localStorage.getItem('app-password') || '')

  const initialValues = {
    email: email,
    password: password,
    remember: false,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      console.log('VALUES', values);
      if (values.remember) {
        //@ts-ignore
        localStorage.setItem('app-email', values.email)
        // @ts-ignore
        // localStorage.setItem('app-password', values.password)
      } else {
        localStorage.setItem('app-email', '');
      }

      axios.post('login', {
        email: values.email,
        password: values.password,
        remeber: values?.remember,
        user_type: 3,
      }).then((res) => {
        console.log('SUCCESS', res);
        if (res?.status === 200) {
          if (res?.data?.success && res?.data?.data?.user?.status == 1) {
            const userAuth = {
              api_token: res?.data?.token,
              refreshToken: res?.data?.token,
            }

            const result = res?.data?.data;

            const userData: any = {
              first_name: result?.user?.f_name,
              last_name: result?.user?.l_name,
              fullname: `${result?.user?.f_name} ${result?.user?.l_name}`,
              email: result?.user?.email,
              phone: result?.user?.phone,
              user_type: result?.user?.user_type,
              status: result?.user?.status,
            }

            console.log('userData', userData);

            cookie.set('token', res?.data?.token);
            cookie.set('user', JSON.stringify(userData));
            cookie.set('userAuth', JSON.stringify(userAuth));

            saveAuth(userAuth)
            setCurrentUser(userData)
            setLoading(false);
          } else {
            setLoading(false)
            navigate('/pending');
          }
        }
      })
        .catch(err => {
          const { status, data } = err.response;
          if (status === 415) {
            const { success, message } = data;
            const { email, password } = message;
            if (!success) {
              if (!Array.isArray(data?.message)) {
                setStatus(data?.message);
              }

              if (email?.length > 0) {
                setStatus(email[0]);
              }

              if (password?.length > 0) {
                setStatus(password[0]);
              }
            }
          } else {
            if (!Array.isArray(data?.message)) {
              setStatus(data?.message);
            }
          }
          setSubmitting(false)
          setLoading(false)
        });
    },
  });

  const SocialLoginHandler = async (token: string, userInfo: any) => {
    try {
      let data = JSON.stringify({
        social_type: "google",
        f_name: userInfo?.given_name,
        l_name: userInfo?.family_name,
        email: userInfo?.email,
        phone: "",
        access_token: token,
      });

      const option = {
        method: 'post',
        url: '/social-login-merchant',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      //@ts-ignore
      const socialLogin = await axios(option);
      console.log('socialLogin', socialLogin);
      if (socialLogin?.status == 200) {
        toast.success("User login successfully!");
        if (socialLogin?.data?.is_old_user) {
          if (socialLogin?.data?.success && socialLogin?.data?.data?.user?.status == 1) {
            const userAuth = {
              api_token: socialLogin?.data?.token,
              refreshToken: socialLogin?.data?.token,
            }

            const result = socialLogin?.data?.data;

            const userData: any = {
              first_name: result?.user?.f_name,
              last_name: result?.user?.l_name,
              fullname: `${result?.user?.f_name} ${result?.user?.l_name}`,
              email: result?.user?.email,
              phone: result?.user?.phone,
              user_type: result?.user?.user_type,
              status: result?.user?.status,
            }

            // console.log('userData', userData);
            cookie.set('token', socialLogin?.data?.token);
            cookie.set('user', JSON.stringify(userData));
            cookie.set('userAuth', JSON.stringify(userAuth));

            saveAuth(userAuth)
            setCurrentUser(userData)
            setLoading(false);
          } else {
            setLoading(false)
            navigate('/pending');
          }
        } else {
          cookie.set('reg-token', socialLogin?.data?.token);
          setTimeout(() => {
            navigate(`/profile-update?email=${userInfo?.email}`);
          }, 1500);
        }
      } else {
        toast.error("User was not successfully logged in!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const loginHandler = useGoogleLogin({
    onSuccess: async tokenResponse => {
      // console.log('userInfo token', tokenResponse);
      try {
        const option = {
          method: 'get',
          url: 'https://www.googleapis.com/oauth2/v3/userinfo',
          headers: { Authorization: `${tokenResponse.token_type} ${tokenResponse.access_token}` },
        };
        //@ts-ignore
        const userInfo = await axios(option);
        // console.log('userInfo', userInfo);
        SocialLoginHandler(tokenResponse.access_token, userInfo?.data);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Login In to Your Account</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Welcome to Elite list</div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            // {
            //   'is-valid': formik.touched.email && !formik.errors.email,
            // }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3 position-relative'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            // {
            //   'is-valid': formik.touched.password && !formik.errors.password,
            // }
          )}
        />

        <div
          className='position-absolute'
          style={{
            right: '1rem',
            top: '2.5rem',
          }}
        >
          {showPassword ? (
            <FaEye
              onClick={() => setShowPassword(!showPassword)}
              className='cursor-pointer'
              size={20}
            />
          ) : (
            <FaEyeSlash
              onClick={() => setShowPassword(!showPassword)}
              className='cursor-pointer'
              size={20}
            />
          )}
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div className='checkbox'>
          <label>
            {/* <Field type="checkbox" name="remember" /> */}
            <input {...formik.getFieldProps('remember')} type='checkbox' name='remember' /> Remember me
          </label>
        </div>

        {/* begin::Link */}
        <Link to='/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}


      <p className='text-center'>OR Continue with</p>

      <GoogleButton
        onClick={() => loginHandler()}
        label='Login with Google'
        className='btn-primary w-100 mb-5 d-flex justify-content-center rounded gap-4'
      />


      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Don't have an account?{' '}
        <Link to='/registration' className='link-primary'>
          Register
        </Link>
      </div>
    </form>
  )
}