/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useAuth } from '../../../../app/modules/auth'
import { useEffect, useState } from 'react'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const { config } = useLayout()
  const { currentUser, logout } = useAuth()
  const [notificationCount, setNotificationsCount] = useState<number>(0)
  const [userDetails, setUserDetails] = useState<any>({})

  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        const user = JSON.parse(localStorage.getItem('user') || '{}')
        setUserDetails(user)
      }
    } catch (error) {
      console.log('🚀 ~ file: HeaderUserMenu.tsx:20 ~ useEffect ~ error', error)
    }
  }, [currentUser?.id])

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={`${btnClass} position-relative`}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />

          {notificationCount > 0 && (
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle bottom-0 start-25 animation-blink' />
          )}
        </div>
        <HeaderNotificationsMenu setNotificationsCount={setNotificationsCount} />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {userDetails?.profile_photo ? (<img src={userDetails?.profile_photo} alt={userDetails?.f_name} />) : (<img src="/assets/media/avatars/blank.png" alt={userDetails?.f_name} />)}
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export { Navbar }
