/* eslint-disable react-hooks/exhaustive-deps */
import {parseCookies} from 'nookies'
import * as React from 'react'
import {APIURL} from '../../../../config/config'
import {Table} from 'react-bootstrap'
import InfluencerSkeleton from '../Influencer/InfluencerSkeleton'
import SuspenseBtn from './SuspenseBtn'
import UnSuspenseBtn from './UnSuspenseBtn'

const SuspenseInfluencer = () => {
  const cookies = parseCookies()
  const token = cookies?.token

  const [influencerList, setInfluencerList] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(true)

  const fetchInfluencer = async () => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/suspension-influencer-list`, requestOptions)
    const result = await response.json()
    if (result.success === true) {
      setInfluencerList(result?.data)
      setLoading(false)
    }

    setLoading(false)
  }

  React.useEffect(() => {
    fetchInfluencer()
  }, [])

  return (
    <>
      <div
        className='row g-5 g-xl-10 mb-xl-10'
        style={{
          marginTop: '-60px',
        }}
      >
        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 bg-success py-5'>
              <h3 className='card-title fw-bolder text-white'>Influencer List</h3>
            </div>
            <div className='card-body p-2'>
              <div className='row mb-4'>
                <div className='col-sm-12'>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className='fs-4 text-center'>Profile Photo</th>
                        <th className='fs-4 text-center'>Name</th>
                        <th className='fs-4 text-center'>Email</th>
                        <th className='fs-4 text-center'>Phone</th>
                        <th className='fs-4 text-center'>Address</th>
                        <th className='fs-4 text-center'>Status</th>
                        <th className='fs-4 text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <>
                          {new Array(10).fill(0).map((_, index) => (
                            <InfluencerSkeleton column={5} key={index} />
                          ))}
                        </>
                      )}

                      {influencerList?.length === 0 && (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            No Data Found
                          </td>
                        </tr>
                      )}

                      {!loading &&
                        influencerList &&
                        influencerList.map((item: any, index: number) => (
                          <tr key={`${item.influencer_id}.${index}`} className='align-middle'>
                            <td className='text-center'>
                              <picture className='symbol symbol-45px me-5'>
                                <img
                                  src={item?.influencer_photo_url}
                                  alt={item?.influencer_name}
                                  style={{width: '80px', height: '80px', objectFit: 'cover'}}
                                />
                              </picture>
                            </td>
                            <td className='text-center'>{item?.influencer_name}</td>
                            <td className='text-center'>{item?.influencer_email}</td>
                            <td className='text-center'>{item?.influencer_phone}</td>
                            <td className='text-center'>{item?.influencer_address}</td>
                            <td className='text-center text-capitalize'>
                              {item?.block_status?.split('_')?.join(' ')}
                            </td>
                            <td
                              className='d-flex align-items-center justify-content-center'
                              style={{
                                minHeight: '100px',
                              }}
                            >
                              {item?.block_status === 'not_suspended' ? (
                                <SuspenseBtn item={item} fetchInfluencer={fetchInfluencer} />
                              ) : (
                                <UnSuspenseBtn item={item} fetchInfluencer={fetchInfluencer} />
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div className='col-sm-12 my-1'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuspenseInfluencer
