import { useState, useEffect } from 'react';
import cookie from 'js-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../../../../_metronic/hooks/axios';
import privateAxios from '../../../../_metronic/hooks/axiosPrivate';

import { Formik, Form } from 'formik';
import { registrationSchema } from '../../../../_metronic/helpers/validations/auth';
import { Verify } from './Verify';

import { Link } from 'react-router-dom';

import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';
import Input from '../../../components/Input/Input';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import { FaGoogle } from 'react-icons/fa6';
import GoogleButton from 'react-google-button'

const initialValues = {
  email: '',
  c_email: '',
  password: '',
  c_password: '',
}

export function Singup() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [otpLoading, setOtpLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [verify_id, setVerify] = useState('')
  const [otp, setOtp] = useState('')

  const submitHandler = (
    values: typeof initialValues,
    {
      setStatus,
      setSubmitting,
    }: { setStatus: (status?: any) => void; setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setLoading(true)
    let fromData = {
      user_type: 3,
      email: values.email,
      password: values.password,
    }

    axios.post(`/register`, fromData)
      .then((res) => {
        if (res.status === 200 && res.data.success === true) {
          console.log("message", res);
          setLoading(false)
          cookie.set('reg-token', res?.data.token)
          otpRequest(values.email)
          toast.success(res.data?.message)
        }
      })
      .catch((err) => {
        console.log('ERROR', err.response);
        const { status, data } = err.response;
        if (status === 415) {
          const { success, message } = data;
          const { email, f_name, l_name, password, phone } = message;

          if (!success) {
            if (!Array.isArray(data?.message)) {
              setStatus(data?.message);
            }

            if (email?.length > 0) {
              setStatus(email[0]);
            }

            if (f_name?.length > 0) {
              setStatus(f_name[0]);
            }

            if (l_name?.length > 0) {
              setStatus(l_name[0]);
            }

            if (password?.length > 0) {
              setStatus(password[0]);
            }

            if (phone?.length > 0) {
              setStatus(phone[0]);
            }
          }
        } else {
          if (!Array.isArray(data?.message)) {
            setStatus(data?.message);
          }
        }
        setSubmitting(false)
        setLoading(false)
      });
  }

  const otpSubmit = async () => {
    setOtpLoading(true)
    try {
      let fromData = {
        verify_id: verify_id,
        otp_code: otp,
      }
      const result = await privateAxios.post(`/admin/verify-otp`, fromData)
      if (result.status === 200 && result.data.success === true) {
        toast.success(result.data?.message)
        setOtpLoading(false)
        setTimeout(() => {
          navigate(`/profile-update?email=${email}`)
        }, 1000)
      }
    } catch (err: any) {
      let message =
        typeof err.response !== 'undefined'
          ? (err.response.data.message as string)
          : (err.message as string)
      console.error('An error occurred:', message)
      toast.error(message);
      setOtpLoading(false);
    }
  }

  const otpRequest = async (value: string) => {
    try {
      const result = await privateAxios.post(`/admin/otp-request`, {
        otp_method: 'email',
        purpose: 'signup',
      });
      setVerify(result.data.data.verify_id);
      setEmail(value);
      setSearchParams({ email: value, verify_id: result.data.data.verify_id })
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('Erro: OTP Can not be sent');
    }
  }

  const SocialSingUpHandler = async (token: string, userInfo: any) => {
    try {
      let data = JSON.stringify({
        social_type: "google",
        f_name: userInfo?.given_name,
        l_name: userInfo?.family_name,
        email: userInfo?.email,
        phone: "",
        access_token: token,
      });

      const option = {
        method: 'post',
        url: '/social-login-merchant',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      //@ts-ignore
      const socialSingUp = await axios(option);
      console.log('socialSingUp', socialSingUp);
      if(socialSingUp?.status == 200 && socialSingUp?.data?.success == true){
        toast.success("User registered successfully!");
        cookie.set('reg-token', socialSingUp?.data?.token);
        setTimeout(() => {
          navigate(`/profile-update?email=${userInfo?.email}`)
        }, 1500)
      }else{
        toast.error("User was not registered successfully!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const loginHandler = useGoogleLogin({
    onSuccess: async tokenResponse => {
      // console.log('userInfo token', tokenResponse);
      try {
        const option = {
          method: 'get',
          url: 'https://www.googleapis.com/oauth2/v3/userinfo',
          headers: { Authorization: `${tokenResponse.token_type} ${tokenResponse.access_token}` },
        };
        //@ts-ignore
        const userInfo = await axios(option);
        // console.log('userInfo', userInfo);
        SocialSingUpHandler(tokenResponse.access_token, userInfo?.data);
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    let searchValue = searchParams.get('email') || ''
    let verify_Value = searchParams.get('verify_id') || ''
    setEmail(searchValue)
    setVerify(verify_Value)
  }, [])

  return (
    <>
      {/* <ToastContainer /> */}
      {email ? (
        <Verify
          setOtp={(e) => setOtp(e)}
          email={email}
          status={''}
          isSubmitting={false}
          loading={otpLoading}
          handleSubmit={otpSubmit}
          resentOtp={otpRequest}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={registrationSchema}
          onSubmit={(values, { resetForm, setStatus, setSubmitting }) =>
            submitHandler(values, { setStatus, setSubmitting })
          }
        >
          {({ errors, values, isValidating, isSubmitting, status, handleBlur, touched }) => (
            <Form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              id='kt_login_signup_form'
            >
              {/* begin::Heading */}
              <div className='text-center mb-11'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Hi There!</h1>
                {/* end::Title */}

                <div className='text-gray-500 fw-semibold fs-6'>
                  Welcome to Elite List, create your account
                </div>
              </div>
              {/* end::Heading */}

              {status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{status}</div>
                </div>
              )}

              <div className='row'>
                <Input
                  id='email'
                  name='email'
                  type='email'
                  errorText={errors.email}
                  error={touched.email}
                  onBlur={handleBlur}
                  inputClass=''
                  placeholder='Email'
                  containerClass='mb-4'
                />
                <Input
                  id='c_email'
                  name='c_email'
                  type='email'
                  errorText={errors.c_email}
                  error={touched.c_email}
                  onBlur={handleBlur}
                  inputClass=''
                  placeholder='Confirm Email'
                  containerClass='mb-4'
                />
                <Input
                  id='password'
                  name='password'
                  type='password'
                  errorText={errors.password}
                  error={touched.password}
                  onBlur={handleBlur}
                  inputClass=''
                  placeholder='Password'
                  containerClass='mb-4'
                />
                <Input
                  id='c_password'
                  name='c_password'
                  type='password'
                  errorText={errors.c_password}
                  error={touched.c_password}
                  onBlur={handleBlur}
                  inputClass=''
                  placeholder='Confirm Password'
                  containerClass='mb-4'
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={isSubmitting}
                >
                  {!loading && <span className='indicator-label'>Sign Up</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>

                <p>OR Continue with</p>

                <GoogleButton
                  onClick={() => loginHandler()}
                  label='Sign-Up with Google'
                  className='btn-primary w-100 mb-5 d-flex justify-content-center rounded gap-4'
                />

                <button
                  type='button'
                  id='kt_login_signup_form_cancel_button'
                  className='btn btn-lg w-100 mb-5'
                >
                  Already have an account? <Link to='/login'>Sign In</Link>
                </button>
              </div>

              {/* end::Form group */}
            </Form>
          )}
        </Formik>
      )}

    </>
  )
}
