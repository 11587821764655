import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios'
import Cookies from 'js-cookie'
import {APIURL} from '../../config/config'

// Define the type for the access token
type AccessToken = string | undefined

// Create an Axios instance
const privateClient = axios.create({
  baseURL: APIURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Request interceptor
privateClient.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    const getToken: AccessToken = Cookies.get('token') || Cookies.get('reg-token')

  if (getToken) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${getToken}`,
     
    }
  }
  

  return request
  },
  (error: any) => Promise.reject(error)
)

// Response interceptor
privateClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      // Handle the 401 unauthorized error here
      // logout();
    }
    return Promise.reject(error)
  }
)

export default privateClient
