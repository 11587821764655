import React from 'react'
import useDetectMode from '../../../../hooks/useDetectMode'
import styles from './Agree.module.scss'

interface AgreeProps {
  isChecked: boolean
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const Agree: React.FC<AgreeProps> = ({isChecked, setIsChecked}) => {
  const mode = useDetectMode()

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked) // Toggle the checkbox state
  }

  return (
    <div className={styles.wrapper}>
      <input
        type='checkbox'
        className={styles.rounded_checkbox}
        style={{
          border: mode === 'light' || mode === 'system' ? '1px solid #000' : '1px solid #fff',
        }}
        id='checkbox'
        checked={isChecked}
        onChange={handleCheckboxChange}
      />{' '}
      <label htmlFor='checkbox' className='ps-2 cursor-pointer'>
        By tapping Apply you agree and consent to our{' '}
        <a href='!#' rel='noopener noreferrer' className={styles.link}>
          Terms of Service
        </a>{' '}
        and{' '}
        <a href='!#' target='_blank' rel='noopener noreferrer'>
          Privacy policy
        </a>
      </label>
    </div>
  )
}

export default Agree
