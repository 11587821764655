import axios from 'axios'
import {APIURL} from '../../config/config'

const BASE_URL = APIURL

export default axios.create({
  baseURL: BASE_URL,
  cancelToken: new axios.CancelToken((cancel) => (c: any) => (cancel = c)),
  headers: {
    'Content-Type': 'application/json',
  },
});
